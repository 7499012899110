import React from "react";
import PropTypes from "prop-types";

// Icons
import BackArrowIcon from "res/icons/arrow-back-24-px.png";

// Utils
import { composeClassName } from "utils";
import { useSetInputFocusOnMount } from "./SearchWidget.helpers";

// App Components
import SearchButton from "./SearchButton";
import SearchForm from "./SearchForm";

// Style sheet
import "./SearchWidget.scss";

/**
*
* @function SearchWidget
* Renders Search widget that is located in top nav.
*
*/
const SearchWidget = (
  {
    searchWidgetIsOpen,
    setSearchWidgetIsOpen,
  },
) => {
  // Add open class only if the widget user has clicked the open button
  const openedClass = (searchWidgetIsOpen) ? "open" : "";
  const composedClassName = composeClassName("we SearchWidget", openedClass);

  // Create a reference that can be used to set the focus on the Searchfield
  const searchInputRef = React.createRef();

  // Update parents state to closed to trigger a rerender
  const handleCloseonClick = () => {
    setSearchWidgetIsOpen(false);
  };

  // set focus on input onMount
  useSetInputFocusOnMount(searchWidgetIsOpen, searchInputRef);

  return (
    <div className={composedClassName}>
      <SearchButton
        className="d-lg-none"
        variant="tertiary"
        icon={BackArrowIcon}
        onClick={handleCloseonClick} />
      <SearchForm searchInputRef={searchInputRef} />
    </div>
  );
};

SearchWidget.propTypes = {

  /**
    * Defines the visibility of the search widget on Mobile
    */
  searchWidgetIsOpen: PropTypes.bool.isRequired,

  /**
    * Accessor use to set the visibility state of the widget
    */
  setSearchWidgetIsOpen: PropTypes.func.isRequired,

};

export default SearchWidget;
