import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { useParams } from "react-router-dom";
import FormFieldFields from "../../graphql/fragments/types/FormFieldFields";
import FormMetaDataFields from "../../graphql/fragments/types/FormMetaDataFields";

export const FORM_QUERY = gql`
  query($slug: String!) {
    form(slug: $slug) {
      id
      name
      formstackId
      formCompleteHeader
      formCompleteBody
      formData {
        fields {...FormFieldFields}
        formSubmitButtonText
        metaFields {...FormMetaDataFields}
      }
    }
  }
  ${FormFieldFields}
  ${FormMetaDataFields}
`;

const withInteractor = (Presenter) => {
  const Interactor = () => {
    const { slug } = useParams();
    return (
      <Query query={FORM_QUERY} variables={{ slug }}>
        {(response) => {
          return <Presenter {...response} />;
        }}
      </Query>
    );
  };

  return Interactor;
};

export default withInteractor;
