import gql from "graphql-tag";

export default gql`
  fragment CampaignFields on Campaign {
    id
    bannerImage {
      ...ImageFields
    }
    bannerTitle
    promoCardImage {
      ...ImageFields
    }
    promoCardTitle
    promoCardContent
    promoCardButton {
      ...ButtonFields
    }
    partOfPrograms
    campaignPage {
      ...LinkFields
    }
  }
`;
