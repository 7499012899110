import gql from "graphql-tag";

export default gql`
  fragment FiltersBlockFields on FiltersBlock {
    id
    type
    title
    filterType
    filters {
      ...FilterFields
    }
  }
`;
