/**
 *
 * @function composeClassName
 * Helper function for merging React component classes. It accepts an infinte number of strings
 * @param {String[]} arguments - describe value1
 * @returns {String} - Comnposed className
 *
 */
const composeClassName = (...classNames) => {
  const validClassNames = [];
  classNames.forEach((className) => {
    if (typeof className === "string") {
      validClassNames.push(className);
    }
  });
  return validClassNames.join(" ");
};
export default composeClassName;
