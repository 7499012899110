import React from "react";
import { withTranslation } from "react-i18next";
import { DEPLOYMENT_TARGET } from "lib/config";
import { logError } from "./errorHandler";
import ErrorMessage from "./ErrorMessage";

/**
 *  Reusable error boundary that should wrap highlevel components.
 *  It listens for any uncaught errors raised by its children and
 *  logs the errors using the error logging helper functions. It also
 *  renders a human friendly error message so users don't see a
 *  confusing random error message.
 */
class ErrorBoundary extends React.Component {
  /**
   * React Lifecycle method, called when child raises an unknown error
   * Used to update the state of ErrorBoundary when error is caught
   * @return {object} - The updated state of the error boundary.
   */
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /**
   * Returns of the error message to render when ErrorBoundary is in
   * an error state. Otherwise returns the children compoenents that the
   * Errorbound wraps.
   * @param {object} children - the components wrapped by the errorboundary
   * @param {boolean} hasError - State of the error boundary.
   * @param {Function} t - i18next translation helper
   * @return {object} - The components to render
   */
  static _renderUI = (children, hasError, t) => {
    if (hasError) {
      return (
        <ErrorMessage
          title={t("error_boundary.error_message.title")}
          message={t("error_boundary.error_message.message")}
          />
      );
    }
    return children;
  }

  /**
   * Create an instances of ErrorBoundary
   * @param {object} props - Class properties passed by React
   */
  constructor(props) {
    super(props);

    // Set inital state of boundary to not have an error caught
    this.state = { hasError: false };
  }

  /**
   * React Lifecycle method, called when child raises an unknown error
   * Used to log the caught error
   * @param {object} error - Error that was raised and caught by error boundary
   * @param {object} info - Additional inforation on the error provided by react
   * this includes a componentStack trace(The specific chain of components,
   * invloved in raising the error) Great for debuging
   */
  componentDidCatch(error, info) {
    // send error to Error Handler to log
    logError(error, info, DEPLOYMENT_TARGET);
  }

  /**
   * React Lifecycle method, called when child rendering component
   * @return {object} - The components to render
   */
  render() {
    const { hasError } = this.state;
    const { children, t } = this.props;
    const components = ErrorBoundary._renderUI(children, hasError, t);
    return components;
  }
}

/**
 * Wrap exported ErrorBoundary in i18next translation HOC to make the "t" function
 * available with in the class.
 */
export default withTranslation()(ErrorBoundary);
