import React from "react";
import PropTypes from "prop-types";

import Block from "components/molecules/Block";


// Style sheet
import "./EmptyResultsBlock.scss";

/**
*
* @function EmptyResultsBlock
* Handles display empty results
*
*/
const EmptyResultsBlock = ({ title }) => {
  return (
    <Block
      className="we EmptyResultsBlock"
      title={title}
    />
  );
};

EmptyResultsBlock.propTypes = {

  /**
  * The term that was searched by the user
  */
  title: PropTypes.string.isRequired,

};

export default EmptyResultsBlock;
