import gql from "graphql-tag";

export default gql`
  fragment LiveWebinarBlockFields on LiveWebinarBlock {
    id
    type
    webinar {
      ...WebinarFields
    }
  }
`;
