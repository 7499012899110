import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Context
import { AuthContext } from "modules/auth";

// Routes
import SideNav from "modules/navigation/organisms/SideNav";
import TopNav from "modules/navigation/organisms/TopNav";
import AppRoutes from "components/molecules/AppRoutes";
import AuthPage from "modules/account";
import CoursePage from "modules/course";
import FormPage from "modules/form";

import "./AppLayout.scss";
import PrivateRoute from "components/molecules/AppRoutes/PrivateRoute";

/**
 * Adds/Removes global onClick event listener to the document. Used to detect when nav should
 * close if open.
 * @param {boolean} isNavCollapsed - State of the nav
 * @param {Function} toggleHandler - Refernce to toggleHandler
 *
 * @returns {null}
 */

function _toggleClickListener(isNavCollapsed, toggleHandler) {
  // Added click listner to close nav once item selected or clicked away from
  if (isNavCollapsed) {
    document.removeEventListener("click", toggleHandler);
  } else {
    document.addEventListener("click", toggleHandler);
  }
}

class AppLayout extends PureComponent {
  static propTypes = {
    topNavRoutes: PropTypes.array.isRequired,
    sideNavRoutes: PropTypes.array.isRequired,
    appRoutes: PropTypes.array.isRequired,
    authenticated: PropTypes.bool,
    logo: PropTypes.object,
    loginBackground: PropTypes.object,
    privacyPolicyLink: PropTypes.string,
  };

  static defaultProps = {
    logo: {
      desktop: "",
      altText: "",
    },
  };

  constructor(props) {
    super(props);
    this.state = { isNavCollapsed: true };
  }

  toggleSideNav = () => {
    // toogle side nav state
    const { isNavCollapsed } = this.state;
    const toggledIsNavCollapsed = !isNavCollapsed;
    this.setState({ isNavCollapsed: toggledIsNavCollapsed });
    _toggleClickListener(toggledIsNavCollapsed, this.toggleSideNav);
  }

  static contextType = AuthContext

  render() {
    const { user } = this.context;
    const { topNavRoutes,
      sideNavRoutes,
      appRoutes,
      logo,
      authenticated,
      loginBackground,
      privacyPolicyLink } = this.props;
    const { isNavCollapsed } = this.state;
    const routes = appRoutes.length > 0 ? (<AppRoutes appRoutes={appRoutes} />) : null;
    return (

      <Router>
        <TopNav
          onSideNavToggle={this.toggleSideNav}
          user={user}
          routes={topNavRoutes}
          logo={logo}
          authenticated={authenticated}
        />
        <Switch>
          <Route path="/account">
            <AuthPage loginBackground={loginBackground} privacyPolicyLink={privacyPolicyLink} />
          </Route>
          <PrivateRoute
            path="/courses/:courseName/:lessons(lessons)?/:lessonId?/:module(modules)?/:moduleId?/:questions(questions)?/:questionId?"
          >
            <CoursePage />
          </PrivateRoute>
          <PrivateRoute path="/forms/:slug">
            <FormPage />
          </PrivateRoute>
          <PrivateRoute>
            <SideNav isCollapsed={isNavCollapsed} routes={sideNavRoutes} />
            <div id="content_container">
              {routes}
            </div>
          </PrivateRoute>
        </Switch>

      </Router>

    );
  }
}


AppLayout._toggleClickListener = _toggleClickListener;

export default AppLayout;
