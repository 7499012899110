import gql from "graphql-tag";
import { graphql } from "react-apollo";
import PropTypes from "prop-types";
import QueryResults from "./QueryResults";
import fragments from "../fragments";

export const PAGE_QUERY = gql`
  query page($slug: String!) {
    page(slug:$slug) {
      ...PageFields
    }
  }
  ${fragments}
`;

// PAGE_QUERY requires a $slug variables.
// These variables are autoset by graphql() if a matching prop is found.
const PageQuery = graphql(PAGE_QUERY)(QueryResults);

PageQuery.propTypes = {
  onLoading: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onData: PropTypes.func.isRequired,

  slug: PropTypes.string.isRequired,
};

PageQuery.defaultProps = {
};

export default PageQuery;
