import * as Sentry from "@sentry/browser";
import { RELEASE, SENTRY_DSN } from "lib/config";

/**
 * Initializes error logging sdks( e.g. Sentry )
 */
export const initializeErrorHandler = (deploymentTarget) => {
  /**
   * Only init sentry for production builds.
   * React rethrows any error caught by an error bound in non-production
   * builds. Without this check, the error would be logged to Sentry twice
   */
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: RELEASE,
      environment: deploymentTarget,
    });
  }
};

/**
 * Logs error to vairous channels.
 * Sentry: for production builds in a remote space
 * Console: for all builds running outside of production( weschools.we.org )
 * @param {object} error - Error that was raised and caught by error boundary
 * @param {object} info - Additional inforation on the error provided by react
 * this includes a componentStack trace(The specific chain of components,
 * invloved in raising the error) Great for debuging
 */
export const logError = (error, info, deploymentTarget = "development") => {
  /**
   * Only log to Sentry if running a production build.
   * As production build happens as a part of the deploy process
   * this assumes that it is running on a remote server, and not
   * local dev server.
   * For local dev, logging should just happen in the console.
   */
  if (process.env.NODE_ENV === "production") {
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      Sentry.captureException(error);
    });
  }

  // if running on prod(weschools.we.org) do not log to console.
  if (deploymentTarget !== "production") {
    // eslint-disable-next-line no-console
    console.error(
      "Raised Error:\n",
      error,
      "\n\n Error Stack:",
      info.componentStack,
    );
  }
};
