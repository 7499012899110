// eslint-disable-next-line import/prefer-default-export
export const adaptForm = (form) => {
  const {
    formData: { fields, formSubmitButtonText, metaFields },
    ...restForm
  } = form;

  return {
    ...restForm,
    formSubmitButtonText: formSubmitButtonText,
    fieldsData: { fields },
    metaFields: metaFields,
  };
};
