import React, { useState } from "react";
import { isEmptyString, isPassword } from "lib/utils";
import { useLocation, useHistory } from "react-router-dom";
import { _extractJwtPayload } from "../../../auth/authUtils";

const withPresenter = (View) => {
  const Presenter = ({ updatePassword }) => {
    const [createPassword, setCreatePassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [createPasswordInputType, setCreatePasswordInputType] = useState("password");
    const [confirmPasswordInputType, setConfirmPasswordInputType] = useState("password");

    const history = useHistory();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const token = query.get("token");

    const formInvalid = (isEmptyString(createPassword) || isEmptyString(confirmPassword));

    const togglePasswordVisibility = () => {
      if (createPasswordInputType === "password") {
        setCreatePasswordInputType("text");
      } else {
        setCreatePasswordInputType("password");
      }
    };

    const toggleConfirmPasswordVisibility = () => {
      if (confirmPasswordInputType === "password") {
        setConfirmPasswordInputType("text");
      } else {
        setConfirmPasswordInputType("password");
      }
    };

    const handleCreatePassword = ({ target: { value } }) => {
      setCreatePassword(value);
      setError(false);
      setInvalid(false);
    };

    const handleConfirmPassword = ({ target: { value } }) => {
      setConfirmPassword(value);
      setError(false);
      setInvalid(false);
    };

    const handleSavePassword = () => {
      if (createPassword !== confirmPassword) {
        setError(true);
      } else if (!isPassword(createPassword)) {
        setInvalid(true);
      } else if (token) {
        const { id } = _extractJwtPayload(token);
        updatePassword({
          id: id,
          password: createPassword,
          token: token,
        });
        history.push("/account/successfulReset");
      }
    };

    return (
      <View
        createPassword={createPassword}
        confirmPassword={confirmPassword}
        handleCreatePassword={handleCreatePassword}
        handleConfirmPassword={handleConfirmPassword}
        handleSavePassword={handleSavePassword}
        error={error}
        formInvalid={formInvalid}
        createPasswordInputType={createPasswordInputType}
        confirmPasswordInputType={confirmPasswordInputType}
        togglePasswordVisibility={togglePasswordVisibility}
        toggleConfirmPasswordVisibility={toggleConfirmPasswordVisibility}
        invalid={invalid}
        />
    );
  };

  return Presenter;
};

export default withPresenter;
