import gql from "graphql-tag";

import PageFields from "./pages/PageFields";
import FeedPageFields from "./pages/FeedPageFields";
import ProgramPageFields from "./pages/ProgramPageFields";
import CampaignPageFields from "./pages/CampaignPageFields";
import CollectionPageFields from "./pages/CollectionPageFields";
import FilterPageFields from "./pages/FilterPageFields";
import SearchPageFields from "./pages/SearchPageFields";

import BlockFields from "./blocks/BlockFields";
import ClassroomResourceListBlockFields from "./blocks/ClassroomResourceListBlockFields";
import MonthlyFeatureBlockFields from "./blocks/MonthlyFeatureBlockFields";
import PromotedCampaignBlockFields from "./blocks/PromotedCampaignBlockFields";
import PromotedProgramsBlockFields from "./blocks/PromotedProgramsBlockFields";
import SocialMediaBlockFields from "./blocks/SocialMediaBlockFields";
import ImageBlockFields from "./blocks/ImageBlockFields";
import ContentBlurbBlockFields from "./blocks/ContentBlurbBlockFields";
import FeaturedCollectionsBlockFields from "./blocks/FeaturedCollectionsBlockFields";
import FeaturedCollectionBlockFields from "./blocks/FeaturedCollectionBlockFields";
import FiltersBlockFields from "./blocks/FiltersBlockFields";
import NewestResourcesListBlockFields from "./blocks/NewestResourcesListBlockFields";
import LiveWebinarBlockFields from "./blocks/LiveWebinarBlockFields";
import RecordedCPDListBlockFields from "./blocks/RecordedCPDListBlockFields";
import UpcomingWebinarListBlockFields from "./blocks/UpcomingWebinarListBlockFields";
import FormBlockFields from "./blocks/FormBlockFields";

import ButtonFields from "./types/ButtonFields";
import ImageFields from "./types/ImageFields";
import LinkFields from "./types/LinkFields";
import TagFields from "./types/TagFields";
import ProgramFields from "./types/ProgramFields";
import CampaignFields from "./types/CampaignFields";
import CollectionFields from "./types/CollectionFields";
import ClassroomResourceFields from "./types/ClassroomResourceFields";
import FilterFields from "./types/FilterFields";
import RecordedCPDFields from "./types/RecordedCPDFields";
import WebinarFields from "./types/WebinarFields";
import FormFieldFields from "./types/FormFieldFields";
import FormMetaDataFields from "./types/FormMetaDataFields";

export default gql`
  ${PageFields}
  ${FeedPageFields}
  ${ProgramPageFields}
  ${CampaignPageFields}
  ${CollectionPageFields}
  ${FilterPageFields}
  ${SearchPageFields}

  ${BlockFields}
  ${ClassroomResourceListBlockFields}
  ${MonthlyFeatureBlockFields}
  ${PromotedCampaignBlockFields}
  ${PromotedProgramsBlockFields}
  ${SocialMediaBlockFields}
  ${ImageBlockFields}
  ${ContentBlurbBlockFields}
  ${FeaturedCollectionsBlockFields}
  ${FeaturedCollectionBlockFields}
  ${FiltersBlockFields}
  ${NewestResourcesListBlockFields}
  ${LiveWebinarBlockFields}
  ${RecordedCPDListBlockFields}
  ${UpcomingWebinarListBlockFields}
  ${FormBlockFields}

  ${ButtonFields}
  ${ImageFields}
  ${LinkFields}
  ${TagFields}
  ${ProgramFields}
  ${CampaignFields}
  ${CollectionFields}
  ${ClassroomResourceFields}
  ${FilterFields}
  ${RecordedCPDFields}
  ${WebinarFields}
  ${FormFieldFields}
  ${FormMetaDataFields}
`;
