import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RatingFormQuery from "graphql/queries/RatingFormQuery";

// App Components
import Modal from "components/molecules/Modal";
import { ErrorMessage } from "modules/error";
import Spinner from "components/atoms/Spinner";

import FormSubmitted from "modules/page/blocks/FormBlock/FormBlockSubmitted";
import RatingForm from "./RatingForm";

import "./RatingFormModal.scss";

const ErrorView = () => {
  const { t } = useTranslation();

  return (
    <ErrorMessage
      title={t("rating_form.query_error_message.title")}
      message={t("rating_form.query_error_message.message")}
    />
  );
};

const LoadingView = () => {
  return (
    <div className="loading-view">
      <Spinner />
    </div>
  );
};

const RatingFormModalBody = ({
  resource,
  isOpen,
  onClose,
  submitted,
  nextRating,
  onSubmit,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <RatingFormQuery
      onLoading={() => {
        return <LoadingView />;
      }}
      onError={() => {
        return <ErrorView />;
      }}
      onData={({ page: ratingForm }) => {
        if (submitted) {
          return (
            <FormSubmitted
              formBlock={ratingForm}
              onClose={onClose}
            />
          );
        }

        return (
          <RatingForm
            isOpen={isOpen}
            resource={resource}
            onSubmit={onSubmit}
            nextRating={nextRating}
            ratingForm={ratingForm}
          />
        );
      }}
    />
  );
};


const RatingFormModal = ({ children, ...props }) => {
  const { isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideCloseButton>
      <div className="we RatingFormModal">
        <button
          type="button"
          className="close-button"
          onClick={onClose}
          />
        <div className="form-modal-body">
          <RatingFormModalBody {...props} />
        </div>
      </div>
    </Modal>
  );
};

RatingFormModal.propTypes = {
  /**
    * Resource data from storyblok
    */
  resource: PropTypes.object,

  /**
    * Whether or not RatingForm modal is open
    */
  isOpen: PropTypes.bool.isRequired,

  /**
    * Event listener fired when modal close button is clicked
    */
  onClose: PropTypes.func.isRequired,
};

export default RatingFormModal;
