const constants = {
  WE_SCHOOLS_PARTNER: "we_charity",
  WE_SCHOOLS_PROGRAM: "we_schools_hub",

  /**
   * Availble themes supported by components. Refers to the backdrop of the parent component.
   * Used to define color variations that aestically optimize
   * the element's presention within the bounded context of its parent.
   *
   * @typedef {Object} Themes
   * @property {String} dark Dark themed elements employ stylings that aestically optimize
   * the elements presention when contained inside parent component that is predominantly
   * dark. (Eg. a dark container would use a transparent button with white font and outline
   *
   * @property {String} light Light themed elements employ stylings that aestically optimize
   * the elements presention when contained inside parent component that is predominantly
   * light. (Eg. a ligh container would use a solid blue button with white font or
   * white button with blue outline and font.
   *
   */
  THEMES: {
    dark: "dark",
    light: "light",
    danger: "danger",
  },
  UNAUTHORIZED_ERROR_CODE: 401,
  INCOMPLETE_SUBSCRIPTION_ERROR_CODE: 403,
  FORM_FIELD_TYPES: {
    text: "text",
    number: "number",
    textarea: "textarea",
    select: "select",
    radio: "radio",
    checkbox: "checkbox",
    ratingFormRating: "rating-form-rating",
    richtext: "richtext",
    section: "section",
    desciption: "description",
    placeholder: "placeholder",
    maxLength: "maxlength",
    rows: "rows",
  },
  MOBILE_MAX_SCREEN_WIDTH: 576,
};

constants.NON_INPUT_FORM_FIELD_TYPES = [
  constants.FORM_FIELD_TYPES.richtext,
  constants.FORM_FIELD_TYPES.section,
];

module.exports = Object.freeze(constants);
