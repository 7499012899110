import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { isEmail, isEmptyString } from "lib/utils";

const withPresenter = (View) => {
  const Presenter = ({ forgotPassword, email, setEmail }) => {
    const history = useHistory();
    const [error, setError] = useState(false);

    const handleSendLink = () => {
      if (!isEmail(email)) {
        setError(true);
      } else {
        forgotPassword(email);
        setError(false);
        history.push("/account/resetSent");
      }
    };
    const handleEmail = ({ target: { value } }) => {
      setEmail(value);
    };

    const handleBack = () => {
      history.goBack();
    };

    const formInvalid = isEmptyString(email);

    return (
      <View
        handleSendLink={handleSendLink}
        email={email}
        handleEmail={handleEmail}
        formInvalid={formInvalid}
        error={error}
        handleBack={handleBack}
        />
    );
  };

  return Presenter;
};

export default withPresenter;
