import React from "react";
import { useTranslation } from "react-i18next";
import { Mutation } from "react-apollo";

import UPDATE_RATING_MUTATION from "graphql/mutations/updateRating";

import { ErrorMessage } from "modules/error";
import Spinner from "components/atoms/Spinner";
import FormStackForm from "components/organisms/FormStackForm";


const _getHandleSubmitForm = ({
  updateRating,
  ratingForm,
  resource,
  onSubmit,
}) => {
  return async (formData) => {
    const {
      formId,
      fieldsData: {
        metaFields,
      },
    } = ratingForm;
    const {
      ratingFieldId,
      resourceNameFieldId,
    } = metaFields;
    const completeForm = {
      ...formData,
      [resourceNameFieldId]: resource.title,
    };

    delete metaFields.__typename;

    await updateRating({
      variables: {
        ratingFormId: formId,
        resourceId: resource.id,
        formData: JSON.stringify(completeForm),
        metaFields: metaFields,
      },
    });

    const rating = formData[ratingFieldId];
    onSubmit(resource.id, rating);
  };
};

const _getErrorView = (t) => {
  return (
    <ErrorMessage
      title={t("form_block.error_message.title")}
      message={t("form_block.error_message.message")}
    />
  );
};

const _getLoadingView = () => {
  return (
    <div className="loading-view">
      <Spinner />
    </div>
  );
};
const RatingForm = ({
  isOpen,
  resource,
  ratingForm,
  onSubmit,
  nextRating,
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="we RatingForm">
      <Mutation mutation={UPDATE_RATING_MUTATION}>
        {(updateRating, { loading, error }) => {
          const _handleSubmit = _getHandleSubmitForm({
            updateRating,
            ratingForm,
            resource,
            onSubmit,
          });

          if (error) return _getErrorView(t);
          if (loading) return _getLoadingView();

          return (
            <FormStackForm
              form={ratingForm}
              onSubmit={_handleSubmit}
              nextRating={nextRating}
              isRating
            />
          );
        }}
      </Mutation>
    </div>
  );
};

export default RatingForm;
