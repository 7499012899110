module.exports = Object.freeze({
  LANDING_PAGE_HREF: process.env.REACT_APP_LANDING_PAGE_HREF,
  BFF_HREF: process.env.REACT_APP_BFF_HREF,
  COOKIE_AUTH_NAME: process.env.REACT_APP_COOKIE_AUTH_NAME,
  COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
  GTM_ID: process.env.REACT_APP_GTM_ID,
  GTM_AUTH: process.env.REACT_APP_GTM_AUTH,
  GTM_PREVIEW: process.env.REACT_APP_GTM_PREVIEW,
  DEPLOYMENT_TARGET: process.env.REACT_APP_DEPLOYMENT_TARGET.toLowerCase(),
  RELEASE: process.env.REACT_APP_RELEASE,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
});
