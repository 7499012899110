const ignorePattern = /^[/?]+/gi;
const parseQueryParams = (queryString) => {
  if (queryString) {
    const params = {};
    const queryStringSplit = queryString.replace(ignorePattern, "").split("&");
    queryStringSplit.forEach((queryParam) => {
      const queryParamSplit = queryParam.split("=");
      if (queryParamSplit.length > 1) {
        const key = queryParamSplit[0];
        const value = decodeURIComponent(queryParamSplit[1]);
        params[key] = value;
      }
    });
    return params;
  }
  return {};
};

export default parseQueryParams;
