import React from "react";
import { composeClassName } from "utils";
import Text from "components/atoms/Text";

import "./Checkbox.scss";

const Checkbox = ({ className, id, value, onChange, selected, label }) => {
  const indicatorClassName = composeClassName(
    "Checkbox__Indicator",
    selected ? "Selected" : "",
  );

  const composedClassName = composeClassName(
    className,
    "we Checkbox",
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onChange();
    }
  };

  return (
    <div
      className={composedClassName}
      onClick={onChange}
      onKeyDown={handleKeyDown}
      htmlFor={id}
      role="checkbox"
      tabIndex={0}
      aria-checked={selected}>
      <input type="checkbox" hidden aria-hidden checked={selected} id={id} onChange={onChange} value={value} />
      <span className={indicatorClassName} />
      <Text className="we Checkbox">{label}</Text>
    </div>
  );
};

export default Checkbox;
