import React from "react";
import PropTypes from "prop-types";

// Style sheet
import "./FeaturedCollectionBlock.scss";

// App Components
import Block from "components/molecules/Block";
import Heading from "components/atoms/Heading";
import Text from "components/atoms/Text";
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";
import ArrowRightIcon from "components/atoms/ArrowRightIcon";

/**
*
* @function FeaturedCollectionBlock
* Featured Collection Block (https://zpl.io/ad14Pxe)
*
* @param {Object} block - Block properties from SB
*
*/
const FeaturedCollectionBlock = ({ block }) => {
  const { collection } = block;
  const {
    promoCardTitle,
    promoCardContent,
    promoCardImage,
    promoCardImageAlt,
    collectionPage,
    promoCardButton,
    theme,
  } = collection;
  const { enabled: buttonEnabled, text: buttonText } = promoCardButton;
  const { url: href, external: externalLink } = collectionPage;

  return (
    <Block className="FeaturedCollectionBlock">
      <Image
        className="block-image"
        src={promoCardImage || ""}
        alt={promoCardImageAlt}
      >
        <div className="tile-content">
          <div className="tile-title">
            <Heading theme={theme} variant={Heading.variants.card}>
              {promoCardTitle}
            </Heading>
          </div>

          <Text theme={theme}>
            {promoCardContent}
          </Text>

          <div>
            <Button
              href={href}
              externalLink={externalLink}
              variant={Button.variants.text}
              className="tile-action"
              disabled={!buttonEnabled}
              theme={theme}
            >
              {buttonText}
              <ArrowRightIcon alt={buttonText} theme={theme} />
            </Button>
          </div>
        </div>
      </Image>
    </Block>
  );
};

FeaturedCollectionBlock.propTypes = {
  block: PropTypes.any.isRequired,
};

export default FeaturedCollectionBlock;
