import React from "react";
import PropTypes from "prop-types";

// Node Modules
import ReactMarkdown from "react-markdown";

// App Components
import Heading from "components/atoms/Heading";
import Text from "components/atoms/Text";
import Button from "components/atoms/Button";
import Block from "components/molecules/Block";
import Card from "components/molecules/Card";

// Style sheet
import "./ContentBlurbBlock.scss";

/**
  *
  * @function _buildButton
  * Lays out a single Button
  * @param {string} variant
  * @param {object} button
  *
  * @returns {Function}
  */
const _buildButton = (button) => {
  const { text, enabled } = button;
  if (button && (text || enabled)) {
    return (
      <Button
        theme="light"
        variant={Button.variants.secondary}
        {...Button.extractButtonPropsFromButtonObject(button)} />
    );
  }
  return null;
};

/**
*
* @function ContentBlurbBlock
* Renders content blurb block. (https://zpl.io/V4zR05N)
*
*/
const ContentBlurbBlock = ({ block }) => {
  const { title, bodyText, logoImage, ctaButton } = block;
  const { desktop } = logoImage;
  const button = _buildButton(ctaButton);
  return (
    <Block className="we ContentBlurbBlock">
      <Card>
        <Card.Content>
          <Heading variant={Heading.variants.card}>{title}</Heading>
          {/* TODO:Update Image component to handle this variant when refactoring Image */}
          <img src={desktop} alt={title} />
          <Text>
            <ReactMarkdown source={bodyText} />
          </Text>
          {button}
        </Card.Content>
      </Card>
    </Block>
  );
};


ContentBlurbBlock.propTypes = {

  /**
    * Story block data model
    */
  block: PropTypes.any.isRequired,

};


export default ContentBlurbBlock;
