import React from "react";
import PropTypes from "prop-types";

// Bootstrap Components
import BootstrapModal from "react-bootstrap/Modal";

// Style sheet
import "./Modal.scss";

const _getCloseButton = (hideCloseButton, onClose) => {
  if (hideCloseButton) return null;

  return (
    <button
      type="button"
      className="modal-close"
      onClick={onClose}
    />
  );
};

const Modal = ({
  children,
  isOpen,
  onClose,
  hideCloseButton = false,
}) => {
  const _closeButton = _getCloseButton(hideCloseButton, onClose);

  return (
    <BootstrapModal
      show={isOpen}
      onHide={onClose}
      backdropClassName="modal-overlay"
      dialogClassName="we Modal"
    >
      {_closeButton}
      <div className="modal-container">
        {children}
      </div>
    </BootstrapModal>
  );
};

Modal.propTypes = {
  /**
   * Whether or not modal is currently visible
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Callback to be triggered when close button is clicked
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Whether or not close button should be rendered
   */
  hideCloseButton: PropTypes.bool,
};

export default Modal;
