import gql from "graphql-tag";

export default gql`
  fragment UpcomingWebinarListBlockFields on UpcomingWebinarListBlock {
    id
    type
    title
    webinars {
      ...WebinarFields
    }
  }
`;
