/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import EmptyResultsBlock from "../blocks/EmptyResultsBlock";
import FeedItems from "../organisms/FeedItems";

const _resolveFilterTitle = (filterType, filterTitle, t) => {
  switch (filterType) {
    case "Program":
      return t("filter.block_titles.programs", { filterTitle });
    case "Campaign":
      return t("filter.block_titles.campaigns", { filterTitle });
    case "Collection":
      return t("filter.block_titles.collections", { filterTitle });
    case "Region":
      return t("filter.block_titles.regions", { filterTitle });
    case "RecommendedFor":
      return t("filter.block_titles.recommendedFor", { filterTitle });
    case "Topic":
      return t("filter.block_titles.topics", { filterTitle });
    case "Skill":
      return t("filter.block_titles.skills", { filterTitle });
    default:
      return "";
  }
};

const Filter = ({ page, blocks }) => {
  const { t } = useTranslation();
  const { filterTitle, feed } = page;
  if (feed.length === 0) {
    const noResult = t("filter.empty_results.title", { filterTitle });
    return <EmptyResultsBlock title={noResult} />;
  }
  feed.forEach((block) => {
    const { type, filterType } = block;
    switch (type) {
      case "ClassroomResourceListBlock":
        block.title = t("filter.block_titles.resources", { filterTitle });
        break;
      case "RecordedCPDListBlock":
        block.title = t("filter.block_titles.recordedCPDs", { filterTitle });
        break;
      case "FiltersBlock":
        block.title = _resolveFilterTitle(filterType, filterTitle, t);
        break;
      default:
        break;
    }
  });
  return <FeedItems feed={feed} blocks={blocks} />;
};
Filter.propTypes = {
  page: PropTypes.any.isRequired,
  blocks: PropTypes.object.isRequired,
};

Filter.defaultProps = {
};

export default Filter;
