import React, { useState } from "react";
import PropTypes from "prop-types";

// Bootstrap component
import BootstrapFormControl from "react-bootstrap/FormControl";

// Autosuggest dependency
import Autosuggest from "react-autosuggest";

const _onSuggestionsRequested = (setSuggestions, getSuggestionList) => {
  return ({ value: data }) => {
    return setSuggestions(getSuggestionList(data));
  };
};

const _onSuggestionsClearRequested = (setSuggestions) => {
  return () => {
    return setSuggestions([]);
  };
};

const _getSuggestionValue = ({ value: suggestionValue }) => {
  return suggestionValue;
};

const _renderSuggestion = ({ key }) => {
  return (<span>{key}</span>);
};

const _renderSuggestionsContainer = ({ containerProps, children: containerChildren }) => {
  return (
    <div {...containerProps}>
      {containerChildren}
    </div>
  );
};

const _renderInputComponent = (children) => {
  return (renderedInputProps) => {
    return (
      <BootstrapFormControl {...renderedInputProps}>
        {children}
      </BootstrapFormControl>
    );
  };
};

const AutoSuggestInput = ({
  name,
  type,
  placeholder,
  value,
  onChange,
  getSuggestionList,
  className,
  children,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const inputProps = { name, type, placeholder, value, className, onChange };

  return (
    <Autosuggest
      suggestions={suggestions}
      inputProps={inputProps}
      onSuggestionsFetchRequested={_onSuggestionsRequested(setSuggestions, getSuggestionList)}
      onSuggestionsClearRequested={_onSuggestionsClearRequested(setSuggestions)}
      getSuggestionValue={_getSuggestionValue}
      renderSuggestion={_renderSuggestion}
      renderSuggestionsContainer={_renderSuggestionsContainer}
      renderInputComponent={_renderInputComponent(children)}
    />
  );
};

AutoSuggestInput.propTypes = {
  /**
   * Type of input, only applicable for text/default input.
   */
  type: PropTypes.string,

  /**
   * Optional: Input value
   */
  value: PropTypes.string,

  /**
   * Input Placeholder
   */
  placeholder: PropTypes.string,

  /**
   * Function triggered on change for auto suggest inputs
   */
  getSuggestionList: PropTypes.func,

  /**
   * Input onChange event handler
   */
  onChange: PropTypes.func,

  /**
   * Input name
   */
  name: PropTypes.string,
};

AutoSuggestInput.defaultProps = {};

export default AutoSuggestInput;
