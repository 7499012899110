import React, { useState } from "react";
import PropTypes from "prop-types";

// Style sheet
import "./ExpandableList.scss";

// Returns onClick event listener for List expand button
const _handleExpandButton = (expanded, setExpanded) => {
  return () => {
    setExpanded(!expanded);
  };
};

/**
 * Helper for rendering Expand Button.
 * showMoreVisible is visible then return the <ExpandButton>
 * otherwise return null
 *
 * @param {boolean} showMoreVisible
 * @param {Component} ExpandButton
 * @param {boolean} expanded
 * @param {function} setExpanded
 * @returns
 */
const _showButton = (showMoreVisible, ExpandButton, expanded, setExpanded) => {
  if (showMoreVisible) {
    return (
      <ExpandButton
        expanded={expanded}
        onClick={_handleExpandButton(expanded, setExpanded)}
    />
    );
  }
  return null;
};

/**
*
* @function ExpandableList
* Expandable list component that renders adapter
*
*/
const ExpandableList = ({
  items,
  displayCount,
  className,
  adapter: ListAdapter,
  expandButton: ExpandButton,
}) => {
  const [expanded, setExpanded] = useState(false);
  const renderCount = expanded ? items.length : displayCount;
  const showMoreVisible = items.length > displayCount;
  const button = _showButton(showMoreVisible, ExpandButton, expanded, setExpanded);

  return (
    <div className={className}>
      <ListAdapter
        items={items}
        renderCount={renderCount} />
      {button}
    </div>
  );
};

ExpandableList.propTypes = {
  /**
   * Specifies items to be rendered
   */
  items: PropTypes.array.isRequired,

  /**
   * Specifies number of items to render with list is not expanded
   */
  displayCount: PropTypes.number.isRequired,
  /**
   * Adapater component to be rendered
   */
  adapter: PropTypes.elementType.isRequired,

  /**
   * Render component for expand button
   */
  expandButton: PropTypes.elementType.isRequired,
};


export default ExpandableList;
