import Joi from "joi";
import { FORM_FIELD_TYPES, NON_INPUT_FORM_FIELD_TYPES } from "lib/constants";

const getBaseFieldSchema = (field) => {
  switch (field.type) {
    case FORM_FIELD_TYPES.number:
      return Joi.number();
    case FORM_FIELD_TYPES.checkbox:
      return Joi.array().items(Joi.string());
    case FORM_FIELD_TYPES.text:
    case FORM_FIELD_TYPES.textarea:
    case FORM_FIELD_TYPES.select:
    case FORM_FIELD_TYPES.radio:
      return Joi.string();
    case FORM_FIELD_TYPES.ratingFormRating:
      return Joi.number()
        .min(1)
        .max(5);
    default:
      throw new Error("Invalid field type");
  }
};

const getFieldSchema = (field) => {
  let base = getBaseFieldSchema(field);

  if (field.required) {
    if (field.type === "checkbox") {
      base = base.min(1);
    } else {
      base = base.required();
    }
  } else {
    base = base.allow("").optional();
  }
  return base;
};

const createSchema = (fields) => {
  const objectSchema = fields.reduce((schema, field) => {
    return {
      ...schema,
      [field.id]: getFieldSchema(field),
    };
  }, {});
  return Joi.object(objectSchema);
};

export default function isFormValid(fields, data) {
  const inputFields = fields.filter(({ type }) => {
    return !NON_INPUT_FORM_FIELD_TYPES.includes(type);
  });
  const { error } = createSchema(inputFields).validate(data);
  return !error;
}
