import gql from "graphql-tag";

export default gql`
  fragment CollectionPageFields on CollectionPage {
    id
    type
    collection {
      ...CollectionFields
    }
    feed {
      ...BlockFields
    }
  }
`;
