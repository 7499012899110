import React from "react";
import PropTypes from "prop-types";

// Bootstrap component
import BootstrapFormControl from "react-bootstrap/FormControl";
import BootstrapFormGroup from "react-bootstrap/FormGroup";
import BootstrapFormLabel from "react-bootstrap/FormLabel";
import BootstrapFormText from "react-bootstrap/FormText";

// Utils
import { composeClassName } from "utils";

// Autosuggest dependency
import AutosuggestInput from "./AutoSuggestInput";

// Style sheet
import "./Input.scss";

// Icons
import ShowAsset from "../../../res/icons/Show.svg";
import HideAsset from "../../../res/icons/Hide.svg";


const _getInputControl = (variant) => {
  if (variant === "autosuggest") return AutosuggestInput;
  return BootstrapFormControl;
};

const _getInputLabel = (label) => {
  if (!label) return "";
  return <BootstrapFormLabel className="Label">{label}</BootstrapFormLabel>;
};

const _getInputSecondaryLabel = (label) => {
  if (!label) return "";
  return <BootstrapFormText className="Secondary">{label}</BootstrapFormText>;
};

const _getInputError = (label) => {
  if (!label) return "";
  return <BootstrapFormText className="Error">{label}</BootstrapFormText>;
};

const _getIcon = (type, onClick) => {
  return (
    <button className="Icon" onClick={onClick} type="button">
      <img
        src={type === "password" ? ShowAsset : HideAsset}
        alt=""
      />
    </button>
  );
};

/**
 *
 * Various types of Inputs
 * @typedef {Object} Variants
 * @property {String} input Text Input
 * @property {String} select Select Input
 *
 */
const variants = {
  default: "input",
  autosuggest: "autosuggest",
  select: "select",
  radio: "radio",
  checkbox: "checkbox",
  textarea: "textarea",
};

/**
*
* @function Input
* Input form field
*
*/

const Input = React.forwardRef(({
  name,
  value,
  type,
  label,
  placeholder,
  children,
  className,
  variant,
  getSuggestionList,
  onChange,
  rows,
  maxLength,
  error,
  secondaryLabel,
  onClick,
  passwordToggle,
}, ref) => {
  // Combines all class into a single string
  const composedClass = composeClassName("we Input", className);
  const composedControlClass = composeClassName(composedClass, "Control", variant);
  const InputControl = _getInputControl(variant);
  const InputLabel = _getInputLabel(label);
  const InputSecondaryLabel = _getInputSecondaryLabel(secondaryLabel);
  const InputErrorLabel = _getInputError(error);
  const PasswordToggle = _getIcon(type, onClick);
  let ControlView = (
    <InputControl
      name={name}
      as={variants[variant]}
      type={type}
      value={value}
      className={composedControlClass}
      placeholder={placeholder}
      getSuggestionList={getSuggestionList}
      onChange={onChange}
      ref={ref}
      maxLength={maxLength}
      rows={rows}
      >
      {children}
    </InputControl>
  );
  if (passwordToggle) {
    ControlView = (
      <div className="InputContainer">
        {ControlView}
        {PasswordToggle}
      </div>
    );
  }
  return (
    <BootstrapFormGroup className={composedClass}>
      {InputLabel}
      {ControlView}
      {InputSecondaryLabel}
      {InputErrorLabel}
    </BootstrapFormGroup>
  );
});

Input.variants = variants;

Input.propTypes = {
  /**
   * Different variations of form controls. Eg. Input, Select.
   */
  variant: PropTypes.oneOf(Object.keys(variants)),

  /**
   * Type of input, only applicable for text/default input.
   */
  type: PropTypes.string,

  /**
   * Optional: Input value
   */
  value: PropTypes.string,

  /**
   * Input Placeholder
   */
  placeholder: PropTypes.string,

  /**
   * Function triggered on change for auto suggest inputs
   */
  getSuggestionList: PropTypes.func,

  /**
   * Input onChange event handler
   */
  onChange: PropTypes.func,

  /**
   * Input name
   */
  name: PropTypes.string,

  /**
   * Secondary Label
   */
  secondaryLabel: PropTypes.string,
  /**
   * on click for toggle
   */
  onClick: PropTypes.func,
  /**
   * password toggle to enable show/hide
   */
  passwordToggle: PropTypes.bool,
};

Input.defaultProps = {
  variant: "default",
};

export default Input;
