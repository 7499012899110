import gql from "graphql-tag";
import { graphql } from "react-apollo";
import PropTypes from "prop-types";
import QueryResults from "./QueryResults";
import fragments from "../fragments";

export const FILTER_PAGE_QUERY = gql`
  query filterPage($filterTitle:String!, $filterName: String!, $filterOperation: String!, $filterValue: String!) {
    page:filterPage(filterTitle: $filterTitle, filterName: $filterName, filterOperation: $filterOperation, filterValue: $filterValue) {
      ...PageFields
    }
  }
  ${fragments}
`;

// FILTER_PAGE_QUERY requires a $filterTitle, $filterName, $filterValue variables.
// These variables are autoset by graphql() if a matching prop is found.
const FilterPageQuery = graphql(FILTER_PAGE_QUERY)(QueryResults);

FilterPageQuery.propTypes = {
  onLoading: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onData: PropTypes.func.isRequired,

  filterTitle: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
  filterOperation: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,
};

FilterPageQuery.defaultProps = {
};

export default FilterPageQuery;
