import React from "react";
import ErrorHandler from "components/molecules/ErrorHandler";
import Spinner from "components/atoms/Spinner";
import SubHeader from "components/organisms/SubHeader";
import FormStackForm from "components/organisms/FormStackForm";
import Heading from "components/atoms/Heading";
import Text from "components/atoms/Text";
import Button from "components/atoms/Button";
import icon from "res/icons/submission-icon.png";
import Image from "components/atoms/Image";
import { useHistory } from "react-router-dom";
import { Mutation } from "react-apollo";

import "./FormPage.scss";
import UPDATE_FORM_STATE_MUTATION from "graphql/mutations/updateFormState";


export default function FormPage({ form, loading, error, submitted, setSubmitted }) {
  const history = useHistory();
  // TODO: replace the count with the proper page to be landed on
  const onClose = () => {
    const count = history.location.state?.count || 1;
    history.go(-count);
  };

  const _handleSubmitForm = (updateFormState) => {
    return (formData) => {
      const { formstackId, metaFields } = form;
      delete metaFields.__typename;

      return updateFormState({
        variables: {
          formId: formstackId,
          metaFields: metaFields,
          formData: JSON.stringify(formData),
          formAction: "completed",
        },
      });
    };
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  let display = (
    <Mutation mutation={UPDATE_FORM_STATE_MUTATION}>
      {(updateFormState, { data }) => {
        if (data && data.updateFormState) {
          setSubmitted(true);
        }
        const _handleSubmit = _handleSubmitForm(updateFormState);
        return <FormStackForm className="form" form={form} onSubmit={_handleSubmit} />;
      }}
    </Mutation>

  );

  if (submitted) {
    display = (
      <div className="submission-container">
        <Image src={icon} alt="Successful Submission Image" />
        <Heading>{form.formCompleteHeader}</Heading>
        <Text>{form.formCompleteBody}</Text>
        <Button
          onClick={onClose}>
          Close
        </Button>
      </div>
    );
  }

  return (
    <div className="we form-page">
      <SubHeader title={form.name} exitLabel="Back" />
      <div className="form-container">
        {display}
      </div>
    </div>
  );
}
