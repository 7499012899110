import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Heading from "components/atoms/Heading/Heading";
import Text from "components/atoms/Text/Text";
import Button from "components/atoms/Button/Button";
import Block from "components/molecules/Block/Block";
import "./ResetSent.scss";
import ArrowBackIcon from "components/atoms/ArrowBackIcon/ArrowBackIcon";
import { Link, useHistory } from "react-router-dom";
import sentIcon from "../../../../res/icons/sent.svg";

const ResetSentBlock = ({
  handleBack,
  handleResend,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Block className="we ResetSentBlock">
      <div className="back">
        <ArrowBackIcon alt="alt" />
        <Button
          variant="text"
          onClick={handleBack}>
          { t("forgot_password.back_button") }
        </Button>
      </div>
      <img className="icon" src={sentIcon} alt="" />
      <Heading {...Heading.variants.page}>
        { t("reset_sent.title") }
      </Heading>
      <Text>
        { t("reset_sent.description") }
      </Text>
      <Heading {...Heading.variants.card}>
        { t("reset_sent.question") }
      </Heading>
      <Text>
        <Trans i18nKey="reset_sent.resolution" t={t}>
          Check your spam folder or
          <Link to={history.location} onClick={() => { return handleResend(); }}>
            resend the link.
          </Link>
        </Trans>
      </Text>

    </Block>
  );
};

export default ResetSentBlock;
