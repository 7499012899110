import React from "react";
import PropTypes from "prop-types";

// Utils
import { composeClassName } from "utils";

// Style sheet
import "./CompletionModal.scss";
import { withRouter } from "react-router-dom";
import PlaceholderImg from "res/icons/completion.gif";
import { Trans, useTranslation } from "react-i18next";
import Modal from "components/molecules/Modal";
import Text from "../../../../components/atoms/Text";
import Image from "../../../../components/atoms/Image";
import Button from "../../../../components/atoms/Button";
/**
 *
 * @function CompletionModal
 * Completion modal pops up when the course is finished
 *
 */
const CompletionModal = (
  {
    history,
    title,
    className,
    isModalOpen,
    closeModal,
    imgUrl,
  },
) => {
  const { t } = useTranslation();
  // Combines all class into a single string
  const composedClass = composeClassName(
    "we CompletionModal",
    className,
  );

  const onConfirm = () => {
    closeModal();
    const count = history.location.state?.count || 1;
    history.go(-count);
  };

  const modalContent = (
    <div className={composedClass}>
      <Text className="completionTitle">{t("congratulation")}</Text>
      <Text className="completionContent">
        <Trans i18nKey="course_exit_modal" title={title}>
          You have completed the
          <strong>{{ title }}</strong>
          course. We encourage you to keep learning.
          Select your next Trauma Informed Practice course now!
        </Trans>
      </Text>
      <Image className="completionImage" src={imgUrl || PlaceholderImg} alt="completion image" />
      <Button className="completeButton" onClick={onConfirm}>
        {t("end_course")}
      </Button>
    </div>
  );

  return (
    <Modal isOpen={isModalOpen} hideCloseButton onClose={closeModal}>
      {modalContent}
    </Modal>
  );
};

CompletionModal.propTypes = {
  /**
   *
   * title: the name of the current course
   * className: optional styling for the CompletionModal
   * isModalOpen: current open state of the modal
   * imgUrl: A url specific to the course, defaults to a placeholder image
   * closeModal: function that is called when the 'end course' button is presseed
   *
   */
  title: PropTypes.string,
  className: PropTypes.string,
  isModalOpen: PropTypes.bool,
  imgUrl: PropTypes.string,
  closeModal: PropTypes.func,
};

CompletionModal.defaultProps = {
  title: "Course",
  className: "",
  isModalOpen: false,
};

export default withRouter(CompletionModal);
