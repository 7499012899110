import React from "react";
import { forgotPassword } from "modules/account/api";
import { useMutation } from "react-query";

const withInteractor = (Presenter) => {
  const Interactor = (props) => {
    const { mutate } = useMutation(forgotPassword);
    return (
      <Presenter
        forgotPassword={mutate}
        {...props}
      />
    );
  };

  return Interactor;
};

export default withInteractor;
