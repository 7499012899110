import React from "react";
import PropTypes from "prop-types";

// Utils
import { composeClassName } from "utils";

// Style sheet
import "./SubHeader.scss";

import { useHistory } from "react-router-dom";
import Button from "components/atoms/Button";
import Text from "../../atoms/Text";
import ArrowBackIcon from "../../atoms/ArrowBackIcon";

/**
 *
 * @function SubHeader
 * Sub-Header is the header under the top navigation bar. Tt displays the course
 * name and a back button to go back to the page it came from.
 *
 */
const SubHeader = ({ title, className, exitLabel }) => {
  const history = useHistory();
  // Combines all class into a single string
  const composedClass = composeClassName(
    "we subheader",
    className,
  );

  const onBack = () => {
    const count = history.location.state?.count || 1;
    history.go(-count);
  };

  return (
    <div className={composedClass}>
      <Button
        className="we exit"
        variant={Button.variants.text}
        onClick={onBack}>
        <ArrowBackIcon alt="alt" />
        {exitLabel}
      </Button>
      <Text className="we title">{title}</Text>
    </div>
  );
};

SubHeader.propTypes = {
  /**
   *
   * title: the name of the current page
   * className: optional styling for the subheader
   *
   */
  title: PropTypes.string,
  className: PropTypes.string,
};

SubHeader.defaultProps = {
  title: "Page Name",
  className: "",
};

export default SubHeader;
