import gql from "graphql-tag";
import { graphql } from "react-apollo";
import PropTypes from "prop-types";
import QueryResults from "./QueryResults";
import FormFieldFields from "../fragments/types/FormFieldFields";
import RatingFormMetaDataFields from "../fragments/types/RatingFormMetaDataFields";
import ImageFields from "../fragments/types/ImageFields";

export const RATING_FORM_QUERY = gql`
  query ratingForm {
    page:ratingForm {
      id
      formId
      formHeader
      formSubmitButtonText
      formSubmittedModalImage {
        ...ImageFields
      }
      formSubmittedHeader
      formSubmittedBodyText
      formDismissButtonText
      fieldsData {
        fields {
          ...FormFieldFields
        }
        metaFields{
          ...RatingFormMetaDataFields
        }
      }
    }
  }
  ${ImageFields}
  ${FormFieldFields}
  ${RatingFormMetaDataFields}
`;

const RatingFormQuery = graphql(RATING_FORM_QUERY)(QueryResults);

RatingFormQuery.propTypes = {
  onLoading: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onData: PropTypes.func.isRequired,
};

RatingFormQuery.defaultProps = {
};

export default RatingFormQuery;
