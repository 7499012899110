import React, { useState } from "react";
import PropTypes from "prop-types";

// Translation hook
import { useTranslation } from "react-i18next";

import Button from "components/atoms/Button";
import BootstrapForm from "react-bootstrap/Form";
import Input from "components/molecules/Input";
import Checkbox from "components/molecules/Checkbox/Checkbox";
import Text from "components/atoms/Text/Text";

import CountriesList from "./res/countries.json";
import StatesList from "./res/states.json";

// Style sheet
import "./LocationCaptureForm.scss";

const RELEVANT_COUNTRIES_STATE = {
  "United States": {
    label: "location_capture.form.labels.state.us",
    placeholder: "location_capture.form.placeholders.state.us",
  },
  Canada: {
    label: "location_capture.form.labels.state.ca",
    placeholder: "location_capture.form.placeholders.state.ca",
  },
};

const RELEVANT_COUNTRIES_POSTAL = {
  "United States": {
    label: "location_capture.form.labels.postal.us",
    placeholder: "location_capture.form.placeholders.postal.us",
  },
  Canada: {
    label: "location_capture.form.labels.postal.ca",
    placeholder: "location_capture.form.placeholders.postal.ca",
  },
  "United Kingdom": {
    label: "location_capture.form.labels.postal.uk",
    placeholder: "location_capture.form.placeholders.postal.uk",
  },
};

/**
 * Whether or not to render state input field.
 * (Form should only render state input field for US and Canada)
 * @param {string} country
 * @returns bool
 */
const _shouldRenderStateInput = (country) => {
  return !!RELEVANT_COUNTRIES_STATE[country];
};

/**
 * Whether or not to render postal input field.
 * (Form should only render postal input field for US, UK and Canada)
 * @param {string} country
 * @returns bool
 */
const _shouldRenderPostalInput = (country) => {
  return !!RELEVANT_COUNTRIES_POSTAL[country];
};

/**
 * Whether or not form meets requirements:
 * - Has valid country value
 * - Has state value if required
 * - Has postal value if required
 * @param {Object} values
 * @returns bool
 */
const _isFormIncomplete = ({ country, state, postal }) => {
  const stateRequired = _shouldRenderStateInput(country);
  const postalRequired = _shouldRenderPostalInput(country);

  const countryMissing = !CountriesList.includes(country);
  const stateMissing = stateRequired && state === "";
  const postalMissing = postalRequired && postal === "";

  return countryMissing || stateMissing || postalMissing;
};

/**
 * Returns country options for country input field
 */
const _getCountryOptions = () => {
  const CountryOptions = CountriesList.map((name) => {
    return (<option value={name} key={name}>{name}</option>);
  });
  return CountryOptions;
};

/**
 * Returns country input field
 * @param {Object} values
 * @param {Function} handleChange
 * @param {function} t i18n translator
 */
const _getCountryInput = ({ country }, handleChange, t) => {
  const CountryOptions = _getCountryOptions();
  const placeholder = "location_capture.form.placeholders.country";

  return (
    <Input
      className="we LocationCaptureForm"
      variant={Input.variants.select}
      name="country"
      label={t("location_capture.form.labels.country")}
      onChange={handleChange}
      value={country}
    >
      <option value="">{t(placeholder)}</option>
      {CountryOptions}
    </Input>
  );
};

/**
 * Returns state options in specified country for state input field
 * @param {string} country
 */
const _getStateOptions = (country) => {
  const StatesNames = StatesList[country];
  const StatesOptions = StatesNames.map((name) => {
    return (<option value={name} key={name}>{name}</option>);
  });
  return StatesOptions;
};

/**
 * Returns state input if required
 * @param {Object} values
 * @param {Function} handleChange
 * @param {function} t i18n translator
 */
const _getStateInput = ({ country, state }, handleChange, t) => {
  if (!_shouldRenderStateInput(country)) return "";

  const { label, placeholder } = RELEVANT_COUNTRIES_STATE[country];

  const StatesOptions = _getStateOptions(country);

  return (
    <Input
      className="we LocationCaptureForm"
      name="state"
      variant={Input.variants.select}
      label={t(label)}
      onChange={handleChange}
      value={state}
    >
      <option value="">{t(placeholder)}</option>
      {StatesOptions}
    </Input>
  );
};

/**
 * Returns postal input if required
 * @param {Object} values
 * @param {Function} handleChange
 * @param {function} t i18n translator
 */
const _getPostalInput = ({ country, postal }, handleChange, t) => {
  if (!_shouldRenderPostalInput(country)) return "";

  const { label, placeholder } = RELEVANT_COUNTRIES_POSTAL[country];
  return (
    <Input
      className="we LocationCaptureForm"
      name="postal"
      label={t(label)}
      placeholder={t(placeholder)}
      onChange={handleChange}
      value={postal}
    />
  );
};

/**
 * @param {Object} values
 * @param {Function} handleChange
 * @param {function} t i18n translator
 */
const _getCommunicationPreference = (values, setValues, t) => {
  const { communicationPreference } = values;
  const handleSelectCheckbox = () => {
    if (communicationPreference === true) {
      setValues({ ...values, communicationPreference: false });
    } else {
      setValues({ ...values, communicationPreference: true });
    }
  };

  return (
    <div className="we LocationCaptureForm">
      <Checkbox className="checkbox" onChange={handleSelectCheckbox} selected={communicationPreference} />
      <Text className="checkbox-label">
        { t("location_capture.form.communication_preference") }
      </Text>
    </div>
  );
};

/**
 * Returns submit button
 * @param {Object} values
 * @param {bool} loading
 * @param {function} t i18n translator
 */
const _getSubmitButton = (values, loading, t) => {
  const labelNs = "location_capture.form.labels";
  const label = loading ? `${labelNs}.loading` : `${labelNs}.submit`;

  // Disable button if form is incomplete or in loading state
  const disabled = _isFormIncomplete(values) || loading;

  return (
    <Button
      className="we LocationCaptureForm Button"
      theme="light"
      variant={Button.variants.primary}
      type="submit"
      disabled={disabled}
    >
      {t(label)}
    </Button>
  );
};

/**
 * Returns method that will update values state on input change
 * @param {Object} values
 * @param {function} setValues
 */
const _handleInputChange = (values, setValues) => {
  return ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };
};

/**
*
* @function LocationCaptureForm
*
*/
const LocationCaptureForm = ({
  loading,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const [values, setValues] = useState({
    country: "",
    state: "",
    postal: "",
    communicationPreference: false,
  });

  const _handleChange = _handleInputChange(values, setValues);

  const CountryInput = _getCountryInput(values, _handleChange, t);
  const ProvinceInput = _getStateInput(values, _handleChange, t);
  const PostalInput = _getPostalInput(values, _handleChange, t);
  const SubmitButton = _getSubmitButton(values, loading, t);
  const CommunicationPreference = _getCommunicationPreference(values, setValues, t);

  return (
    <BootstrapForm onSubmit={(e) => {
      e.preventDefault();
      onSubmit(values);
    }}>
      { CountryInput }
      { ProvinceInput }
      { PostalInput }
      { CommunicationPreference }
      { SubmitButton }
    </BootstrapForm>
  );
};

// Private Functions
LocationCaptureForm._isFormIncomplete = _isFormIncomplete;
LocationCaptureForm._shouldRenderStateInput = _shouldRenderStateInput;
LocationCaptureForm._shouldRenderPostalInput = _shouldRenderPostalInput;

LocationCaptureForm.propTypes = {
  /**
   * Form is in loading state (after submission)
   */
  loading: PropTypes.bool,

  /**
   * Method called on form submission
   */
  onSubmit: PropTypes.func.isRequired,
};

export default LocationCaptureForm;
