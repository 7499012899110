import React from "react";
import PropTypes from "prop-types";

// Style sheet
import "./FeedItems.scss";

const _buildFeedItems = (feed, blocks) => {
  return feed.map((item) => {
    const { id, type } = item;
    const BlockComponent = blocks[type];
    if (BlockComponent) {
      return (<BlockComponent key={id} block={item} />);
    }
    return (<div key={id}>{`unknown type ${type}`}</div>);
  });
};

const FeedItems = ({ feed, blocks }) => {
  const feedItems = _buildFeedItems(feed, blocks);
  return (
    <div className="we Feed">
      {feedItems}
    </div>
  );
};

FeedItems._buildFeedItems = _buildFeedItems;

FeedItems.propTypes = {
  feed: PropTypes.array.isRequired,
  blocks: PropTypes.object.isRequired,
};

FeedItems.defaultProps = {
};

export default FeedItems;
