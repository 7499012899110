import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Container } from "react-bootstrap";
import SignInBlock from "modules/account/blocks/SignInBlock";
import WarningBlock from "modules/account/blocks/WarningBlock";
import Image from "components/atoms/Image";
import ForgotPasswordBlock from "./blocks/forgotPasswordBlock";
import CreatePasswordBlock from "./blocks/createPasswordBlock";
import ResetSentBlock from "./blocks/resetSentBlock";
import ResetSuccessfulBlock from "./blocks/resetSuccessfulBlock/ResetSuccessful";
import "./AuthPage.scss";

import SignUpBlock from "./blocks/signUpBlock";
import CompleteProfileBlock from "./blocks/completeProfileBlock";
import FormSubmittedBlock from "./blocks/FormSubmittedBlock";

export const routes = {
  signIn: "/account/signIn",
  forgotPassword: "/account/forgotPassword",
  completeProfile: "/account/completeProfile",
  resetLinkSent: "/account/resetSent",
  createPassword: "/account/createPassword",
  signUp: "/account/signUp",
  successfulReset: "/account/successfulReset",
  ssoWarning: "/account/ssoWarning",
  formSubmitted: "/account/formSubmitted",
  invalid: "/",
};

const AuthPage = (params) => {
  const { loginBackground, privacyPolicyLink } = params;
  const [email, setEmail] = useState("");

  return (
    <Image className="we auth-page" src={loginBackground || {}}>
      <Container className="block-container">
        <Switch>
          <Route exact path={routes.signUp}>
            <SignUpBlock privacyPolicyLink={privacyPolicyLink} />
          </Route>
          <Route exact path={routes.completeProfile}>
            <CompleteProfileBlock />
          </Route>
          <Route exact path={routes.forgotPassword}>
            <ForgotPasswordBlock email={email} setEmail={setEmail} />
          </Route>
          <Route exact path={routes.createPassword}>
            <CreatePasswordBlock />
          </Route>
          <Route exact path={routes.resetLinkSent}>
            <ResetSentBlock email={email} />
          </Route>
          <Route exact path={routes.successfulReset}>
            <ResetSuccessfulBlock />
          </Route>
          <Route exact path={routes.signIn}>
            <SignInBlock />
          </Route>
          <Route exact path={routes.ssoWarning}>
            <WarningBlock />
          </Route>
          <Route exact path={routes.formSubmitted}>
            <FormSubmittedBlock />
          </Route>
          <Route path={routes.invalid}>
            <Redirect to={routes.signIn} />
          </Route>
        </Switch>
      </Container>
    </Image>
  );
};
export default AuthPage;
