import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Switch, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";


class AppRoutes extends PureComponent {
  static buildReactRoutes(routes) {
    return routes.map((item) => {
      return (
        <PrivateRoute
          key={item.path}
          path={item.path}
          exact={item.exactPath}
          component={item.component} />
      );
    });
  }

  static propTypes = {
    appRoutes: PropTypes.array.isRequired,
    fallbackPath: PropTypes.string,
  };

  static defaultProps = {
    appRoutes: [],
    fallbackPath: "/",
  };


  render() {
    const { appRoutes, fallbackPath } = this.props;

    // Filter out external links
    const allInternalAppRoutes = appRoutes.filter((item) => { return !item.externalLink; });
    // Build App Routes
    const reactRoutes = AppRoutes.buildReactRoutes(allInternalAppRoutes);

    return (
      <Switch>
        {reactRoutes}
        {/* Any unknown routes should be redirected to fallback path */}
        <Redirect to={fallbackPath} exact />
      </Switch>
    );
  }
}

export default AppRoutes;
