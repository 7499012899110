import React from "react";

// App components
import Heading from "components/atoms/Heading";
import Text from "components/atoms/Text";

// Style sheet
import "./ErrorMessage.scss";


/**
*
* @function ErrorMessage
* Renders the error message shown to users when an uncaught error is raised
*
*/
const ErrorMessage = ({ title, message }) => {
  return (
    <div className="we ErrorMessage">
      <Heading variant={Heading.variants.block}>
        { title }
      </Heading>
      <Text>
        { message }
      </Text>
    </div>
  );
};

export default ErrorMessage;
