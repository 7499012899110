import gql from "graphql-tag";

export default gql`
  fragment FeaturedCollectionBlockFields on FeaturedCollectionBlock {
    id
    type
    collection {
      ...CollectionFields
    }
  }
`;
