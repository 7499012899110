import React from "react";
import PropTypes from "prop-types";

// Style sheet
import "./FeaturedCollectionsBlock.scss";

// Bootstrap Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// App Components
import Block from "components/molecules/Block";
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";
import Heading from "components/atoms/Heading";
import ArrowRightIcon from "components/atoms/ArrowRightIcon";

/**
*
* @function FeaturedCollectionsBlock
* Featured Collections Block (https://zpl.io/Vx6GKN3)
*
* @param {Object} block - Block properties from SB
*
*/
const FeaturedCollectionsBlock = ({ block }) => {
  const { collection1, collection2 } = block;
  return (
    <Block className="FeaturedCollectionsBlock">
      <Row>
        <FeaturedCollectionsTile {...collection1} />
        <FeaturedCollectionsTile {...collection2} />
      </Row>
    </Block>
  );
};

const FeaturedCollectionsTile = ({
  promoCardTitle,
  promoCardImage,
  promoCardImageAlt,
  collectionPage,
  promoCardButton,
  theme,
}) => {
  const { enabled: buttonEnabled, text: buttonText } = promoCardButton;
  const { url: href, external: externalLink } = collectionPage;

  return (
    <Col className="FeaturedCollectionsTile" md={6}>
      <Image
        className="block-image"
        src={promoCardImage || ""}
        alt={promoCardImageAlt}
      >
        <div className="tile-content">
          <div className="tile-title">
            <Heading theme={theme} variant={Heading.variants.card}>
              {promoCardTitle}
            </Heading>
          </div>
          <div className="tile-footer">
            <Button
              theme={theme}
              variant={Button.variants.text}
              className="tile-action"
              href={href}
              externalLink={externalLink}
              disabled={!buttonEnabled}
            >
              {buttonText}
              <ArrowRightIcon alt={buttonText} theme={theme} />
            </Button>
          </div>
        </div>
      </Image>
    </Col>
  );
};


FeaturedCollectionsBlock.propTypes = {
  block: PropTypes.any.isRequired,
};

export default FeaturedCollectionsBlock;
