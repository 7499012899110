import React from "react";
import PropTypes from "prop-types";
import { PageQuery, FilterPageQuery, SearchPageQuery } from "graphql/queries";
import ErrorHandler from "components/molecules/ErrorHandler";
import { parseQueryParams } from "utils";

// Error Handling
import { ErrorBoundary } from "modules/error";

// Blocks
import Spinner from "components/atoms/Spinner";

const PageTemplate = ({ page, templates, blocks, children }) => {
  const TemplateComponent = templates[page.type];
  if (TemplateComponent) {
    return (
      <TemplateComponent page={page} blocks={blocks}>
        {children}
      </TemplateComponent>
    );
  }
  return <div>{`unknown type ${page.type}`}</div>;
};

// TODO: Refactor as part of WS-252 (https://redthreadinnovations.atlassian.net/browse/WS-252)
const _onLoadError = (error) => {
  return <ErrorHandler error={error} />;
};

const variants = {
  page: "page",
  filter: "filter",
  search: "search",
};

const _getQuery = (variant) => {
  switch (variant) {
    case variants.filter:
      return FilterPageQuery;
    case variants.search:
      return SearchPageQuery;
    default:
      return PageQuery;
  }
};

const Page = ({ location, match, templates, blocks, variant }) => {
  let queryParams;
  if (location) {
    queryParams = parseQueryParams(location.search);
  } else {
    queryParams = {};
  }
  const { url, params } = match;

  const queryProps = {
    ...params,
    ...queryParams,
    slug: url,
  };

  const Query = _getQuery(variant);
  return (
    <ErrorBoundary key={url}>
      <Query
        {...queryProps}
        onLoading={() => {
          return (<Spinner />);
        }}
        onError={_onLoadError}
        onData={({ page }) => {
          const pageProps = { page, templates, blocks };
          return <PageTemplate {...pageProps} />;
        }}
      />
    </ErrorBoundary>
  );
};

Page.PageTemplate = PageTemplate;
Page._onLoadError = _onLoadError;
Page.variants = variants;

Page.propTypes = {
  match: PropTypes.object.isRequired,
  templates: PropTypes.object.isRequired,
  blocks: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(Object.keys(variants)),
};

Page.defaultProps = {
  variant: variants.page,
};

export default Page;
