import React from "react";
import PropTypes from "prop-types";

// Node Module Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// App Components
import Heading from "components/atoms/Heading";
import Block from "components/molecules/Block";
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";
import Text from "components/atoms/Text";
import Tag from "components/atoms/Tag";

// Utils
import { composeClassName } from "utils";

// Style sheet
import "./LiveWebinarBlock.scss";


const _buildButton = (variant, button) => {
  if (button && (button.text || button.enabled)) {
    const { externalLink, ...rest } = Button.extractButtonPropsFromButtonObject(button);
    return (
      <Button
        target="_blank"
        variant={variant}
        {...rest}
        externalLink
                />
    );
  }
  return null;
};

/**
* @function LiveWebinarBlock
* Live webinar block (https://zpl.io/agWQdZD)
*
* @param {Object} block - Block properties
*
*/
const LiveWebinarBlock = ({ block }) => {
  // Combines all class into a single string
  const composedClass = composeClassName("we LiveWebinarBlock");

  // Extract storyblock props
  const {
    webinar: {
      title,
      bodyText,
      thumbnailImage,
      liveButton,
    },
  } = block;

  const primaryButton = _buildButton(Button.variants.primary, liveButton);
  return (
    <Block className={composedClass}>
      <Row>
        {/* Image Container */}
        <Col className="image-container" md={5}>
          <Image src={thumbnailImage} />
        </Col>

        {/* Content Container */}
        <Col md={7} className="content-container">
          <Tag theme="danger" variant="outline" size="big">
            LIVE NOW
          </Tag>
          <Heading
            variant={Heading.variants.card}>
            {title}
          </Heading>
          <Text>
            {bodyText}
          </Text>
          {primaryButton}
        </Col>
      </Row>
    </Block>
  );
};


LiveWebinarBlock.propTypes = {
  /**
    * Block props from StoryBlok
    * {
    *   id,
    *   type,
    *   webinar,
    * }
    */
  block: PropTypes.object.isRequired,
};

export default LiveWebinarBlock;
