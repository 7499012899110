import React from "react";
import PropTypes from "prop-types";

// Bootstrap components
import BootstrapButton from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

// Contants
import { THEMES } from "lib/constants";

// Utils
import { composeClassName } from "utils";

// Style sheet
import "./Button.scss";

/**
   *
   * Various types of buttons
   * @typedef {Object} Variants
   * @property {String} primary Used for primary CTAs
   * @property {String} secondary Used for secondary less important CTAs
   *
   */
const variants = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  text: "text",
  google: "google",
  facebook: "facebook",
  apple: "apple",
};

const InternalButtonLink = (props) => {
  const { href, ...rest } = props;
  return (
    <LinkContainer to={href} exact>
      <BootstrapButton {...rest} />
    </LinkContainer>
  );
};

/**
 * builds a button link. Handles buttons that have internal or external links
 * @param {String} path
 * @param {Boolean} externalLink
 * @param {Object} children
 *
 * @returns {InternalButtonLink|BootstrapButton}
 */
const _getButton = (href, externalLink) => {
  if (href && !externalLink) {
    return InternalButtonLink;
  }
  return BootstrapButton;
};

const Button = ({
  theme,
  variant,
  type,
  href,
  externalLink,
  disabled,
  target,
  onClick,
  children,
  className,
}) => {
  const composedClassName = composeClassName("we Button", className, theme, variant);
  const WeButton = _getButton(href, externalLink);

  return (
    <WeButton
      className={composedClassName}
      href={href}
      disabled={disabled}
      target={target}
      type={type}
      onClick={onClick}
    >
      {children}
    </WeButton>
  );
};


/**
 * TODO: Remove when button model has been updated
 * Helper function to extract button prop values from
 * graphql payload before passing directly into button.
 * This is a temporary workaround
 * @param {Object} button - button object
 *
 * @returns {String} - NavLink HTML
 */
const extractButtonPropsFromButtonObject = (button) => {
  const { link, text: children, enabled } = button;
  const disabled = !enabled;
  const { external: externalLink, url: href } = link;
  return { href, externalLink, children, disabled };
};

Button.extractButtonPropsFromButtonObject = extractButtonPropsFromButtonObject;
Button.InternalButtonLink = InternalButtonLink;
Button.variants = variants;

Button.propTypes = {
  /**
    *
    * Refers to the backdrop of the parent component.
    * Used to define color variations that aestically optimize
    * the element's presention within the bounded context of its parent.
    *
    */
  theme: PropTypes.oneOf(Object.keys(THEMES)),

  /**
    * The variant changes border, background and font color based on theme.
    */
  variant: PropTypes.oneOf(Object.keys(variants)),

  /**
     * Optional href to internal or exteral page
     */
  href: PropTypes.string,

  /**
    * The color that all variations are based on.
    */
  externalLink: PropTypes.bool,

  /**
   * Renders the button inactive(not clickable and greyed out)
   */
  disabled: PropTypes.bool,

  /**
   * Button type (e.g. Submit)
   */
  type: PropTypes.string,

  /**
   * Specifies where to open the linked document
   */
  target: PropTypes.string,

  /**
   * Handler function for onClick event
   */
  onClick: PropTypes.func,

};

Button.defaultProps = {
  externalLink: false,
  theme: THEMES.light,
  variant: variants.primary,
  disabled: false,
  target: "_self",
  onClick: null,
};

export default Button;
