import gql from "graphql-tag";

export default gql`
  fragment SettingsFields on Settings {
    id
    mainLogo {
      ...ImageFields
    }
    loginBackground {
      ...ImageFields
    }
    menuItems {
      ...MenuItemFields
    }
  }
`;
