import React from "react";
import Input from "components/molecules/Input/Input";
import Heading from "components/atoms/Heading/Heading";
import Button from "components/atoms/Button/Button";
import Block from "components/molecules/Block/Block";
import "./CompleteProfileBlock.scss";

const CreatePasswordBlock = (props) => {
  const { blockHeading, firstNameTextField, lastNameTextField,
    emailTextField, completeButton } = props;
  const { label: completeButtonLabel, ...completeButtonProps } = completeButton;
  // TODO social sign up, waiting on buttons
  return (
    <Block className="completeProfileBlock">
      <div className="headingContent">
        <Heading className="blockHeading">{blockHeading.title}</Heading>
      </div>
      <div className="form">
        <Input {...firstNameTextField} />
        <Input {...lastNameTextField} />
        <Input {...emailTextField} />
        <Button {...completeButtonProps}>{completeButtonLabel}</Button>
      </div>
    </Block>
  );
};


export default CreatePasswordBlock;
