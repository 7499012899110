import React from "react";
import { composeClassName } from "utils";
import Text from "components/atoms/Text";

import "./RadioOption.scss";

const RadioOption = ({ className, id, value, onChange, selected, label }) => {
  const indicatorClassName = composeClassName(
    "RadioOption__Indicator",
    selected ? "Selected" : "",
  );

  const composedClassName = composeClassName(
    className,
    "we RadioOption",
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onChange();
    }
  };

  return (
    <div
      className={composedClassName}
      onClick={onChange}
      onKeyDown={handleKeyDown}
      htmlFor={id}
      tabIndex={0}
      role="menuitemradio"
      aria-checked={selected}
    >
      <input value={value} hidden aria-hidden checked={selected} id={id} onChange={onChange} />
      <span className={indicatorClassName} />
      <Text className="we RadioLabel">{label}</Text>
    </div>
  );
};

export default RadioOption;
