import gql from "graphql-tag";

export default gql`
  fragment PageFields on Page {
    id
    type
    ... on FeedPage {
      ...FeedPageFields
    }
    ... on ProgramPage {
      ...ProgramPageFields
    }
    ... on CampaignPage {
      ...CampaignPageFields
    }
    ... on CollectionPage {
      ...CollectionPageFields
    }
    ... on FilterPage {
      ...FilterPageFields
    }
    ... on SearchPage {
      ...SearchPageFields
    }
  }
`;
