import gql from "graphql-tag";
import { graphql } from "react-apollo";
import PropTypes from "prop-types";
import QueryResults from "./QueryResults";

import SettingsFields from "../fragments/settings/SettingsFields";
import MenuItemFields from "../fragments/settings/MenuItemFields";
import ImageFields from "../fragments/types/ImageFields";
import LinkFields from "../fragments/types/LinkFields";

export const SETTINGS_QUERY = gql`
  query settings {
    page:settings {
        ...SettingsFields
    }
  }
  ${SettingsFields}
  ${MenuItemFields}
  ${ImageFields}
  ${LinkFields}
`;

// SEARCH_PAGE_QUERY requires a $searchTerm, $filterValue variables.
// These variables are autoset by graphql() if a matching prop is found.
const SettingsQuery = graphql(SETTINGS_QUERY)(QueryResults);

SettingsQuery.propTypes = {
  onLoading: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onData: PropTypes.func.isRequired,

};

SettingsQuery.defaultProps = {
};

export default SettingsQuery;
