import React from "react";
import PropTypes from "prop-types";

// Sass Imports
import "./NavIcon.scss";

function NavIcon(props) {
  const { icon, label } = props;
  return (<img className="we nav-icon" src={icon} alt={`${label} Navigation Icon`} />);
}

NavIcon.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default NavIcon;
