import gql from "graphql-tag";

export default gql`
  fragment ClassroomResourceListBlockFields on ClassroomResourceListBlock {
    id
    type
    title
    resources {
      ...ClassroomResourceFields
    }
  }
`;
