import React, { useContext } from "react";
import PropTypes from "prop-types";

// App Components
import Block from "components/molecules/Block";
import Card from "components/molecules/Card";
import Image from "components/atoms/Image";
import Heading from "components/atoms/Heading";
import Button from "components/atoms/Button";
import Text from "components/atoms/Text";
import { useTranslation } from "react-i18next";
import ExpandableList from "components/organisms/ExpandableList";
import ExpandableListButton from "components/molecules/ExpandableListButton";
import Row from "react-bootstrap/Row";
import { VideoModalContext } from "../../../../components/molecules/VideoModal";
import { trackVideoView } from "../../../../utils/trackingUtils";

// Translation hook
import RatingWidget from "./RatingWidget";

// Style sheet
import "./ClassroomResourceListBlock.scss";

const ListAdapter = ({ items, renderCount }) => {
  const displayItems = items.slice(0, renderCount).map((item) => {
    return <BuildResourceCard resourceBlock={item} />;
  });
  return (<Row>{displayItems}</Row>);
};

const isVimeoUrl = (url) => {
  return (/^(https?:)?\/\/((player|www)\.)?vimeo\.com(?=$|\/)/).test(url);
};

const _getPlayVideo = (openVideoModal, videoSrc) => {
  return () => {
    const video = {
      title: "Video title not available",
      url: videoSrc,
    };
    trackVideoView(video);
    openVideoModal(videoSrc);
  };
};

/**
  *
  * @function _buildButton
  * Lays out a single Button
  * @param {string} variant
  * @param {object} button
  *
  * @returns {Function}
  */
const _buildButton = (variant, button) => {
  const { openVideoModal } = useContext(VideoModalContext);
  if (button && (button.text || button.enabled)) {
    const { externalLink, ...rest } = Button.extractButtonPropsFromButtonObject(button);
    const target = externalLink ? "_blank" : "_self";
    const videoSrc = rest.href;
    const _playVideo = _getPlayVideo(openVideoModal, videoSrc);
    if (isVimeoUrl(videoSrc)) {
      return (
        <Button
          className="cta-button"
          variant={variant}
          {...rest}
          href={null}
          onClick={_playVideo}
        />
      );
    }
    return (
      <Button
        className="cta-button"
        variant={variant}
        {...rest}
        target={target}
        externalLink
      />
    );
  }
  return null;
};

const _buildRecommendedText = (recommendedFor) => {
  let name = "";
  const { t } = useTranslation();

  if (recommendedFor) {
    name = recommendedFor.map((age) => {
      return age.name;
    }).join(", ");
  }
  if (name) {
    const textKey = "classroom_resource.recommendedFor";
    const text = t(textKey);

    return (
      <Text className="recommendedFor">
        {text}
        <span className="age">{name}</span>
      </Text>
    );
  }
  return null;
};


/**
*
* @function BuildResourceCard
* Lays out a single Classroom Resource Card
* @param {object} coverImage
* @param {string} topic
* @param {string} title
* @param {string} bodyText
* @param {string} recommendedFor
* @param {object} downloadButton
* @param {object} supplementaryMaterialButton
*
* @returns {Function}
*/

const BuildResourceCard = ({ resourceBlock }) => {
  const {
    id,
    coverImage,
    topic,
    title,
    bodyText,
    recommendedFor,
    downloadButton,
    supplementaryMaterialButton,
  } = resourceBlock;
  const primaryBtn = _buildButton("primary", downloadButton);
  const secondaryBtn = _buildButton("secondary", supplementaryMaterialButton);
  const recommendedForText = _buildRecommendedText(recommendedFor);
  return (
    <Card key={id}>
      <Card.Content>
        <Image src={coverImage} />
        <div className="inner-content">
          <Text variant="overline">{topic.name}</Text>
          <Heading variant={Heading.variants.card}>{title}</Heading>
          <Text className="bodyText">{bodyText}</Text>
          {recommendedForText}
          <RatingWidget className="rating-widget" resourceBlock={resourceBlock} />
          <div className="button-group">
            {primaryBtn}
            {secondaryBtn}
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

/**
*
* @function ClassroomResourceListBlock
* Renders a list of Classroom Resources
* @param {object} block - Storyblock data model
*
* @returns {Function}
*/

const ClassroomResourceListBlock = ({ block }) => {
  const { id } = block;
  const { title, resources } = block;
  let resourceCards;
  if (id.includes("query")) {
    resourceCards = (
      <ExpandableList
        items={resources}
        adapter={ListAdapter}
        displayCount={3}
        expandButton={ExpandableListButton}
    />
    );
  } else {
    resourceCards = resources.map((resource) => {
      return <BuildResourceCard resourceBlock={resource} />;
    });
  }

  return (
    <Block
      title={title}
      className="we ClassroomResourceListBlock">
      {resourceCards}
    </Block>
  );
};


ClassroomResourceListBlock.propTypes = {

  /**
    * Storblock datamodel
    */
  block: PropTypes.object.isRequired,

};

export default ClassroomResourceListBlock;
