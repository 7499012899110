import React, { useContext } from "react";
import PropTypes from "prop-types";

import { SettingsQuery } from "graphql/queries";

// Context
import { AuthContext, Logout } from "modules/auth";

import Page from "modules/page";
import BlockItems from "./BlockItems";
import PageTemplates from "./PageTemplates";

// TODO: Refactor these to be included in the Nav Modules.
/**
 * route
 * @typedef {Object} route
 * @property {string} path - href
 *  - relative path for internal links eg. "/relative_path "
 *  - fully qualified url for external links
 * @property {boolean} [exactPath = false] - whether the path in the url needs to match
 * exactly to path
 * @property {string} label - The text that will be visible in the Nav
 * @property {function} [component] - A page commponent. Required for internal links
 * @property {string} [icon] - Url to icon that will displayed in Nav
 * @property {boolean} [externalLink] - must be set to true for externalLinks
 */

// These routes are used to build the navigation.

const topNavRoutes = [
  {
    path: "/account/forgotPassword",
    label: "Change Password",
  },
  {
    path: "/logout",
    label: "Logout",
  },
];

const PageRoute = (props) => {
  return <Page {...props} blocks={BlockItems} templates={PageTemplates} />;
};

const FilterPageRoute = (props) => {
  return (
    <Page
      {...props}
      blocks={BlockItems}
      templates={PageTemplates}
      variant={Page.variants.filter}
    />
  );
};
const SearchPageRoute = (props) => {
  return (
    <Page
      {...props}
      blocks={BlockItems}
      templates={PageTemplates}
      variant={Page.variants.search}
    />
  );
};

/**
 * We want to restrict adding menu items routes that could override default app routes.
*/
const ignoreBaseRoutes = ["account", "programs", "campaigns", "collections", "filter", "search", "logout"];
const defaultAppRoutesPattern = new RegExp(`^/(${ignoreBaseRoutes.join("|")})`, "gi");

const defaultAppRoutes = [
  {
    path: "/programs/:program",
    exactPath: false,
    component: PageRoute,
  },
  {
    path: "/programs/:program",
    exactPath: false,
    component: PageRoute,
  },
  {
    path: "/campaigns/:campaign",
    exactPath: false,
    component: PageRoute,
  },
  {
    path: "/collections/:collection",
    exactPath: false,
    component: PageRoute,
  },
  {
    path: "/filter",
    exactPath: true,
    component: FilterPageRoute,
  },
  {
    path: "/search",
    exactPath: true,
    component: SearchPageRoute,
  },
  {
    path: "/logout",
    exactPath: true,
    component: Logout,
  },
  {
    path: "/:any_path",
    exactPath: false,
    component: PageRoute,
  },
];

const AppRouterItems = ({ layout: Layout }) => {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <SettingsQuery
      onLoading={() => {
        return (
          <Layout
            topNavRoutes={[]}
            sideNavRoutes={[]}
            appRoutes={[]}
            authenticated={isAuthenticated}
        />
        );
      }}
      onError={() => {
        return (
          <Layout
            topNavRoutes={[]}
            sideNavRoutes={[]}
            appRoutes={[]}
            authenticated={isAuthenticated}
        />
        );
      }}
      onData={({ page }) => {
        const sideNavRoutes = [];
        const menuRoutes = [];
        let privacyPolicyLink = "";

        page.menuItems.forEach(({ icon, label, destination, bottomAlign }) => {
          const destinationPath = destination.url;
          sideNavRoutes.push({
            icon: icon.desktop,
            label: label,
            path: destinationPath,
            externalLink: destination.external,
            bottom: bottomAlign === true,
          });

          if (!destination.external && !defaultAppRoutesPattern.test(destinationPath)) {
            menuRoutes.push({
              path: destinationPath,
              exactPath: true,
              component: PageRoute,
            });
          }

          if (label.toLowerCase() === "terms of use") {
            privacyPolicyLink = destination.url;
          }
        });

        const appRoutes = [...menuRoutes, ...defaultAppRoutes];

        return (
          <Layout
            topNavRoutes={topNavRoutes}
            sideNavRoutes={sideNavRoutes}
            appRoutes={appRoutes}
            logo={page.mainLogo}
            authenticated={isAuthenticated}
            loginBackground={page.loginBackground}
            privacyPolicyLink={privacyPolicyLink}
        />
        );
      }}
    />
  );
};

AppRouterItems.propTypes = {
  layout: PropTypes.object.isRequired,
};

export default AppRouterItems;
