import gql from "graphql-tag";

export default gql`
  fragment ProgramFields on Program {
    id
    bannerImage {
      ...ImageFields
    }
    bannerTitle
    promoCardImage {
      ...ImageFields
    }
    promoCardTitle
    promoCardContent
    promoCardButton {
      ...ButtonFields
    }
    programPage {
      ...LinkFields
    }
  }
`;
