export const VIDEO_MODULE = "Video";

const flatMap = (arr, fn) => {
  return arr.reduce((acc, value) => {
    return acc.concat(fn(value));
  }, []);
};

const flattenModules = (lesson) => {
  return flatMap(lesson.modules, (module) => {
    if (module.__typename === VIDEO_MODULE) {
      return [
        {
          ...module,
          lessonId: lesson.id,
          moduleId: module.id,
        },
      ];
    }

    return module.questions.map((question, index, arr) => {
      return {
        question: {
          ...question,
          questionNumber: index + 1,
          numberOfQuestions: arr.length,
        },
        __typename: module.__typename,
        moduleId: module.id,
        lessonId: lesson.id,
        questionId: question.id,
      };
    });
  });
};

export const flattenCourse = (course) => {
  return flatMap(course.lessons, flattenModules);
};
