import React from "react";
import PropTypes from "prop-types";

// Bootstrap components
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";

// Sass Imports
import "./NavLink.scss";
import DropdownItem from "react-bootstrap/DropdownItem";

/**
 * builds a nav link. Handles internal and external links
 * @param {String} path
 * @param {Boolean} externalLink
 * @param {Boolean} isDropdown
 * @param {Object} children
 *
 * @returns {String} - NavLink HTML
 */
function _buildNavLink(path, externalLink, isDropdown, children) {
  if (externalLink) {
    return <ExternalNavLink path={path} isDropdown={isDropdown}>{children}</ExternalNavLink>;
  }
  return <InternalNavLink path={path} isDropdown={isDropdown}>{children}</InternalNavLink>;
}

function NavLink(props) {
  const { path, externalLink, isDropdown, children } = props;
  const navLink = _buildNavLink(path, externalLink, isDropdown, children);
  return navLink;
}

function ExternalNavLink(props) {
  const { path, isDropdown, children } = props;
  const linkProps = {
    href: path,
    className: "we nav-link",
  };
  const LinkComponent = isDropdown ? DropdownItem : "a";

  return <LinkComponent {...linkProps}>{children}</LinkComponent>;
}

function InternalNavLink(props) {
  const { path, isDropdown, children } = props;
  const linkProps = {
    href: path,
    className: "we nav-link",
  };
  const LinkComponent = isDropdown ? DropdownItem : Nav.Link;

  return (
    <LinkContainer className="we" to={path} exact>
      <LinkComponent {...linkProps}>{children}</LinkComponent>
    </LinkContainer>
  );
}

NavLink._buildNavLink = _buildNavLink;

NavLink.propTypes = {
  path: PropTypes.string.isRequired,
  externalLink: PropTypes.bool,
};
NavLink.defaultProps = {
  externalLink: false,
  isDropdown: false,
};

export default NavLink;
