import gql from "graphql-tag";

export default gql`
  fragment FormMetaDataFields on FormMetaDataFields {
    userIdFieldId
    emailFieldId
    firstNameFieldId
    lastNameFieldId
    countryFieldId
    stateFieldId
    zipFieldId
  }
`;
