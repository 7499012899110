import React from "react";
import PropTypes from "prop-types";

// App Components
import Modal from "components/molecules/Modal";
import VideoPlayer from "components/organisms/VideoPlayer";

// Style sheet
import "./VideoModal.scss";

const VideoModal = ({ videoSrc, isOpen, onClose }) => {
  const player = isOpen && videoSrc
    ? <VideoPlayer className="we VideoModalPlayer" src={videoSrc} />
    : null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {player}
    </Modal>
  );
};


VideoModal.propTypes = {
  /**
    * Video source url
    */
  videoSrc: PropTypes.string,

  /**
    * Whether or not video modal is open
    */
  isOpen: PropTypes.bool.isRequired,

  /**
    * Event listener fired when modal close button is clicked
    */
  onClose: PropTypes.func.isRequired,
};

export default VideoModal;
