import React from "react";
import PropTypes from "prop-types";

// Node Modules
import { withRouter } from "react-router-dom";
import BootstrapForm from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

// App Components
import SearchButton from "modules/search/SearchButton";
import Input from "components/molecules/Input";

import SearchIconWhite from "res/icons/search-white-24-px.svg";

import { handleSearchFormSubmit } from "./SearchForm.helpers";

// Style sheet
import "./SearchForm.scss";

/**
*
* @function SearchForm
* Renders the form for nav bar search
*
*/
export const SearchForm = ({ history, searchInputRef }) => {
  const { t } = useTranslation();
  // handle form submission
  const onSubmit = (event) => {
    handleSearchFormSubmit(event, searchInputRef.current.value, history);
  };
  return (
    <BootstrapForm
      action="."
      className="we SearchForm"
      onSubmit={onSubmit}
    >
      <Input ref={searchInputRef} type="search" placeholder={t("search.search_form.placeholder")} />
      <SearchButton
        variant="primary"
        icon={SearchIconWhite}
        disabled={false}
        type="submit"

      />
    </BootstrapForm>
  );
};


SearchForm.propTypes = {

  /**
  * React Router history object
  */
  history: PropTypes.object.isRequired,

  /**
  * Reference used by Search widget to set focus
  */
  searchInputRef: PropTypes.object.isRequired,
};

export default withRouter(SearchForm);
