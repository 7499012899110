import React from "react";
import PropTypes from "prop-types";

// Bootstrap components
import Nav from "react-bootstrap/Nav";

// Utils
import { composeClassName } from "utils";

// App components
import NavIcon from "../../atoms/NavIcon";
import NavLink from "../NavLink";

// Sass Imports
import "./NavItem.scss";

/**
 * builds a nav icon, returns empty string if no icon passed in
 * @param {String} icon
 *
 * @returns {String} - Icon HTML
 */
function _getIconHtml(icon, label) {
  if (icon) {
    return (<NavIcon label={label} icon={icon} />);
  }
  return "";
}

function NavItem(props) {
  const { path, label, icon, externalLink, navPosition, isDropdown } = props;
  const iconHtml = _getIconHtml(icon, label);
  const composedClass = composeClassName("we", navPosition);
  return (
    <Nav.Item className={composedClass} key={path}>
      <NavLink path={path} externalLink={externalLink} isDropdown={isDropdown}>
        {iconHtml}
        <span className="nav-label">{label}</span>
      </NavLink>
    </Nav.Item>
  );
}

NavItem._getIconHtml = _getIconHtml;

NavItem.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default NavItem;
