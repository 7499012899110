import gql from "graphql-tag";

export default gql`
  fragment FormFieldFields on FormField {
    id
    type
    name
    label
    hidden
    required
    default
    otherOption
    options {
      label
      value
    }
    text
    description
    placeholder
    maxLength
    default
    rows
    translationKey
  }
`;
