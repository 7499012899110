import React from "react";
import PropTypes from "prop-types";

// Utils
import { composeClassName } from "utils";

// Style sheet
import "./FiltersBlock.scss";

// Bootstrap Components
import Row from "react-bootstrap/Row";

// App Components
import Block from "components/molecules/Block";
import Heading from "components/atoms/Heading";
import ExpandableList from "components/organisms/ExpandableList";
import ExpandableListButton from "components/molecules/ExpandableListButton";

// Filter Tile Component
import FilterTile from "./FilterTile";

/**
 * Adapter component for Filters Expandable List
 * @param {Array} filters
 */
const ListAdapter = ({ items, renderCount }) => {
  const displayItems = items.slice(0, renderCount).map((item) => {
    return (<FilterTile key={item.id} filter={item} />);
  });
  return (<Row>{ displayItems }</Row>);
};

/**
*
* @function FiltersBlock
* Filters Block (https://zpl.io/a7QoBe8)
*
* @param {Object} block - Block properties from SB
*
*/
const FiltersBlock = ({ className, block }) => {
  // Combines all class into a single string
  const composedClass = composeClassName("we FiltersBlock", className);

  const { title, filters } = block;

  return (
    <Block className={composedClass}>
      <Heading variant={Heading.variants.block}>
        {title}
      </Heading>
      <ExpandableList
        items={filters}
        adapter={ListAdapter}
        displayCount={3}
        expandButton={ExpandableListButton}
      />
    </Block>
  );
};

FiltersBlock.propTypes = {
  /**
   * Story block data model
   */
  block: PropTypes.object.isRequired,
};

export default FiltersBlock;
