import React from "react";
import PropTypes from "prop-types";

// App Components
import Modal from "components/molecules/Modal";

import FormBlockSubmitted from "../FormBlockSubmitted";
import FormBlockForm from "../FormBlockForm";

import "./FormBlockModal.scss";

const _getForm = (isOpen, formBlock, handleFormSubmitted) => {
  return (
    <FormBlockForm
      isOpen={isOpen}
      formBlock={formBlock}
      handleFormSubmitted={handleFormSubmitted}
    />
  );
};

const _getSubmittedView = (formBlock, onClose) => {
  return (
    <FormBlockSubmitted
      formBlock={formBlock}
      onClose={onClose}
    />
  );
};

const _getView = ({ isOpen, submitted, handleFormSubmitted, formBlock, onClose }) => {
  if (!isOpen) return null;

  return submitted
    ? _getSubmittedView(formBlock, onClose)
    : _getForm(isOpen, formBlock, handleFormSubmitted);
};

const _getHandleFormSubmitted = (formBlock, onSubmit) => {
  return () => {
    onSubmit(formBlock.formId);
  };
};

const FormBlockModal = ({
  formBlock,
  isOpen,
  onClose,
  onSubmit,
  submitted,
}) => {
  const handleFormSubmitted = _getHandleFormSubmitted(formBlock, onSubmit);
  const view = _getView({
    isOpen,
    submitted,
    handleFormSubmitted,
    formBlock,
    onClose,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideCloseButton>
      <div className="we FormBlockModal">
        <button
          type="button"
          className="close-button"
          onClick={onClose}
          />
        <div className="form-modal-body">{view}</div>
      </div>
    </Modal>
  );
};

FormBlockModal._getView = _getView;

FormBlockModal.propTypes = {
  /**
    * FormBlock data from storyblok
    */
  formBlock: PropTypes.object,

  /**
    * Whether or not FormBlock modal is open
    */
  isOpen: PropTypes.bool.isRequired,

  /**
    * Event listener fired when modal close button is clicked
    */
  onClose: PropTypes.func.isRequired,
};

export default FormBlockModal;
