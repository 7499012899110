import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Heading from "components/atoms/Heading/Heading";
import Image from "components/atoms/Image/Image";
import Text from "components/atoms/Text";
import Button from "components/atoms/Button";
import Block from "components/molecules/Block/Block";
import Submitted from "./res/Submitted.svg";
import "./FormSubmittedBlock.scss";
import { getPrefill } from "../../api";


const FormSubmittedBlock = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const heading = t("form_submitted.header");
  const description = t("form_submitted.description");
  const ctaHeading = t("form_submitted.cta_heading");
  const ctaDescription = t("form_submitted.cta_description");
  const ctaButton = t("form_submitted.cta_button");

  const handleJoinNow = async () => {
    let prefill;
    if (search) {
      const params = new URLSearchParams(search);
      const submissionId = params.get("submissionId");
      prefill = await getPrefill(submissionId);
    }
    history.replace(`/account/signUp${search}`, prefill);
  };

  return (
    <>
      <Block className="we form-submitted-block">
        <Image src={Submitted} />
        <Heading>{heading}</Heading>
        <Text>{description}</Text>
      </Block>
      <Block className="we form-cta-block" title={ctaHeading}>
        <Text>{ctaDescription}</Text>
        <Button onClick={handleJoinNow}>{ctaButton}</Button>
      </Block>
    </>
  );
};

export default FormSubmittedBlock;
