import gql from "graphql-tag";
import { graphql } from "react-apollo";
import PropTypes from "prop-types";
import QueryResults from "./QueryResults";
import fragments from "../fragments";

export const SEARCH_PAGE_QUERY = gql`
  query searchPage($searchTerm: String!) {
    page:searchPage(searchTerm:$searchTerm) {
        ...PageFields
    }
  }
  ${fragments}
`;

// SEARCH_PAGE_QUERY requires a $searchTerm, $filterValue variables.
// These variables are autoset by graphql() if a matching prop is found.
const SearchPageQuery = graphql(SEARCH_PAGE_QUERY)(QueryResults);

SearchPageQuery.propTypes = {
  onLoading: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onData: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

SearchPageQuery.defaultProps = {
};

export default SearchPageQuery;
