import gql from "graphql-tag";

export default gql`
  fragment BlockFields on Block {
    id
    type
    ... on ClassroomResourceListBlock {
      ...ClassroomResourceListBlockFields
    }
    ... on MonthlyFeatureBlock {
      ...MonthlyFeatureBlockFields
    }
    ... on PromotedCampaignBlock {
      ...PromotedCampaignBlockFields
    }
    ... on PromotedProgramsBlock {
      ...PromotedProgramsBlockFields
    }
    ... on SocialMediaBlock {
      ...SocialMediaBlockFields
    }
    ... on ImageBlock {
      ...ImageBlockFields
    }
    ... on ContentBlurbBlock {
      ...ContentBlurbBlockFields
    }
    ... on FeaturedCollectionsBlock {
      ...FeaturedCollectionsBlockFields
    }
    ... on FeaturedCollectionBlock {
      ...FeaturedCollectionBlockFields
    }
    ... on FiltersBlock {
      ...FiltersBlockFields
    }
    ... on NewestResourcesListBlock {
      ...NewestResourcesListBlockFields
    }
    ... on LiveWebinarBlock {
      ...LiveWebinarBlockFields
    }
    ... on RecordedCPDListBlock {
      ...RecordedCPDListBlockFields
    }
    ... on UpcomingWebinarListBlock {
      ...UpcomingWebinarListBlockFields
    }
    ... on FormBlock {
      ...FormBlockFields
    }
  }
`;
