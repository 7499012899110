import React from "react";
import {
  UNAUTHORIZED_ERROR_CODE,
  INCOMPLETE_SUBSCRIPTION_ERROR_CODE,
} from "lib/constants";
import { Logout } from "modules/auth";
import LocationCaptureBlock from "modules/location";

const _errorHandlerComponents = {
  [UNAUTHORIZED_ERROR_CODE]: Logout,
  [INCOMPLETE_SUBSCRIPTION_ERROR_CODE]: LocationCaptureBlock,
};

const ErrorHandler = ({ error }) => {
  const { networkError } = error;
  if (networkError && networkError.result && networkError.result.errors) {
    // Network error containing status code
    const networkRequestError = networkError.result.errors.find(
      ({ statusCode }) => {
        return !!statusCode;
      },
    );

    if (
      networkRequestError &&
      _errorHandlerComponents[networkRequestError.statusCode]
    ) {
      const ErrorHandlerComponent =
        _errorHandlerComponents[networkRequestError.statusCode];
      return <ErrorHandlerComponent />;
    }
  }

  throw error;
};

export default ErrorHandler;
