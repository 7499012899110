import {
  MonthlyFeatureBlock,
  PromotedProgramsBlock,
  SocialMediaBlock,
  ClassroomResourceListBlock,
  ContentBlurbBlock,
  ImageBlock,
  FeaturedCollectionsBlock,
  FeaturedCollectionBlock,
  FiltersBlock,
  NewestResourcesListBlock,
  LiveWebinarBlock,
  RecordedCPDListBlock,
  UpcomingWebinarListBlock,
  FormBlock,
} from "modules/page/blocks";

const BlockItems = {
  MonthlyFeatureBlock,
  PromotedProgramsBlock,
  SocialMediaBlock,
  ClassroomResourceListBlock,
  ContentBlurbBlock,
  ImageBlock,
  FeaturedCollectionsBlock,
  FeaturedCollectionBlock,
  FiltersBlock,
  NewestResourcesListBlock,
  LiveWebinarBlock,
  RecordedCPDListBlock,
  UpcomingWebinarListBlock,
  FormBlock,
};

export default BlockItems;
