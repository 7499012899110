import React from "react";
import { useTranslation } from "react-i18next";
import Input from "components/molecules/Input/Input";
import Heading from "components/atoms/Heading/Heading";
import Text from "components/atoms/Text/Text";
import Button from "components/atoms/Button/Button";
import Block from "components/molecules/Block/Block";
import "./ForgotPasswordBlock.scss";
import ArrowBackIcon from "components/atoms/ArrowBackIcon/ArrowBackIcon";
import AnswerExplanation from "modules/course/atoms/AnswerExplanation";

const ForgotPasswordBlock = ({
  handleSendLink,
  handleEmail,
  email,
  formInvalid,
  error,
  handleBack,
}) => {
  const { t } = useTranslation();

  let displayError;
  let buttonText = t("forgot_password.link_button");
  if (error) {
    buttonText = t("forgot_password.try_again_button");
    const errorProps = {
      errorMessage: t("forgot_password.invalid_email_error"),
    };
    displayError = (
      <AnswerExplanation {...errorProps} />
    );
  }

  return (
    <Block className="we ForgotPasswordBlock">
      <ArrowBackIcon alt="alt" />
      <Button
        variant="text"
        onClick={handleBack}>
        { t("forgot_password.back_button") }
      </Button>
      <Heading>
        { t("forgot_password.title") }
      </Heading>
      <Text>
        { t("forgot_password.description") }
      </Text>
      {displayError}
      <Text variant="overline" className="label">
        { t("forgot_password.label") }
      </Text>
      <Input
        className="input"
        type="default"
        value={email}
        onChange={handleEmail} />
      <Button
        className="button"
        onClick={handleSendLink}
        disabled={formInvalid}>
        {buttonText}
      </Button>
    </Block>
  );
};

export default ForgotPasswordBlock;
