import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import VideoPlayer from "components/organisms/VideoPlayer";
import Button from "components/atoms/Button";
import "./VideoCoursePlayer.scss";

const VideoCoursePlayer = ({ url, onNext, showPrevButton, onPrev }) => {
  const { t } = useTranslation();

  const buttons = (
    <>
      {showPrevButton ? (
        <Button className="we Prev_Button" variant="secondary" onClick={onPrev}>
          {t("course.prev_button")}
        </Button>
      ) : <div />}
      <Button className="we Next_Button" onClick={onNext}>
        {t("course.next_button")}
      </Button>
    </>
  );

  return (
    <div className="we VideoCoursePlayer">
      <VideoPlayer src={url} autoplay={false} />
      <div className="we Row">
        {buttons}
      </div>
    </div>
  );
};

VideoCoursePlayer.propType = {
  url: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default VideoCoursePlayer;
