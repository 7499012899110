import React from "react";
import { useTranslation } from "react-i18next";
import Heading from "components/atoms/Heading/Heading";
import Text from "components/atoms/Text/Text";
import Button from "components/atoms/Button/Button";
import Block from "components/molecules/Block/Block";
import { useHistory } from "react-router-dom";
import passwordResetIcon from "../../../../res/icons/password-reset.svg";
import "./ResetSuccessful.scss";

const ResetSuccessfulBlock = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleSignIn = () => {
    history.push("/account/signIn");
  };

  return (
    <Block className="we ResetSuccessfulBlock">
      <img className="icon" src={passwordResetIcon} alt="" />
      <Heading {...Heading.variants.page}>
        { t("password_successful.title") }
      </Heading>
      <Text>
        { t("password_successful.description") }
      </Text>
      <Button
        className="button"
        onClick={handleSignIn}>
        { t("password_successful.sign_in_button") }
      </Button>

    </Block>
  );
};

export default ResetSuccessfulBlock;
