import gql from "graphql-tag";

export default gql`
  fragment ButtonFields on Button {
    text
    link {
      ...LinkFields
    }
    enabled
  }
`;
