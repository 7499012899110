export const isObject = (value) => {
  return typeof value === "object" && !Array.isArray(value) && value !== null;
};

export const isEmpty = (value) => {
  return (
    value === undefined ||
    value == null ||
    (isObject(value) && Object.keys(value).length === 0) ||
    value.length === 0
  );
};

export const isEmail = (value) => {
  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexEmail.test(value);
};

export const isEmptyString = (value) => {
  return value ? value.trim().length === 0 : true;
};

export const isPassword = (value) => {
  const regexPassword = /[^\s]{8,}/;
  return regexPassword.test(value);
};
