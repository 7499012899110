import React from "react";
import PropTypes from "prop-types";

// Node Module Components
import Container from "react-bootstrap/Container";

// App Modules
import Heading from "components/atoms/Heading";

// Utils
import { composeClassName } from "utils";

// Style sheet
import "./Block.scss";


/**
*
* @function _buildTitle
* Builds the title html for a block
* @param {String} title
* @returns {String | Function} - Returns heading component or empty string
* if the title is not set.
*
*/
const _buildTitle = (title) => {
  if (title) {
    return <Heading variant={Heading.variants.block}>{title}</Heading>;
  }
  return null;
};


/**
*
* @function Block
* A wrapper for all cards used in the feed.
* @param {Function} children
* @param {String} className
* @param {String} [title]
*
*/
const Block = ({ children, className, title }) => {
  // Combines all classes into a single string
  const composedClass = composeClassName("we Block", className);
  const titleHtml = _buildTitle(title);
  return (
    <div className={composedClass}>
      {titleHtml}
      <Container fluid>
        {children}
      </Container>
    </div>
  );
};


Block._buildTitle = _buildTitle;

Block.propTypes = {

  /**
    * Optional title that appears on top of the block outside of the main content
    */
  title: PropTypes.string,

};

export default Block;
