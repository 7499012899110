import gql from "graphql-tag";

export default gql`
  fragment RatingFormMetaDataFields on FormMetaDataFields {
    userIdFieldId
    emailFieldId
    firstNameFieldId
    lastNameFieldId
    countryFieldId
    stateFieldId
    zipFieldId
    resourceNameFieldId
    ratingFieldId
  }
`;
