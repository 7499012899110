import gql from "graphql-tag";

export default gql`
  fragment SearchPageFields on SearchPage {
    id
    type
    searchTerm
    feed {
      ...BlockFields
    }
  }
`;
