import React from "react";
import PropTypes from "prop-types";

// Contants
import { THEMES } from "lib/constants";

// Style sheet
import "./ArrowBackIcon.scss";

// Icons
import BlueArrowBackIcon from "res/icons/keyboard-backspace-24-px-2.svg";


const _getIcon = (theme) => {
  return (theme === THEMES.light ? BlueArrowBackIcon : null);
};

const ArrowBackIcon = ({ alt, theme }) => {
  const Icon = _getIcon(theme);

  return (
    <img
      src={Icon}
      className="we arrow-back-icon"
      alt={alt}
    />
  );
};

ArrowBackIcon.propTypes = {
  alt: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.keys(THEMES)),
};

ArrowBackIcon.defaultProps = {
  theme: THEMES.light,
};

export default ArrowBackIcon;
