import React from "react";
import "./SignInBlock.scss";
import Block from "components/molecules/Block/Block";
import Input from "components/molecules/Input/Input";
import Text from "components/atoms/Text";
import Button from "components/atoms/Button";
import SocialButton from "components/atoms/Button/SocialButton";
import Spinner from "components/atoms/Spinner";
import AnswerExplanation from "modules/course/atoms/AnswerExplanation/AnswerExplanation";
import { getSsoLink } from "../../api";

const SignInBlock = (props) => {
  const {
    heading,
    description,
    emailTextField,
    passwordTextField,
    forgotPassword,
    signInButton,
    callToActionHeading,
    createAccountButton,
    loading,
    error,
  } = props;

  let display = (
    <div>
      <Text>{description}</Text>
    </div>
  );

  if (error) {
    const errorProps = {
      errorMessage: error,
    };
    display = (
      <AnswerExplanation {...errorProps} />
    );
  }

  if (loading) {
    return <Spinner />;
  }

  const handleSsoClick = (ssoLink) => {
    return () => {
      window.location.replace(ssoLink);
    };
  };

  const facebookClickHandler = handleSsoClick(getSsoLink("facebook", "/"));
  const googleClickHandler = handleSsoClick(getSsoLink("google", "/"));
  // const appleClickHandler = handleSsoClick(getSsoLink("apple", "/"));

  return (
    <>
      <Block className="we sign-in-block" title={heading.value}>
        {display}
        <div className="form">
          <Input {...emailTextField} />
          <Input {...passwordTextField} />
        </div>
        <Button className="forgot-button" onClick={forgotPassword.onClick} variant="text">{forgotPassword.value}</Button>
        <div className="button-group">
          <Button onClick={signInButton.onClick}>{signInButton.value}</Button>
          <Text>OR</Text>
          <SocialButton onClick={facebookClickHandler} variant="facebook" />
          <SocialButton onClick={googleClickHandler} variant="google" />
          {/* <SocialButton onClick={appleClickHandler} variant="apple" /> */}
        </div>
      </Block>
      <Block className="we cta-block" title={callToActionHeading}>
        <Button onClick={createAccountButton.onClick}>{createAccountButton.value}</Button>
      </Block>
    </>
  );
};

export default SignInBlock;
