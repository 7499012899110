import {
  Feed,
  Program,
  Campaign,
  Collection,
  Filter,
  Search,
} from "modules/page/templates";

export default {
  FeedPage: Feed,
  FilterPage: Filter,
  SearchPage: Search,
  ProgramPage: Program,
  CampaignPage: Campaign,
  CollectionPage: Collection,
};
