import gql from "graphql-tag";

export default gql`
  fragment CollectionFields on Collection {
    id
    bannerImage {
      ...ImageFields
    }
    bannerTitle
    promoCardImage {
      ...ImageFields
    }
    promoCardTitle
    promoCardContent
    promoCardButton {
      ...ButtonFields
    }
    partOfPrograms
    partOfCampaigns
    collectionPage {
      ...LinkFields
    }
    theme
  }
`;
