import gql from "graphql-tag";

export default gql`
  fragment CampaignPageFields on CampaignPage {
    id
    type
    campaign {
      ...CampaignFields
    }
    feed {
      ...BlockFields
    }
  }
`;
