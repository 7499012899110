import React from "react";
import PropTypes from "prop-types";

import Image from "components/atoms/Image";
import Heading from "components/atoms/Heading";
import Text from "components/atoms/Text";
import Button from "components/atoms/Button";

import "./FormBlockSubmitted.scss";

function FormBlockSubmitted({ formBlock, onClose }) {
  const {
    formSubmittedModalImage,
    formSubmittedHeader,
    formSubmittedBodyText,
    formDismissButtonText,
  } = formBlock;
  return (
    <div className="we FormBlockSubmitted">
      <Image src={formSubmittedModalImage} className="submitted-image" />
      <Heading variant={Heading.variants.card} className="header">
        {formSubmittedHeader}
      </Heading>
      <Text className="body-text">
        {formSubmittedBodyText}
      </Text>
      <Button onClick={onClose} className="dismiss-button">
        {formDismissButtonText}
      </Button>
    </div>
  );
}

FormBlockSubmitted.propTypes = {
  /**
    * FormBlock data from storyblok
    */
  formBlock: PropTypes.object.isRequired,

  /**
    * Event listener fired when modal close button is clicked
    */
  onClose: PropTypes.func.isRequired,
};

export default FormBlockSubmitted;
