import React from "react";
import PropTypes from "prop-types";

// Utils
import { composeClassName } from "utils";

// Contants
import { THEMES } from "lib/constants";

// Style sheet
import "./Heading.scss";

/**
   *
   * Various types of Headings
   * @typedef {Object} Variant
   * @property {String} element - HTML Element
   * @property {String} className - Element className
   *
   */
/**
   *
   * Various types of Headings
   * @typedef {Object} Variants
   * @property {Variant} page - Used for Page Level Titles like Banners
   * @property {Variant} block - Used for Titles that appear on Blocks
   * @property {Variant} card - Lower pritory Titles at the card level
   *
   */
const variants = {
  page: {
    element: "h1",
    className: "page",
  },
  block: {
    element: "h2",
    className: "block",
  },
  card: {
    element: "h3",
    className: "card",
  },

};

/**
*
* @function Heading
* Used through the app for headings of sections
*
*/
const Heading = ({ children, className, theme, variant }) => {
  // Combines all class into a single string
  const composedClass = composeClassName("we Heading", className, theme, variant.className);
  const Element = variant.element;
  return (
    <Element className={composedClass}>
      {children}
    </Element>
  );
};

Heading.variants = variants;

Heading.propTypes = {


  /**
    *
    * Refers to the backdrop of the parent component.
    * Used to define color variations that aestically optimize
    * the Heading component's presention within the bounded
    * context of its parent.
    *
    */
  theme: PropTypes.oneOf(Object.keys(THEMES)),

  /**
    * The various available variations of Headings
    * Can be access from Heading.variants object
    */
  variant: PropTypes.object,
};

Heading.defaultProps = {
  variant: Heading.variants.page,
  theme: THEMES.light,
};

export default Heading;
