import React from "react";
import PropTypes from "prop-types";

// App Components
import Button from "components/atoms/Button";
import Card from "components/molecules/Card";
import Image from "components/atoms/Image";
import Tag from "components/atoms/Tag";
import Heading from "components/atoms/Heading";

// TODO: add onClick listener
// Returns onClick event listener for each webinar tile
const _openVideoModal = () => {
  return () => {};
};

/**
 * @function VideoTile
 *
 * @param {Object} video - Resource block properties from SB
 */
const WebinarTile = ({ webinar }) => {
  const {
    thumbnailImage,
    date,
    title,
  } = webinar;

  return (
    <Button
      className="we WebinarTile"
      variant="text"
      onClick={_openVideoModal}>
      <Card key={title}>
        <Card.Content className="tile-content">
          <Image src={thumbnailImage}>
            <Tag theme="light" variant="contained" size="big">
              {date}
            </Tag>
          </Image>
          <div className="webinar-title">
            <Heading variant={Heading.variants.card}>
              {title}
            </Heading>
          </div>
        </Card.Content>
      </Card>
    </Button>
  );
};

WebinarTile.propTypes = {
  /**
    * Storblock datamodel
    */
  webinar: PropTypes.object.isRequired,
};

export default WebinarTile;
