import gql from "graphql-tag";

export default gql`
  fragment ContentBlurbBlockFields on ContentBlurbBlock {
    id
    type
    title
    bodyText
    logoImage {
      desktop
      mobile
    }
    ctaButton {
      ...ButtonFields
    }
  }
`;
