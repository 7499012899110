import gql from "graphql-tag";

export default gql`
  fragment ImageBlockFields on ImageBlock {
    id
    type
    image {
      ...ImageFields
    }
  }
`;
