import React, { useState, createContext } from "react";

// Video Modal
import VideoModal from "./VideoModal";

const VideoModalContext = createContext();

const _getModalOnClose = (setModalOpen, setModalVideoSrc) => {
  return () => {
    setModalOpen(false);
    setModalVideoSrc(null);
  };
};

const _getOpenVideoModal = (setModalOpen, setModalVideoSrc) => {
  return (src) => {
    setModalVideoSrc(src);
    setModalOpen(true);
  };
};

export const VideoModalProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalVideoSrc, setModalVideoSrc] = useState(null);
  const _modalOnClose = _getModalOnClose(setModalOpen, setModalVideoSrc);
  const _openVideoModal = _getOpenVideoModal(setModalOpen, setModalVideoSrc);

  return (
    <VideoModalContext.Provider value={{ openVideoModal: _openVideoModal }}>
      <VideoModal
        videoSrc={modalVideoSrc}
        isOpen={modalOpen}
        onClose={_modalOnClose}
      />
      {children}
    </VideoModalContext.Provider>
  );
};

export default VideoModalContext;
