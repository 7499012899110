import React from "react";
import PropTypes from "prop-types";

// Contants
import { THEMES } from "lib/constants";

// Style sheet
import "./ArrowRightIcon.scss";

// Icons
import BlueArrowRightIcon from "res/icons/arrow-right-blue.svg";
import WhiteArrowRightIcon from "res/icons/arrow-right-white.svg";


const _getIcon = (theme) => {
  return (theme === THEMES.dark ? WhiteArrowRightIcon : BlueArrowRightIcon);
};

const ArrowRightIcon = ({ alt, theme }) => {
  const Icon = _getIcon(theme);

  return (
    <img
      src={Icon}
      className="we arrow-right-icon"
      alt={alt}
    />
  );
};

ArrowRightIcon.propTypes = {
  alt: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.keys(THEMES)),
};

ArrowRightIcon.defaultProps = {
  theme: THEMES.light,
};

export default ArrowRightIcon;
