import React from "react";
import PropTypes from "prop-types";

// Bootstrap Components
import Col from "react-bootstrap/Col";

// App Components
import Image from "components/atoms/Image/";
import Heading from "components/atoms/Heading";
import Button from "components/atoms/Button";

/**
 * Helper for rendering Filter Tile Body.
 * if image object is passed in, then render an <Image>
 * otherwise just render a <div>
 *
 * @param {Object} image
 * @param {any} tileContent
 * @returns
 */
const _getShowTileBody = (image, tileContent) => {
  if (image) {
    return (
      <Image
        className="tile-body"
        src={image}>
        {tileContent}
      </Image>
    );
  }

  return (
    <div className="tile-body">
      {tileContent}
    </div>
  );
};
/**
*
* @function FiltersBlock
* Component for individual list item in Filters Expandable List
*
* @param {Object} filter - Filter Block properties from SB
*
*/
const FilterTile = ({ filter }) => {
  const {
    title,
    image,
    filterLink,
    theme,
  } = filter;

  const tileContent = (
    <div className="tile-content">
      <Heading
        className="tile-title"
        theme={theme}
        variant={Heading.variants.card}>
        {title}
      </Heading>
    </div>
  );

  const tileBody = _getShowTileBody(image, tileContent);

  return (
    <Col className="FilterTile" md={4}>
      <Button
        href={filterLink}
        className="tile-cta"
        variant={Button.variants.text}>
        {tileBody}
      </Button>
    </Col>
  );
};

FilterTile.propTypes = {
  /**
   * Filter data to be rendered
   */
  filter: PropTypes.object.isRequired,
};

export default FilterTile;
