import React from "react";
import PropTypes from "prop-types";

// Utils
import { composeClassName } from "utils";

// Contants
import { THEMES } from "lib/constants";

// Style sheet
import "./Tag.scss";

const variants = {
  contained: "contained",
  outline: "outline",
};

const sizes = {
  small: "small",
  big: "big",
};

/**
* @function Tag
* Renders tags that are used throughout that app
* https://zpl.io/VYXMPjm
* @param {Object} children
* @param {String} theme
* @param {String} variant
* @param {String} size
 */
const Tag = ({ children, theme, variant, size }) => {
  // Combines all class into a single string
  const composedClass = composeClassName("we Tag", theme, variant, size);
  return (
    <div className={composedClass}>
      {children}
    </div>
  );
};

Tag.variants = variants;
Tag.sizes = sizes;

Tag.propTypes = {
  /**
    *
    * Refers to the backdrop of the parent component.
    * Used to define color variations that aestically optimize
    * the Tag component's presention within the bounded
    * context of its parent.
    *
    */
  theme: PropTypes.oneOf(Object.keys(THEMES)),

  /**
  * The various available variations of Tags
  */
  variant: PropTypes.oneOf(Object.keys(variants)),

  /**
  * The various available sizes of Tags
  */
  size: PropTypes.oneOf(Object.keys(sizes)),
};

Tag.defaultProps = {
  variant: Tag.variants.contained,
  size: Tag.sizes.small,
  theme: THEMES.light,
};

export default Tag;
