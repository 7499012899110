import gql from "graphql-tag";

export default gql`
  fragment MenuItemFields on MenuItem {
    id
    icon {
      ...ImageFields
    }
    label
    destination {
        ...LinkFields
    }
    bottomAlign
  }
`;
