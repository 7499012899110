import gql from "graphql-tag";

export default gql`
  fragment FeedPageFields on FeedPage {
    id
    type
    feed {
      ...BlockFields
    }
  }
`;
