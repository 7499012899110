import React from "react";
import PropTypes from "prop-types";

// Node Modules
import { useTranslation } from "react-i18next";

// App Components
import Button from "components/atoms/Button/";

// Utils
import { composeClassName } from "utils";

// Style sheet
import "./SearchButton.scss";

/**
*
* @function SearchButton
* Renders Button that is styled to be used in Search Widget
*
*/
const SearchButton = ({ className, icon, type, variant, disabled, onClick }) => {
  const composedClassName = composeClassName("we SearchButton", className);
  const { t } = useTranslation();
  return (
    <Button
      className={composedClassName}
      type={type}
      variant={variant}
      disabled={disabled}
      onClick={onClick}>
      <img className="icon" alt={t("search.search_button.icon.alt")} src={icon} />
    </Button>

  );
};

SearchButton.propTypes = {

  /**
    * css Class selector to be applied to the button
    */
  className: PropTypes.string,

  /**
    * Icon to be rendered in button
    */
  icon: PropTypes.string.isRequired,

  /**
  * Button Type
  */
  type: PropTypes.string,

  /**
    * The variant changes border, background and font color based on theme.
    */
  variant: PropTypes.string,

  /**
  * Renders the button inactive
  */
  disabled: PropTypes.bool,

  /**
   * Handler function for onClick event
   */
  onClick: PropTypes.func,

};

export default SearchButton;
