import gql from "graphql-tag";

export default gql`
  mutation UpdateFormState(
    $formId: String!
    $formAction: String!
    $metaFields: MetaFields!
    $formData: String
  ) {
    updateFormState(
      input: {
        formId: $formId
        formAction: $formAction
        metaFields: $metaFields
        formData: $formData
      }
    )
  }
`;
