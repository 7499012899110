import React from "react";
import PropTypes from "prop-types";

// Utils
import { composeClassName } from "utils";

// Contants
import { THEMES } from "lib/constants";

// Style sheet
import "./Text.scss";


/**
   *
   * Various types
   * @typedef {Object} Variant
   * @property {[String]} element - Optional HTML Element
   * @property {String} className - Element className
   *
   */
/**
   *
   * Various types of Texts
   * @typedef {Object} Variants
   * @property {String} body - Used for body text throughout the app
   *
   *
   */
const variants = {
  body: {
    className: "body",
    element: "p",
  },
  overline: {
    className: "overline",
    element: "p",
  },
};


/**
*
* @function Text
* Renders text used throughout that app
* @param {Object} children
* @param {String} className
* @param {String} theme
* @param {String} variant
* @param {String} props
*
*/
const Text = ({ children, className, theme, variant }) => {
  const variation = variants[variant];
  // Combines all class into a single string
  const composedClass = composeClassName("we Text", className, theme, variation.className);
  const Element = variation.element;
  return (
    <Element className={composedClass}>
      {children}
    </Element>
  );
};

Text.variants = variants;

Text.propTypes = {

  /**
    *
    * Refers to the backdrop of the parent component.
    * Used to define color variations that aestically optimize
    * the Text component's presention within the bounded
    * context of its parent.
    *
    */
  theme: PropTypes.oneOf(Object.keys(THEMES)),

  /**
    * The various available variations of Texts
    */
  variant: PropTypes.oneOf(Object.keys(variants)),
};

Text.defaultProps = {
  theme: THEMES.light,
  variant: "body",
};

export default Text;
