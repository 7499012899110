import gql from "graphql-tag";

export default gql`
  fragment FormBlockFields on FormBlock {
    id
    type
    completed
    formId
    blockImage {
      ...ImageFields
    }
    title
    blockBodyText: bodyText
    formLaunchButtonText
    formHeader
    formSubmitButtonText
    formSubmittedModalImage {
      ...ImageFields
    }
    formSubmittedHeader
    formSubmittedBodyText
    formDismissButtonText
    fieldsData {
      fields {
        ...FormFieldFields
      }
      metaFields {
        ...FormMetaDataFields
      }
    }
  }
`;
