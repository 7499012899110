import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";

import { BFF_HREF } from "lib/config";
import introspectionQueryResultData from "./fragmentTypes.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const client = new ApolloClient({
  link: ApolloLink.from([
    new HttpLink({
      uri: `${BFF_HREF}/graphql`,
      credentials: "include",
    }),
  ]),
  cache: new InMemoryCache({ fragmentMatcher }),
});

export default client;
