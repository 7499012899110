import React from "react";

// Node Module Components
import BootstrapSpinner from "react-bootstrap/Spinner";

// Utils
import { composeClassName } from "utils";

// Style sheet
import "./Spinner.scss";

/**
*
* @function Spinner
* Renders a loading spinner
*
*/
const Spinner = ({ className }) => {
  // Combines all class into a single string
  const composedClass = composeClassName("we Spinner", className);

  return (
    <div className={composedClass}>
      <BootstrapSpinner animation="border" />
    </div>
  );
};


export default Spinner;
