import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { flattenCourse, VIDEO_MODULE } from "./utils";

const withPresenter = (View) => {
  const Presenter = (props) => {
    const { loading, error, data, refetch } = props;
    const history = useHistory();
    const match = useRouteMatch();

    const [isModalOpen, setShowModal] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [content, setContent] = useState([]);
    const contentIndex = useRef(0);

    useEffect(() => {
      if (data && data.course) {
        setContent(flattenCourse(data.course));
      }
    }, [data]);

    const closeModal = () => {
      setShowModal(false);
    };

    const navigateToModule = (lessonId, moduleId, questionId, method = "push") => {
      const { courseName } = match.params;
      const { state } = history.location;

      let count = state?.count || 1;
      if (method === "push") {
        count += 1;
      }

      if (questionId) {
        history[method](
          `/courses/${courseName}/lessons/${lessonId}/modules/${moduleId}/questions/${questionId}`,
          { count },
        );
      } else {
        history[method](`/courses/${courseName}/lessons/${lessonId}/modules/${moduleId}`, { count });
      }
    };

    const redirectToInitialModule = () => {
      if (content.length === 0) {
        return;
      }
      const { lessonId, moduleId, questionId } = match.params;
      const currentItem = content[contentIndex.current];
      if (currentItem.__typename === VIDEO_MODULE) {
        if (!lessonId || !moduleId) {
          navigateToModule(currentItem.lessonId, currentItem.moduleId, undefined, "replace");
        }
        return;
      }

      if (!lessonId || !moduleId || !questionId) {
        navigateToModule(currentItem.lessonId, currentItem.moduleId, currentItem.questionId, "replace");
      }
    };

    useEffect(() => {
      redirectToInitialModule();
    });

    const resetQuestion = () => {
      setShowResult(false);
      setSelectedAnswer(undefined);
    };

    const handleNext = () => {
      const currentContent = content[contentIndex.current];
      if (currentContent.__typename !== VIDEO_MODULE && showResult === false) {
        setShowResult(true);
      } else if (contentIndex.current + 1 < content.length) {
        const { lessonId, moduleId, questionId } = content[contentIndex.current + 1];
        navigateToModule(lessonId, moduleId, questionId);
        resetQuestion();
      } else {
        setShowModal(true);
      }
    };

    const handlePrev = () => {
      if (showResult) {
        resetQuestion();
        return;
      }

      if (contentIndex.current > 0) {
        contentIndex.current -= 1;
        const { lessonId, moduleId, questionId } = content[contentIndex.current];
        navigateToModule(lessonId, moduleId, questionId);
      }
    };

    const getModule = () => {
      if (content.length === 0) {
        return null;
      }
      const { lessonId, moduleId, questionId } = match.params;

      let nextIndex = -1;
      if (questionId) {
        nextIndex = content.findIndex((item) => {
          return item.questionId === questionId;
        });
      }
      if (nextIndex === -1) {
        nextIndex = content.findIndex((item) => {
          return item.moduleId === moduleId;
        });
      }
      if (nextIndex === -1) {
        nextIndex = content.findIndex((item) => {
          return item.lessonId === lessonId;
        });
      }
      if (nextIndex === -1) {
        nextIndex = 0;
      }
      contentIndex.current = nextIndex;

      const currentItem = content[contentIndex.current];

      return currentItem;
    };

    const memoizedModule = useMemo(getModule, [data, match.params, content]);

    if (!(data && data.course) && !error && !loading) {
      refetch();
      return null;
    }

    const showPrevButton = contentIndex.current !== 0 || showResult;

    let module;
    if (memoizedModule) {
      module = {
        ...memoizedModule,
        selectedAnswer: selectedAnswer,
        setSelectedAnswer: setSelectedAnswer,
        showResult: showResult,
        onTryAgain: resetQuestion,
        showPrevButton: showPrevButton,
        onNext: handleNext,
        onPrev: handlePrev,
      };
    }

    return (
      <View
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        name={data?.course?.name}
        module={module}
        error={error}
        loading={loading}
      />
    );
  };
  return Presenter;
};

export default withPresenter;
