import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { isEmptyString, isPassword, isEmail } from "lib/utils";
import { RECAPTCHA_SITE_KEY } from "lib/config";

const withPresenter = (View) => {
  const Presenter = (props) => {
    const { createAccount, prefill, privacyPolicyLink } = props;
    const { t } = useTranslation();
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [passwordVisibility, setPasswordVisibility] = useState("password");
    const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState("password");
    const recaptchaRef = useRef(null);

    const isValidForm = !isEmptyString(lastName) && !isEmptyString(firstName)
            && !isEmptyString(email) && !isEmptyString(password)
            && !isEmptyString(confirmPassword);


    useEffect(() => {
      if (prefill) {
        setFirstName(prefill.first);
        setLastName(prefill.last);
        setEmail(prefill.email);
      }
    }, [prefill]);


    const toggleConfirmPasswordVisibility = () => {
      if (confirmPasswordVisibility === "password") {
        setConfirmPasswordVisibility("text");
      } else {
        setConfirmPasswordVisibility("password");
      }
    };

    const togglePasswordVisibility = () => {
      if (passwordVisibility === "password") {
        setPasswordVisibility("text");
      } else {
        setPasswordVisibility("password");
      }
    };

    const handleFirstName = ({ target: { value } }) => {
      setFirstName(value);
    };

    const handleLastName = ({ target: { value } }) => {
      setLastName(value);
    };

    const handleEmail = ({ target: { value } }) => {
      setEmail(value);
      setEmailError("");
    };

    const handlePassword = ({ target: { value } }) => {
      setPassword(value);
      setPasswordError("");
    };

    const handleConfirmPassword = ({ target: { value } }) => {
      setConfirmPassword(value);
      setPasswordError("");
    };

    const handlePrivacyPolicy = () => {
      window.location.replace(privacyPolicyLink);
    };

    // Called after token has been updated by clicking sign up
    const handleSignUp = async (recaptchaToken) => {
      if (!isEmail(email)) {
        setEmailError(t("signup.email_error"));
      }
      if (password !== confirmPassword) {
        setPasswordError(t("signup.password_error_mismatch"));
      } else if (!isPassword(password)) {
        setPasswordError(t("signup.password_error"));
      } else if (createAccount) {
        try {
          await createAccount({
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            enabled: true,
            recaptchaToken: recaptchaToken,
          });
          window.location.replace("/");
        } catch {
          //
          recaptchaRef.current.reset();
        }
      }
    };

    // This will be called every time the captcha is solved
    const handleReCaptchaChange = (token) => {
      // eslint-disable-next-line no-void
      void handleSignUp(token);
    };

    const signUpBlockProps = {
      blockHeading: {
        title: t("signup.header"),
      },
      firstNameTextField: {
        name: "firstName",
        label: t("signup.first_name"),
        onChange: handleFirstName,
        value: firstName,
      },
      lastNameTextField: {
        name: "lastName",
        label: t("signup.last_name"),
        onChange: handleLastName,
        value: lastName,
      },
      emailTextField: {
        name: "emailAddress",
        label: t("signup.email"),
        onChange: handleEmail,
        value: email,
        error: emailError,
      },
      createPasswordField: {
        name: "password",
        label: t("signup.password"),
        secondaryLabel: t("signup.password_secondary"),
        onChange: handlePassword,
        value: password,
        type: passwordVisibility,
        onClick: togglePasswordVisibility,
        passwordToggle: true,
      },
      confirmPasswordField: {
        name: "confirmPassword",
        label: t("signup.password_confirm"),
        onChange: handleConfirmPassword,
        value: confirmPassword,
        type: confirmPasswordVisibility,
        onClick: toggleConfirmPasswordVisibility,
        passwordToggle: true,
        error: passwordError,
      },
      signUpButton: {
        className: "signUpButton",
        label: t("signup.button"),
        onClick: () => { recaptchaRef.current.execute(); },
        disabled: !isValidForm,
      },
      privacyPolicyButton: {
        label: t("signup.privacy_policy"),
        onClick: handlePrivacyPolicy,
      },
      recaptch: {
        sitekey: RECAPTCHA_SITE_KEY,
        size: "invisible",
        onChange: handleReCaptchaChange,
        ref: recaptchaRef,
      },
    };

    return (
      <View
        {...signUpBlockProps} />
    );
  };

  return Presenter;
};

export default withPresenter;
