import PropTypes from "prop-types";

const QueryResults = ({ data, onLoading, onError, onData }) => {
  const { loading, error, page, refetch } = data;
  if (loading) {
    return onLoading();
  }
  if (error) {
    return onError(error);
  }
  if (page) {
    return onData(data);
  }

  // due to a bug in Apollo Cache,
  // when an error is returned the data object is cached as null
  // without caching the error object.
  // So next time the query is made it returns error=undefined and data=null
  // https://github.com/apollographql/apollo-client/issues/4138
  // The workaround is to call refetch().
  refetch();
  return null;
};

QueryResults.propTypes = {
  onLoading: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onData: PropTypes.func.isRequired,
};

QueryResults.defaultProps = {
};

export default QueryResults;
