import React, { useContext } from "react";
import PropTypes from "prop-types";

// Translation hook
import { useTranslation } from "react-i18next";

// Context
import { AuthContext } from "modules/auth";

// Constants
import { WE_SCHOOLS_PROGRAM, WE_SCHOOLS_PARTNER } from "lib/constants";

const _getProfileLocale = (user, currentLocale, countryTags) => {
  if (user) {
    const subscription = user.subscriptions.find(
      ({ partner_id: partner, program }) => {
        const matches = partner === WE_SCHOOLS_PARTNER && program === WE_SCHOOLS_PROGRAM;
        return matches;
      },
    );

    if (subscription) {
      const { extras } = subscription;
      if (extras) {
        const { location } = extras;
        if (location) {
          const { country } = location;
          if (country) {
            const countryTag = countryTags[country.toLowerCase()];
            const localeSplit = currentLocale.split("-");

            if (countryTag && localeSplit.length > 0) {
              const localeParts = [];
              localeParts.push(localeSplit[0]);
              localeParts.push(countryTag);
              return localeParts.join("-");
            }
          }
        }
      }
    }
  }

  return null;
};

const ProfileLocaleDetector = ({ children, countryTags }) => {
  const { user } = useContext(AuthContext);
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const profileLocale = _getProfileLocale(user, currentLocale, countryTags);
  if (profileLocale && profileLocale !== currentLocale) {
    i18n.changeLanguage(profileLocale);
  }

  return (
    <>
      { children }
    </>
  );
};

ProfileLocaleDetector.propTypes = {
  countryTags: PropTypes.object.isRequired,
};

export default ProfileLocaleDetector;
