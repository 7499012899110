import gql from "graphql-tag";

export default gql`
  fragment FeaturedCollectionsBlockFields on FeaturedCollectionsBlock {
    id
    type
    collection1 {
      ...CollectionFields
    }
    collection2 {
      ...CollectionFields
    }
  }
`;
