import React from "react";
import PropTypes from "prop-types";
import FeedItems from "../organisms/FeedItems";

const Home = ({ page, blocks }) => {
  const { feed } = page;
  return (
    <FeedItems feed={feed} blocks={blocks} />
  );
};
Home.propTypes = {
  page: PropTypes.any.isRequired,
  blocks: PropTypes.object.isRequired,
};

Home.defaultProps = {
};

export default Home;
