import React from "react";
import PropTypes from "prop-types";
import { Spinner, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ErrorHandler from "components/molecules/ErrorHandler";
import SubHeader from "../../components/organisms/SubHeader";
import CompletionModal from "./organisms/CompletionModal";
import VideoCoursePlayer from "./organisms/VideoCoursePlayer";

import "./CoursePage.scss";
import QuizModule from "./organisms/QuizModule";

const modules = {
  Quiz: QuizModule,
  Video: VideoCoursePlayer,
};

const CoursePage = ({
  name,
  module,
  loading,
  error,
  isModalOpen,
  closeModal,
}) => {
  const { t } = useTranslation();
  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (loading) {
    return <Spinner />;
  }

  if (module) {
    const Module = modules[module.__typename];

    const completionModal = isModalOpen && (
      <CompletionModal
        title={name}
        isModalOpen={isModalOpen}
        closeModal={closeModal} />
    );

    return (
      <div className="we course-container">
        <SubHeader title={name} exitLabel={t("course_exit")} />
        {completionModal}
        <Container className="module-container">
          <Module {...module} />
        </Container>
      </div>
    );
  }

  return null;
};

CoursePage.propTypes = {
  name: PropTypes.string,
  module: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default CoursePage;
