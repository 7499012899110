import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEnUS from "./en-us/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "./locale/{{lng}}/translation.json",
    },
    resources: {
      "en-us": {
        translations: translationEnUS,
      },
    },
    fallbackLng: "en-us",
    lowerCaseLng: true,
    whitelist: ["en-us"],

    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    // keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },

    react: {
      wait: true,
    },
  });

export default i18n;
