import { completeFacebookSignUp } from "modules/account/api";
import React from "react";
import { useMutation } from "react-query";

const withInteractor = (Presenter) => {
  const Interactor = (props) => {
    const { mutateAsync: completeSignUp } = useMutation(completeFacebookSignUp);
    return (
      <Presenter {...props} completeSignUp={completeSignUp} />
    );
  };

  return Interactor;
};

export default withInteractor;
