import gql from "graphql-tag";

export default gql`
  fragment ProgramPageFields on ProgramPage {
    id
    type
    program {
      ...ProgramFields
    }
    feed {
      ...BlockFields
    }
  }
`;
