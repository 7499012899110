import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { initializeTracking } from "utils/trackingUtils";
import { initializeErrorHandler } from "modules/error/errorHandler";

import { DEPLOYMENT_TARGET } from "lib/config";

import App from "./App";

initializeErrorHandler(DEPLOYMENT_TARGET);
initializeTracking();
ReactDOM.render(<App />, document.getElementById("root"));
