import React from "react";
import PropTypes from "prop-types";

// App Components
import Block from "components/molecules/Block";
import ExpandableList from "components/organisms/ExpandableList";
import ExpandableListButton from "components/molecules/ExpandableListButton";
import Text from "components/atoms/Text";


// Bootstrap Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Style sheet
import "./RecordedCPDListBlock.scss";

// Video Tile Component
import VideoTile from "./VideoTile";

/**
 * Adapter component for Expandable List
 * @param {Array} filters
 */
const ListAdapter = ({ items, renderCount }) => {
  const displayItems = items.slice(0, renderCount).map((item) => {
    const key = item.id;
    return (
      <Col md={4} key={key}>
        <VideoTile video={item} />
      </Col>
    );
  });

  return (<Row>{displayItems}</Row>);
};

/**
*
* @function RecordedCPDListBlock
*
* @param {Object} block - Block properties from SB
*/
const RecordedCPDListBlock = ({ block }) => {
  const { title, recordedCPDs, description } = block;
  return (
    <Block
      title={title}
      className="we RecordedCPDListBlock">
      <Text variant="body">
        {description}
      </Text>
      <ExpandableList
        items={recordedCPDs}
        adapter={ListAdapter}
        displayCount={3}
        expandButton={ExpandableListButton}
      />
    </Block>
  );
};

RecordedCPDListBlock.propTypes = {
  /**
    * Storblock datamodel
    */
  block: PropTypes.object.isRequired,
};

export default RecordedCPDListBlock;
