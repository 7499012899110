import React from "react";
import PropTypes from "prop-types";

// App Components
import Button from "components/atoms/Button";
import Card from "components/molecules/Card";
import Image from "components/atoms/Image";
import Tag from "components/atoms/Tag";
import Heading from "components/atoms/Heading";

/**
 * @function ResourceTile
 * Square resource tile (https://zpl.io/agWv4gQ)
 *
 * @param {Object} resource - Resource block properties from SB
 */
const ResourceTile = ({ resource }) => {
  const {
    coverImage,
    topic,
    title,
    downloadButton,
  } = resource;

  const { name: topicName } = topic;
  const { externalLink, ...rest } = Button.extractButtonPropsFromButtonObject(downloadButton);

  return (
    <Button
      className="we ResourceTile"
      variant="text"
      {...rest}
      externalLink>
      <Card key={title}>
        <Card.Content className="tile-content">
          <Image src={coverImage}>
            <Tag theme="dark" size="big">
              {topicName}
            </Tag>
          </Image>
          <div className="resource-title">
            <Heading variant={Heading.variants.card}>
              {title}
            </Heading>
          </div>
        </Card.Content>
      </Card>
    </Button>
  );
};

ResourceTile.propTypes = {
  /**
    * Storblock datamodel
    */
  resource: PropTypes.object.isRequired,
};

export default ResourceTile;
