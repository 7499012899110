import React from "react";
import Cookie from "js-cookie";
import { COOKIE_AUTH_NAME, COOKIE_DOMAIN } from "lib/config";
import { trackUser } from "utils/trackingUtils";
import { getUserFromAuth } from "./authUtils";

const AuthContext = React.createContext();

export const Logout = () => {
  Cookie.remove(COOKIE_AUTH_NAME, {
    domain: COOKIE_DOMAIN,
    secure: true,
  });
  Cookie.remove(`${COOKIE_AUTH_NAME}-legacy`, {
    domain: COOKIE_DOMAIN,
    secure: false,
  });
  window.location.replace("/account/signIn");
  return null;
};

export const AuthProvider = ({ children }) => {
  // read auth from browser
  const authCookie = Cookie.get(COOKIE_AUTH_NAME);

  // extract user object for cookie
  const user = getUserFromAuth(authCookie);
  const isAuthenticated = !!user;

  // track custom data layer variables
  if (isAuthenticated) {
    trackUser(user);
  }

  // set default value for AuthProvider
  const defaultContext = { user, isAuthenticated };

  return (
    <AuthContext.Provider value={defaultContext}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
