import gql from "graphql-tag";

export default gql`
  fragment RecordedCPDFields on RecordedCPD {
    id
    title
    thumbnailImage {
      ...ImageFields
    }
    duration
    videoLink {
      ...LinkFields
    }
    region {
      ...TagFields
    }
    recommendedFor {
      ...TagFields
    }
    topic {
      ...TagFields
    }
    skillsAdded {
      ...TagFields
    }
    partOfPrograms {
      ...TagFields
    }
    partOfCampaigns {
      ...TagFields
    }
  }
`;
