import React, { useState, createContext, useContext } from "react";

// Form Block Modal
import RatingFormModal from "./RatingFormModal";

const RatingFormModalContext = createContext();

const _getModalOnClose = (setState) => {
  return () => {
    setState((state) => {
      return {
        ...state,
        open: false,
        submitted: false,
        resource: null,
        nextRating: null,
      };
    });
  };
};

const _getOpenRatingFormModal = (setState) => {
  return (resource, nextRating) => {
    setState((state) => {
      return {
        ...state,
        open: true,
        resource: resource,
        nextRating: nextRating,
      };
    });
  };
};

const _getOnSubmit = (setState) => {
  return (resourceId, rating) => {
    setState((state) => {
      return {
        ...state,
        submitted: true,
        ratings: {
          ...state.ratings,
          [resourceId]: rating,
        },
      };
    });
  };
};

const initialRatingFormState = {
  open: false,
  submitted: false,
  ratings: {},
  resource: null,
  nextRating: null,
};

export const RatingFormModalProvider = ({ children }) => {
  const [state, setState] = useState(initialRatingFormState);

  const _modalOnClose = _getModalOnClose(setState);
  const _openRatingFormModal = _getOpenRatingFormModal(setState);
  const _onSubmit = _getOnSubmit(setState);

  const { open, ratings, resource, nextRating, submitted } = state;
  const _contextValue = {
    openRatingFormModal: _openRatingFormModal,
    ratings: ratings,
  };
  const resourceId = resource && resource.id;
  const resourceRated = resourceId && ratings[resourceId];

  return (
    <RatingFormModalContext.Provider value={_contextValue}>
      <RatingFormModal
        isOpen={open}
        resource={resource}
        onSubmit={_onSubmit}
        onClose={_modalOnClose}
        nextRating={nextRating}
        rated={resourceRated}
        submitted={submitted}
      />
      {children}
    </RatingFormModalContext.Provider>
  );
};

export const useRatingFormModal = () => {
  const context = useContext(RatingFormModalContext);

  if (context === undefined) {
    throw new Error("Hook must be used inside RatingFormContextProvider");
  }

  return context;
};

export default RatingFormModalContext;
