import React from "react";
import { updatePassword } from "modules/account/api";
import { useMutation } from "react-query";

const withInteractor = (Presenter) => {
  const Interactor = () => {
    const { mutate } = useMutation(updatePassword);
    return (
      <Presenter
        updatePassword={mutate} />
    );
  };

  return Interactor;
};

export default withInteractor;
