import gql from "graphql-tag";

export default gql`
  fragment RecordedCPDListBlockFields on RecordedCPDListBlock {
    id
    type
    title
    recordedCPDs {
      ...RecordedCPDFields
    }
    description
  }
`;
