import React from "react";
import PropTypes from "prop-types";

// Utils
import { composeClassName } from "utils";

// Style sheet
import "./Image.scss";

/**
*
* @function Image
* Renders the background style based on the src.
*
* @param {String | Object} src - Can be the image src or an Image object
* @returns {Object} - The mobile and desktop background style object
*
*/
const _getBackgroundStyle = (src) => {
  // If src is a url
  if (typeof src === "string") {
    return {
      mobileStyle: { backgroundImage: `url(${src})` },
      desktopStyle: { backgroundImage: `url(${src})` },
    };
  }

  // If src is an image object
  const { desktop, mobile } = src;
  const styles = {};
  styles.desktopStyle = { backgroundImage: `url(${desktop})` };

  // Use desktop styles for mobile if mobile isn't set.
  if (mobile) {
    styles.mobileStyle = { backgroundImage: `url(${mobile})` };
  } else {
    styles.mobileStyle = { backgroundImage: `url(${desktop})` };
  }
  return styles;
};

const _getAriaLabel = (src, alt) => {
  // If alt was provided use it
  if (alt) {
    return alt;
  }
  // If src is a url
  if (typeof src === "string") {
    return src;
  }
  return src.altText;
};

/**
*
* @function Image
* React component for displaying images. Renders both Desktop and Mobile containers
* but hides mobile at desktop breakpoints and vica versa at mobile breakpoints
*
*/
const Image = ({ className, children, src, alt }) => {
  // Combines all class into a single string
  const composedClass = composeClassName("we Image", className);

  const { desktopStyle, mobileStyle } = _getBackgroundStyle(src);
  const ariaLabel = _getAriaLabel(src, alt);

  return (
    <div className={composedClass}>
      <span className="mobile" style={mobileStyle} role="img" aria-label={ariaLabel}>
        {children}
      </span>
      <span className="desktop" style={desktopStyle} role="img" aria-label={ariaLabel}>
        {children}
      </span>
    </div>
  );
};

Image.propTypes = {

  /**
    *
    * Specifies the URL of an image or an image object
    *
    */
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default Image;
