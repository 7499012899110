import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// Bootstrap Components
import Navbar from "react-bootstrap/Navbar";

import Button from "components/atoms/Button";

// Components
import SearchWidget from "modules/search";
import SearchButton from "modules/search/SearchButton";

// Sass Imports
import "./TopNav.scss";

// Icons and Images
// eslint-disable-next-line import/order
import SearchIconWhite from "res/icons/search-white-24-px.svg";
import { useLocation } from "react-router-dom";
import AccountWidget from "../../molecules/AccountWidget";


function TopNav({ routes, user, logo, onSideNavToggle, authenticated }) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { givenName, familyName } = user;
  const [searchWidgetIsOpen, setSearchWidgetIsOpen] = useState(false);

  /**
   * TODO: move state management into Nav Parent Component or Context
   * When refactoring Nav implementation
   * Leaving state here for now because moving it to app layout will trigger
   * unecessary page rerenders when opening search
   */
  const handleOpenSearchWidget = () => {
    setSearchWidgetIsOpen(true);
  };

  let searchWidget;
  let rightSlot;
  if (authenticated) {
    searchWidget = (
      <SearchWidget
        searchWidgetIsOpen={searchWidgetIsOpen}
        setSearchWidgetIsOpen={setSearchWidgetIsOpen}
        />
    );
    rightSlot = (
      <div className="nav-slot right">
        <SearchButton
          className="d-lg-none"
          variant="primary"
          icon={SearchIconWhite}
          onClick={handleOpenSearchWidget}
        />
        <AccountWidget userName={`${givenName} ${familyName}`} routes={routes} />
      </div>
    );
  } else {
    let buttonTarget;
    let buttonText;
    if (pathname.toLowerCase() === "/account/signin") {
      buttonTarget = "/account/signUp";
      buttonText = t("auth_top_nav.sign_up");
    } else {
      buttonTarget = "/account/signIn";
      buttonText = t("auth_top_nav.sign_in");
    }
    rightSlot = (
      <div className="nav-slot right">
        <Button
          theme="light"
          variant="tertiary"
          className="sign-in"
          href={buttonTarget}>
          { buttonText }
        </Button>
      </div>
    );
  }

  return (
    <Navbar fixed="top" className="we" expand="xs" id="top_nav" tabIndex="-1">
      <div className="nav-slot left">
        {/* TODO:Create Icon Button to standardize all buttons in nav */}
        <Navbar.Toggle aria-controls="side_nav" onClick={onSideNavToggle} />
        <Navbar.Brand href="/">
          <img id="weschools-logo" src={logo.desktop} alt={logo.altText} />
        </Navbar.Brand>
      </div>
      {searchWidget}
      {rightSlot}
    </Navbar>
  );
}
TopNav.propTypes = {
  routes: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  logo: PropTypes.object.isRequired,
  onSideNavToggle: PropTypes.func,
  authenticated: PropTypes.bool,
};

export default TopNav;
