import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "./Button";

// Social Icons
import Facebook from "./res/facebook.svg";
import Google from "./res/google.svg";
import Apple from "./res/apple.svg";

const variants = {
  google: "google",
  facebook: "facebook",
  apple: "apple",
};

const SocialButton = ({
  variant,
  onClick,
}) => {
  const { t } = useTranslation();

  let socialIcon;
  let text;
  switch (variant) {
    case "google":
      socialIcon = <span><img src={Google} alt="" /></span>;
      text = t("sso_button.google");
      break;
    case "facebook":
      socialIcon = <img src={Facebook} alt="" />;
      text = t("sso_button.facebook");
      break;
    case "apple":
      socialIcon = <img src={Apple} alt="" />;
      text = t("sso_button.apple");
      break;
    default:
      break;
  }
  return (
    <Button onClick={onClick} variant={variant}>
      {socialIcon}
      {text}
    </Button>
  );
};

SocialButton.variants = variants;

SocialButton.propTypes = {
  /**
    * The variant changes border, background and font color based on theme.
    */
  variant: PropTypes.oneOf(Object.keys(variants)),
  /**
   * Handler function for onClick event
   */
  onClick: PropTypes.func,
};

export default SocialButton;
