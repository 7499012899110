import gql from "graphql-tag";

export default gql`
  fragment MonthlyFeatureBlockFields on MonthlyFeatureBlock {
    id
    type
    image {
      ...ImageFields
    }
    overlineText
    title
    bodyText
    ctaButton {
      ...ButtonFields
    }
    ctaButtonType
    theme
  }
`;
