import React from "react";
import PropTypes from "prop-types";
import BannerBlock from "../blocks/BannerBlock";
import FeedItems from "../organisms/FeedItems";

const Program = ({ page, blocks }) => {
  const { program, feed } = page;
  return (
    <div>
      <BannerBlock block={program} />
      <FeedItems feed={feed} blocks={blocks} />
    </div>
  );
};
Program.propTypes = {
  page: PropTypes.any.isRequired,
  blocks: PropTypes.object.isRequired,
};

Program.defaultProps = {
};

export default Program;
