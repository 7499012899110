import React, { useState } from "react";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";

import UPDATE_FORM_STATE_MUTATION from "graphql/mutations/updateFormState";

// App Components
import Block from "components/molecules/Block";
import Image from "components/atoms/Image";
import Heading from "components/atoms/Heading";
import Card from "components/molecules/Card";
import Text from "components/atoms/Text";
import Button from "components/atoms/Button";
import { useFormBlockModal } from "./FormBlockModal";

// Style sheet
import "./FormBlock.scss";

const _getLaunchForm = (openFormBlockModal, formBlock) => {
  return () => {
    openFormBlockModal(formBlock);
  };
};

const _getHandleDismissBlock = (
  formId,
  fieldsData,
  updateFormState,
  setDismissed,
) => {
  const { metaFields } = fieldsData;
  delete metaFields.__typename;

  return () => {
    setDismissed(true);

    updateFormState({
      variables: {
        formId: formId,
        formAction: "dismissed",
        metaFields: metaFields,
      },
    });
  };
};

const _getDismissButton = (formId, fieldsData, setDismissed) => {
  return (
    <Mutation mutation={UPDATE_FORM_STATE_MUTATION}>
      {(updateFormState, { error }) => {
        if (error) {
          throw new Error(error);
        }

        const _handleDismiss = _getHandleDismissBlock(
          formId,
          fieldsData,
          updateFormState,
          setDismissed,
        );
        return <button type="button" onClick={_handleDismiss} />;
      }}
    </Mutation>
  );
};

/**
 *
 * @function FormBlock
 * Renders an Form Block
 *
 */
const FormBlock = ({ block }) => {
  const {
    blockImage,
    title,
    blockBodyText,
    formLaunchButtonText,
    formId,
    fieldsData,
    completed,
  } = block;
  const [dismissed, setDismissed] = useState(false);
  const { openFormBlockModal, submittedFormIds } = useFormBlockModal();
  const _launchForm = _getLaunchForm(openFormBlockModal, block);
  const dismissButton = _getDismissButton(formId, fieldsData, setDismissed);
  const submitted = submittedFormIds.includes(formId);
  const hideForm = completed || submitted || dismissed;

  if (hideForm) {
    return null;
  }

  return (
    <Block className="we FormBlock">
      <Card>
        <Card.Content>
          <div className="dismiss-button-wrapper">{dismissButton}</div>
          <Image src={blockImage} className="block-image" />
          <div className="content">
            <Heading variant={Heading.variants.card}>{title}</Heading>
            <Text>{blockBodyText}</Text>
            <Button onClick={_launchForm}>{formLaunchButtonText}</Button>
          </div>
        </Card.Content>
      </Card>
    </Block>
  );
};

FormBlock.propTypes = {
  /**
   *
   * Story block data model
   */
  block: PropTypes.object.isRequired,
};

export default FormBlock;
