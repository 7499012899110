import React, { useState } from "react";
import { adaptForm } from "./utils";

const withPresenter = (View) => {
  const Presenter = ({ loading, error, data, refetch }) => {
    const [submitted, setSubmitted] = useState(false);
    if (!(data && data.form) && !error && !loading) {
      refetch();
      return null;
    }

    let form;
    if (data && data.form) {
      form = adaptForm(data.form);
    }

    return (
      // eslint-disable-next-line max-len
      <View loading={loading} error={error} form={form} submitted={submitted} setSubmitted={setSubmitted} />
    );
  };

  return Presenter;
};

export default withPresenter;
