import axios from "axios";
import { BFF_HREF } from "lib/config";

export const getSsoLink = (type, target) => {
  return `${BFF_HREF}/auth/${type}?target_uri=${target}`;
};

export const getPrefill = async (submissionId) => {
  const { data: response } = await axios.get(
    `${BFF_HREF}/auth/prefill?submissionId=${submissionId}`,
    {
      withCredentials: true,
    },
  );
  return response;
};

export const signUp = async (data) => {
  const { data: response } = await axios.post(
    `${BFF_HREF}/auth/accounts`,
    data,
    { withCredentials: true },
  );
  return response.data;
};

export const completeFacebookSignUp = async (data) => {
  const {
    data: response,
  } = await axios.post(`${BFF_HREF}/auth/oauth/facebook/token`, data, {
    withCredentials: true,
  });
  return response.data;
};

export const signIn = async (payload) => {
  const { data } = await axios.post(`${BFF_HREF}/auth/token`, payload, {
    withCredentials: true,
  });
  return data;
};

export const forgotPassword = async (email) => {
  await axios.post(`${BFF_HREF}/auth/accounts/actions/forgotPassword`, {
    email,
  });
};

export const updatePassword = async (payload) => {
  const { id, password, token } = payload;
  await axios.patch(
    `${BFF_HREF}/auth/accounts/${id}/actions/updatePassword`,
    {
      password,
      token,
    },
    { withCredentials: true },
  );
};

export const updateName = async (payload) => {
  const { id, firstName, lastName } = payload;
  await axios.patch(
    `${BFF_HREF}/auth/accounts/${id}`,
    {
      firstName,
      lastName,
    },
    { withCredentials: true },
  );
};
