import React from "react";
import PropTypes from "prop-types";

// App components
import NavItem from "../NavItem";

// Sass Imports
import "./NavItems.scss";

/**
 * builds a list of nav items from the list of routes
 * @param {Object[]} routes
 *
 * @returns {NavItem []}
 */
function _buildNavItems(routes, isDropdown) {
  return routes.map((item) => {
    const { icon, path, label, externalLink, navPosition } = item;

    return (
      <NavItem key={path} {...{ icon, path, label, externalLink, navPosition, isDropdown }} />
    );
  });
}

function NavItems(props) {
  const { routes, isDropdown } = props;
  const navItems = _buildNavItems(routes, isDropdown);
  return (
    <>
      {navItems}
    </>
  );
}

NavItems._buildNavItems = _buildNavItems;

NavItems.propTypes = {
  routes: PropTypes.array.isRequired,
  isDropdown: PropTypes.bool,
};

NavItems.defaultProps = {
  isDropdown: false,
};

export default NavItems;
