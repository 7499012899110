import React from "react";
import PropTypes from "prop-types";

// Utils
import { composeClassName } from "utils";

// Style sheet
import "./Card.scss";


/**
*
* @function Header
* Renders the heading for cards. Usually full-bleed image
*
*/
const Header = ({ children, className }) => {
  // Combines all class into a single string
  const composedClass = composeClassName("Header", className);
  return (
    <div className={composedClass}>
      {children}
    </div>
  );
};

/**
*
* @function Content
* Renders the content section of cards
*
*/
const Content = ({ children, className }) => {
  // Combines all class into a single string
  const composedClass = composeClassName("Content", className);
  return (
    <div className={composedClass}>
      {children}
    </div>
  );
};


/**
*
* @function Card
* Renders the card layout
*
*/
const Card = ({ children, className }) => {
  // Combines all class into a single string
  const composedClass = composeClassName("we Card", className);
  return (
    <div className={composedClass}>
      {children}
    </div>
  );
};


Card.propTypes = {

  /**
    *
    * CSS class name
    */
  className: PropTypes.string,

};
Content.propTypes = {

  /**
    *
    * CSS class name
    */
  className: PropTypes.string,
};
Header.propTypes = {

  /**
    *
    * CSS class name
    */
  className: PropTypes.string,
};

Card.Header = Header;
Card.Content = Content;


export default Card;
