import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { useParams } from "react-router-dom";

export const COURSE_QUERY = gql`
  query($slug: String!) {
    course(slug: $slug) {
      id
      name
      lessons {
        id
        name
        modules {
          ... on Video {
            id
            url
            name
            altText
          }
          ... on Quiz {
            id
            questions {
              id
              text
              answers {
                id
                text
                explanation
                correctAnswer
              }
            }
          }
        }
      }
    }
  }
`;

const withInteractor = (Presenter) => {
  const Interactor = () => {
    const { courseName: slug } = useParams();

    return (
      <Query query={COURSE_QUERY} variables={{ slug }}>
        {(response) => {
          return <Presenter {...response} />;
        }}
      </Query>
    );
  };
  return Interactor;
};

export default withInteractor;
