import React, { PureComponent } from "react";
import PropTypes from "prop-types";

// Vimeo Player
import Player from "@vimeo/player";

/**
 *
 * Vimeo Player Integration
 *
 */
class VimeoPlayer extends PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
    setLoading: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    className: PropTypes.string,
    autoplay: PropTypes.bool,
  };

  static defaultProps = {
    autoplay: true,
  }

  constructor(props) {
    super(props);
    this.playerNode = null;
    this.player = null;
    this._setPlayerRef = this._setPlayerRef.bind(this);
    this._onVideoLoaded = this._onVideoLoaded.bind(this);
    this._onVideoError = this._onVideoError.bind(this);
  }

  componentDidMount() {
    this._createPlayer();
  }

  componentDidUpdate(prevProps) {
    const { src } = this.props;
    if (prevProps.src !== src) {
      this._createPlayer();
    }
  }

  componentWillUnmount() {
    if (this.player) this.player.destroy();
  }

  _createPlayer() {
    const { src, autoplay } = this.props;
    this.player = new Player(this.playerNode, {
      url: src,
      responsive: true,
      autoplay: autoplay,
      byline: false,
    });
    this.player.on("loaded", this._onVideoLoaded);
    this.player.on("error", this._onVideoError);
  }

  _onVideoLoaded() {
    const { setLoading } = this.props;
    setLoading(false);
  }

  _onVideoError() {
    const { setError } = this.props;
    setError(true);
  }

  _setPlayerRef(element) {
    this.playerNode = element;
  }

  render() {
    const { className, src } = this.props;

    return <div key={src} ref={this._setPlayerRef} className={className} />;
  }
}

export default VimeoPlayer;
