import gql from "graphql-tag";

export default gql`
  fragment FilterPageFields on FilterPage {
    id
    type
    filterTitle
    feed {
      ...BlockFields
    }
  }
`;
