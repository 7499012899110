import gql from "graphql-tag";

export default gql`
  fragment ClassroomResourceFields on ClassroomResource {
    id
    coverImage {
      ...ImageFields
    }
    title
    bodyText
    rating
    region {
      ...TagFields
    }
    recommendedFor {
      ...TagFields
    }
    topic {
      ...TagFields
    }
    skillsAdded {
      ...TagFields
    }
    downloadButton {
      ...ButtonFields
    }
    supplementaryMaterialButton {
      ...ButtonFields
    }
    partOfPrograms {
      ...TagFields
    }
    partOfCampaigns {
      ...TagFields
    }
    partOfCollections {
      ...TagFields
    }
  }
`;
