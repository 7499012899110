import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmptyString, isEmail } from "lib/utils";
import { useLocation } from "react-router-dom";


const withPresenter = (View) => {
  const Presenter = (props) => {
    const { completeSignUp } = props;
    const { t } = useTranslation();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const targetUri = query.get("target_uri");
    const [lastName, setLastName] = useState(query.get("last_name") || "");
    const [firstName, setFirstName] = useState(query.get("first_name") || "");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");


    const isValidForm = !isEmptyString(lastName) && !isEmptyString(firstName)
      && !isEmptyString(email);

    const handleFirstName = ({ target: { value } }) => {
      setFirstName(value);
    };

    const handleLastName = ({ target: { value } }) => {
      setLastName(value);
    };

    const handleEmail = ({ target: { value } }) => {
      setEmail(value);
      setEmailError("");
    };

    const handleSignUp = async () => {
      if (!isEmail(email)) {
        setEmailError(t("signup.email_error"));
      } else {
        await completeSignUp({
          firstName,
          lastName,
          email,
          targetUri,
        });
        window.location.replace(targetUri);
      }
    };

    const completeProfileBlockProps = {
      ...props,
      blockHeading: {
        title: t("complete_profile.header"),
      },
      firstNameTextField: {
        name: "firstName",
        label: t("complete_profile.first_name"),
        onChange: handleFirstName,
        value: firstName,
      },
      lastNameTextField: {
        name: "lastName",
        label: t("complete_profile.last_name"),
        onChange: handleLastName,
        value: lastName,
      },
      emailTextField: {
        name: "emailAddress",
        label: t("complete_profile.email"),
        onChange: handleEmail,
        value: email,
        error: emailError,
      },
      completeButton: {
        className: "confirmButton",
        label: t("complete_profile.button"),
        onClick: () => { return handleSignUp(); },
        disabled: !isValidForm,
      },
    };


    return (
      <View
        {...completeProfileBlockProps}
      />
    );
  };

  return Presenter;
};

export default withPresenter;
