import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const withPresenter = (View) => {
  const Presenter = (props) => {
    const {
      doSignIn,
      isLoading,
    } = props;
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisibility, setPasswordVisibility] = useState("password");
    const history = useHistory();
    const description = t("sign_in.description");

    const togglePasswordVisibility = () => {
      if (passwordVisibility === "password") {
        setPasswordVisibility("text");
      } else {
        setPasswordVisibility("password");
      }
    };

    const handleEmail = (e) => {
      setEmail(e.target.value);
    };

    const handlePassword = (e) => {
      setPassword(e.target.value);
    };

    const handleSignIn = async () => {
      try {
        await doSignIn({
          email,
          password,
        });
        window.location.replace("/");
      } catch (e) {
        if (e.response && e.response.data && e.response.data.error === "Needs Migration") {
          setError(t("sign_in.needs_migration"));
        } else {
          setError(t("sign_in.error_state"));
        }
      }
    };

    const handleSignUp = () => {
      history.push({
        pathname: "/account/signUp",
      });
    };

    const handleForgotPassword = () => {
      history.push({
        pathname: "/account/forgotPassword",
      });
    };

    const signInBlockProps = {
      heading: {
        value: t("sign_in.heading"),
      },
      description: description,
      emailTextField: {
        name: "email",
        label: t("sign_in.email_label"),
        onChange: handleEmail,
        value: email,
      },
      passwordTextField: {
        name: "password",
        label: t("sign_in.password_label"),
        onChange: handlePassword,
        value: password,
        type: passwordVisibility,
        onClick: togglePasswordVisibility,
        passwordToggle: true,
      },
      forgotPassword: {
        value: t("sign_in.forgot_password"),
        onClick: handleForgotPassword,
      },
      signInButton: {
        value: t("sign_in.sign_in_button"),
        onClick: handleSignIn,
      },
      callToActionHeading: t("sign_in.cta_heading"),
      createAccountButton: {
        value: t("sign_in.create_account_button"),
        onClick: handleSignUp,
      },
      error: error,
      loading: isLoading,
    };

    return (
      <View
        {...signInBlockProps}
        />
    );
  };
  return Presenter;
};
export default withPresenter;
