import gql from "graphql-tag";

export default gql`
  mutation UpdateLocationDetails(
    $country: String!
    $state: String
    $postal: String
    $communicationPreference: Boolean
  ) {
    updateLocationDetails(
      input: {
        country: $country
        state: $state
        postal: $postal
        communicationPreference: $communicationPreference
      }
    )
  }
`;
