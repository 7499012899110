import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BootstrapFormCheck from "react-bootstrap/FormCheck";
import BootstrapFormLabel from "react-bootstrap/FormLabel";

import Input from "components/molecules/Input";

const _renderOption = ({ name, label, id, value, onChange, checked }) => {
  return (
    <BootstrapFormCheck
      className="option"
      name={name}
      type="radio"
      label={label}
      value={value}
      id={id}
      key={id}
      onChange={onChange}
      checked={checked}
    />
  );
};

const _getHandleOtherInputChange = (checked, setOtherValue, onChange) => {
  return (e) => {
    setOtherValue(e.target.value);
    if (checked) {
      onChange(e);
    }
  };
};

const OtherOption = ({ id, checked, onChange }) => {
  const { t } = useTranslation();
  const [otherValue, setOtherValue] = useState("");
  const handleInputChange = _getHandleOtherInputChange(checked, setOtherValue, onChange);

  return (
    <>
      <BootstrapFormCheck
        className="option"
        name={id}
        type="radio"
        label={t("form.other_option.label")}
        value={otherValue}
        id={id}
        key={id}
        onChange={onChange}
        checked={checked}
      />
      <Input
        className="other-text-input"
        name={`${id}_input`}
        value={otherValue}
        onChange={handleInputChange}
      />
    </>
  );
};

const _createHandleInputChange = (fieldId, optionId, setFormState) => {
  return ({ target: { value } }) => {
    setFormState((currentFormState) => {
      return {
        ...currentFormState,
        [fieldId]: {
          id: optionId,
          value: value,
        },
      };
    });
  };
};
const _getOtherOption = ({ id, formState, setFormState }) => {
  const optionId = `${id}_other`;
  const onChange = _createHandleInputChange(id, optionId, setFormState);
  const checked = formState[id] && formState[id].id === optionId;

  return (
    <OtherOption
      optionId={optionId}
      onChange={onChange}
      checked={checked}
    />
  );
};

export default function FormStackFormRadioGroup(field) {
  const { options, label, id, formState, setFormState, otherOption } = field;

  const enhacedOptions = options.map((option, index) => {
    const optionId = `${id}_${index}`;
    const handleOptionChange = _createHandleInputChange(id, optionId, setFormState);
    const isOptionChecked = formState[id].id === optionId;

    return {
      ...option,
      name: id,
      id: optionId,
      onChange: handleOptionChange,
      checked: isOptionChecked,
    };
  });

  const optionElements = enhacedOptions.map(_renderOption);
  const otherOptionElem = otherOption
    ? _getOtherOption(field)
    : null;

  return (
    <fieldset>
      <BootstrapFormLabel className="Label">{label}</BootstrapFormLabel>
      {optionElements}
      {otherOptionElem}
    </fieldset>
  );
}
