import gql from "graphql-tag";

export default gql`
  fragment PromotedProgramsBlockFields on PromotedProgramsBlock {
    id
    type
    title
    program1 {
      ...ProgramFields
    }
    program2 {
      ...ProgramFields
    }
  }
`;
