import React, { useState } from "react";

// Translation hook
import { useTranslation } from "react-i18next";

import BootstrapForm from "react-bootstrap/Form";
import BootstrapFormLabel from "react-bootstrap/FormLabel";

// App Components
import Heading from "components/atoms/Heading";
import Button from "components/atoms/Button";
import Rating from "components/atoms/Rating";
import Input from "components/molecules/Input";
import Text from "components/atoms/Text";

import { FORM_FIELD_TYPES, NON_INPUT_FORM_FIELD_TYPES } from "lib/constants";
import { composeClassName } from "utils";
import FormStackFormRadioGroup from "./FormStackFormRadioGroup";
import FormStackFormCheckBoxGroup from "./FormStackFormCheckBoxGroup";


// Utils
import isFormValid from "./validation";


import "./FormStackForm.scss";

const _createHandleInputChange = (id, setFormState) => {
  return ({ target: { value } }) => {
    setFormState((currentFormState) => {
      return {
        ...currentFormState,
        [id]: value,
      };
    });
  };
};

const _renderTextInput = ({ id, name, label, value, setFormState, variant = "default", type = "text", placeholder, maxLength, rows, description }) => {
  const handleChange = _createHandleInputChange(id, setFormState);
  const currentLengthString = `${maxLength - value.length} / ${maxLength}`;
  let maxLengthDisplay;

  if (maxLength) {
    maxLengthDisplay = (
      <Text className="word-count">
        {currentLengthString}
      </Text>
    );
  }
  let supportingTextDisplay;
  if (description) {
    supportingTextDisplay = <Text className="supporting-label">{description}</Text>;
  }

  return (
    <div>
      <Input
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
        variant={variant}
        type={type}
        maxLength={maxLength}
        placeholder={placeholder}
        rows={rows}
      />
      {maxLengthDisplay}
      {supportingTextDisplay}
    </div>
  );
};

const _renderTextArea = (field) => {
  return _renderTextInput({
    ...field,
    variant: Input.variants.textarea,
  });
};

const _renderNumberInput = (field) => {
  return _renderTextInput({
    ...field,
    type: "number",
  });
};

// TODO: move render of select options and choice optons into the input component

const _renderSelectOption = ({ label, value }) => {
  return (
    <option value={value} key={value}>{label}</option>
  );
};

const _renderSelect = ({ id, name, options, label, value, setFormState }) => {
  const handleChange = _createHandleInputChange(id, setFormState);
  const optionElements = options.map(_renderSelectOption);

  return (
    <Input
      variant={Input.variants.select}
      name={name}
      label={label}
      value={value}
      onChange={handleChange}
    >
      <option value="" />
      {optionElements}
    </Input>
  );
};

const _getOnRatingChange = (setFormState, id) => {
  return (_, value) => {
    setFormState((currentFormState) => {
      return {
        ...currentFormState,
        [id]: value,
      };
    });
  };
};

const _renderRatingFormRating = ({ id, label, value, setFormState }) => {
  const onChange = _getOnRatingChange(setFormState, id);

  return (
    <>
      <BootstrapFormLabel className="Label" htmlFor={id}>{label}</BootstrapFormLabel>
      <div className="rating-wrapper">
        <Rating
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          large
        />
      </div>
    </>
  );
};

const _renderRadioGroup = (field) => {
  let supportingTextDisplay;
  const { description } = field;
  if (description) {
    supportingTextDisplay = (
      <Text className="supporting-label">
        {description}
      </Text>
    );
  }

  return (
    <div>
      <FormStackFormRadioGroup {...field} />
      {supportingTextDisplay}
    </div>
  );
};

const _renderCheckBoxGroup = (field) => {
  let supportingTextDisplay;
  const { description } = field;
  if (description) {
    supportingTextDisplay = (
      <Text className="supporting-label">
        {description}
      </Text>
    );
  }


  return (
    <div>
      <FormStackFormCheckBoxGroup {...field} />
      {supportingTextDisplay}
    </div>
  );
};

const _renderRichText = ({ text }) => {
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: text }} />;
};

const _renderHeading = ({ text }) => {
  return <Heading variant={Heading.variants.block}>{text}</Heading>;
};

const _fieldTypeToInputRenderFn = {
  [FORM_FIELD_TYPES.text]: _renderTextInput,
  [FORM_FIELD_TYPES.number]: _renderNumberInput,
  [FORM_FIELD_TYPES.textarea]: _renderTextArea,
  [FORM_FIELD_TYPES.select]: _renderSelect,
  [FORM_FIELD_TYPES.radio]: _renderRadioGroup,
  [FORM_FIELD_TYPES.checkbox]: _renderCheckBoxGroup,
  [FORM_FIELD_TYPES.ratingFormRating]: _renderRatingFormRating,
  [FORM_FIELD_TYPES.richtext]: _renderRichText,
  [FORM_FIELD_TYPES.section]: _renderHeading,
  _fallback: () => { return null; },
};

const _getRenderField = (formState, setFormState, t) => {
  return (field) => {
    const { id, type, label, translationKey } = field;
    const _renderInput = _fieldTypeToInputRenderFn[type] || _fieldTypeToInputRenderFn._fallback;
    const input = _renderInput({
      ...field,
      label: translationKey ? t(`form.labels.${translationKey}`) : label,
      value: formState[id],
      formState: formState,
      setFormState: setFormState,
    });

    const composedClassName = `field ${type}-field`;

    return (
      <div className={composedClassName} key={id}>
        {input}
      </div>
    );
  };
};

const _transformCheckBoxValue = (value) => {
  return Object.values(value).filter((val) => {
    return val !== undefined;
  });
};

const _transformOptionValue = (value) => {
  return (value && value.value) || undefined;
};

const _cleanForm = (form, fields) => {
  const inputFields = fields.filter(({ type }) => {
    return !NON_INPUT_FORM_FIELD_TYPES.includes(type);
  });
  return inputFields.reduce((cleanForm, { id, type }) => {
    let value = form[id];
    if (type === FORM_FIELD_TYPES.checkbox) {
      value = _transformCheckBoxValue(value);
    }
    if (type === FORM_FIELD_TYPES.radio) {
      value = _transformOptionValue(value);
    }
    if (value && value.trim && value.trim() === "") {
      value = undefined;
    }
    return {
      ...cleanForm,
      [id]: value,
    };
  }, {});
};

const _buildInitialFormState = (fields, nextRating) => {
  const inputFields = fields.filter(({ type }) => {
    return !NON_INPUT_FORM_FIELD_TYPES.includes(type);
  });
  const initialState = inputFields.reduce((statePartial, field) => {
    let value;

    if (field.type === FORM_FIELD_TYPES.checkbox) {
      value = {};
    } else if (field.type === FORM_FIELD_TYPES.ratingFormRating) {
      value = nextRating || 0;
    } else {
      value = field.default || "";
    }

    return {
      ...statePartial,
      [field.id]: value,
    };
  }, {});

  return initialState;
};

const _getRatingFormRatingField = ({ fieldsData: { metaFields } }, t) => {
  const { ratingFieldId } = metaFields;
  return {
    id: ratingFieldId,
    type: FORM_FIELD_TYPES.ratingFormRating,
    required: true,
    label: t("rating_form.rating_field.label"),
  };
};

const _getFields = (form, isRating, t) => {
  const { fieldsData: { fields } } = form;
  if (!isRating) return fields;

  return [
    _getRatingFormRatingField(form, t),
    ...fields,
  ];
};

function FormStackForm({ className, form, isRating, nextRating, onSubmit }) {
  const { t } = useTranslation();
  const {
    formHeader,
    formSubmitButtonText = t("form.submit"),
  } = form;
  const fields = _getFields(form, isRating, t);
  const initialFormState = _buildInitialFormState(fields, nextRating);
  const [formState, setFormState] = useState(initialFormState);

  const _renderField = _getRenderField(formState, setFormState, t);
  const fieldElements = fields.map(_renderField);

  const cleanForm = _cleanForm(formState, fields);
  const formValid = isFormValid(fields, cleanForm);

  const composedClassName = composeClassName(
    "we",
    "FormStackForm",
    isRating && "rating-form",
    className,
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(cleanForm);
  };

  return (
    <div className={composedClassName}>
      <Heading variant={Heading.variants.card}>{formHeader}</Heading>
      <BootstrapForm onSubmit={handleSubmit}>
        <div className="fields">
          {fieldElements}
        </div>
        <div className="submit-button-wrapper">
          <Button
            type="submit"
            disabled={!formValid}
            className="submit-button"
          >
            {formSubmitButtonText}
          </Button>
        </div>
      </BootstrapForm>
    </div>
  );
}

FormStackForm._buildInitialFormState = _buildInitialFormState;
FormStackForm._cleanForm = _cleanForm;
FormStackForm._getRenderField = _getRenderField;

export default FormStackForm;
