import React from "react";
import PropTypes from "prop-types";

// Node Module Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// App Components
import Heading from "components/atoms/Heading";
import Block from "components/molecules/Block";
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";
import Text from "components/atoms/Text";
import Card from "components/molecules/Card";


/**
*
* @function PromotedProgramsBlock
* Promoted Programs Block (https://zpl.io/Vx6GKN3)
*
* @param {Object} block - Block properties from SB
*
*/
const PromotedProgramsBlock = ({ block }) => {
  const { title, program1, program2 } = block;
  return (
    <Block
      title={title}
    >
      <Row>
        <PromotedProgramsCard {...program1} />
        <PromotedProgramsCard {...program2} />
      </Row>
    </Block>
  );
};


/**
*
* @function SocialMediaBlock
* Social media block (https://zpl.io/a7dXdyY)
*
* @param {String} className
* @param {Object} block - Block properties
*
*/
const PromotedProgramsCard = (
  {
    promoCardTitle,
    promoCardImage,
    promoCardContent,
    promoCardButton,
  },
) => {
  const { link: buttonLink, text: buttonText, enabled: buttonEnabled } = promoCardButton;
  const { url: href, external: externalLink } = buttonLink;
  return (
    <Col className="PromotedProgramsCard" md={6}>
      <Card>
        <Card.Header>
          <Image src={promoCardImage} />
        </Card.Header>
        <Card.Content>
          <Heading variant={Heading.variants.card}>{promoCardTitle}</Heading>
          <Text>{promoCardContent}</Text>
          <Button
            href={href}
            externalLink={externalLink}
            disabled={!buttonEnabled}
          >
            {buttonText}
          </Button>
        </Card.Content>
      </Card>
    </Col>
  );
};
PromotedProgramsBlock.PromotedProgramsCard = PromotedProgramsCard;
PromotedProgramsBlock.propTypes = {
  block: PropTypes.any.isRequired,
};


export default PromotedProgramsBlock;
