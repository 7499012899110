import React, { useState } from "react";
import PropTypes from "prop-types";

// Translation hook
import { useTranslation } from "react-i18next";

// App Components
import Spinner from "components/atoms/Spinner";
import Heading from "components/atoms/Heading";
import Text from "components/atoms/Text";

// Players
import VimeoPlayer from "./players/VimeoPlayer";

// Style sheet
import "./VideoPlayer.scss";

const _SUPPORTED_PLAYERS = {
  vimeo: {
    component: VimeoPlayer,
    // Source: https://regexr.com/3begm
    regex: /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/,
  },
};

/**
 * @function LoadingComponent
 * @param {boolean} loading
 */
const LoadingComponent = ({ loading }) => {
  if (!loading) return null;
  return (
    <Spinner className="player-loading" />
  );
};

/**
 * @function ErrorComponent
 * @param {Error} error
 * @param {Function} t i18n translator
 */
const ErrorComponent = ({ error, t }) => {
  if (!error) return null;
  return (
    <div className="player-error">
      <Heading variant={Heading.variants.block}>
        {t("video_player.error_message.title")}
      </Heading>
      <Text variant="body">
        {t("video_player.error_message.message")}
      </Text>
    </div>
  );
};

/**
 * @function IncompatibleVideoComponent
 * Component returned when no compatible player is available for video
 * @param {Function} setError
 * @param {Function} setLoading
 */
const IncompatibleVideoComponent = ({ setError, setLoading }) => {
  setError(true);
  setLoading(false);
  return null;
};

/**
 * Return compatible video player for specified video
 * @param {string} src
 */
const _getPlayer = (src) => {
  const Player = Object
    .values(_SUPPORTED_PLAYERS)
    .find(({ component, regex }) => {
      if (!regex || !component) return false;
      return regex.test(src);
    });

  if (!Player) {
    return IncompatibleVideoComponent;
  }

  return Player.component;
};

/**
 * Return class name relevant to display for video player
 * @param {boolean} loading
 * @param {Error} error
 */
const _getPlayerClassName = (loading, error) => {
  return (loading || error) ? "player-hidden" : "";
};

/**
*
* @function VideoPlayer
*
*/
const VideoPlayer = ({ src, className, autoplay }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const Player = _getPlayer(src);
  const playerClassName = _getPlayerClassName(loading, error);

  return (
    <div className={`we VideoPlayer ${className}`}>
      <LoadingComponent loading={loading} />
      <ErrorComponent error={error} t={t} />
      <Player
        autoplay={autoplay}
        className={playerClassName}
        setLoading={setLoading}
        setError={setError}
        src={src}
      />
    </div>
  );
};

VideoPlayer.IncompatibleVideoComponent = IncompatibleVideoComponent;

VideoPlayer._getPlayer = _getPlayer;

VideoPlayer.propTypes = {
  // Video URL
  src: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
};

export default VideoPlayer;
