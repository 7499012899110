import React from "react";
import { useTranslation } from "react-i18next";
import { Mutation } from "react-apollo";

import UPDATE_FORM_STATE_MUTATION from "graphql/mutations/updateFormState";

import { ErrorMessage } from "modules/error";
import Spinner from "components/atoms/Spinner";
import FormStackForm from "../../../../../components/organisms/FormStackForm";

import "./FormBlockForm.scss";

const _getHandleSubmitForm = (updateFormState, formBlock) => {
  return (formData) => {
    const { formId, fieldsData: { metaFields } } = formBlock;
    return updateFormState({
      variables: {
        formId: formId,
        metaFields: metaFields,
        formData: JSON.stringify(formData),
        formAction: "completed",
      },
    });
  };
};

const _getErrorView = (t) => {
  return (
    <ErrorMessage
      title={t("form_block.error_message.title")}
      message={t("form_block.error_message.message")}
    />
  );
};

const _getLoadingView = () => {
  return (
    <div className="loading-view">
      <Spinner />
    </div>
  );
};

const FormBlockForm = ({ isOpen, formBlock, handleFormSubmitted }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="we FormBlockForm">
      <Mutation mutation={UPDATE_FORM_STATE_MUTATION}>
        {(updateFormState, { loading, error, data }) => {
          if (data) handleFormSubmitted();
          const _handleSubmit = _getHandleSubmitForm(updateFormState, formBlock);

          if (error) return _getErrorView(t);
          if (loading) return _getLoadingView();

          return (
            <FormStackForm
              form={formBlock}
              onSubmit={_handleSubmit}
              loading={loading}
            />
          );
        }}
      </Mutation>
    </div>
  );
};

export default FormBlockForm;
