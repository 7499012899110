import React from "react";
import PropTypes from "prop-types";

// App Components
import Heading from "components/atoms/Heading";
import Block from "components/molecules/Block";
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";
import Text from "components/atoms/Text";

// Style sheet
import "./MonthlyFeatureBlock.scss";


const _getCtaButtonTheme = (ctaButtonType, theme) => {
  // If the button is text only, it should match the text colour of the block content
  return (ctaButtonType === Button.variants.text ? theme : undefined);
};

/**
*
* @function MonthlyFeatureBlock
* TODO: - Describe what MonthlyFeatureBlock does
*
*/
const MonthlyFeatureBlock = ({ block }) => {
  const { image, overlineText, title, bodyText, ctaButton, theme, ctaButtonType } = block;
  const ctaButtonTheme = _getCtaButtonTheme(ctaButtonType, theme);

  return (
    <Block className="we MonthlyFeatureBlock">
      <Image
        src={image}
        alt={title}>
        <div className="content">
          <Text
            theme={theme}
            variant="overline"
              >
            {overlineText}
          </Text>
          <Heading theme={theme} variant={Heading.variants.card}>{title}</Heading>
          <Text theme={theme}>{bodyText}</Text>
          <Button
            theme={ctaButtonTheme}
            variant={ctaButtonType}
            {...Button.extractButtonPropsFromButtonObject(ctaButton)}
          />
        </div>
      </Image>
    </Block>
  );
};

MonthlyFeatureBlock._getCtaButtonTheme = _getCtaButtonTheme;

MonthlyFeatureBlock.propTypes = {

  /**
    *
    * Story block data model
    */
  block: PropTypes.any.isRequired,

};

export default MonthlyFeatureBlock;
