/**
 * Extracts payload from a jwt
 * @param {String} jwt
 *
 * @returns {Object}
 */
export function _extractJwtPayload(jwt) {
  const payload = window.atob(jwt.split(".")[1]);
  return JSON.parse(payload);
}

/**
 * @typedef User
 * @property {String} givenName
 * @property {String} familyName
 * @property {String} email
 */

/**
 * Extracts User object from auth token
 * @param {String} token - Auth token(jwt)
 *
 * @returns {User}
 */

export function getUserFromAuth(appToken) {
  // Return false if no cookie passed in
  if (!appToken) return false;

  try {
    // decode access token and extract the identity token from the at claim
    const appTokenBodyJson = _extractJwtPayload(appToken);
    const { at: sessionToken } = appTokenBodyJson;
    const subscriptions = appTokenBodyJson.subscriptions || [];

    // decode session token and build user object from it
    const sessionTokenPayload = _extractJwtPayload(sessionToken);
    const {
      uuid: profileId,
      firstName: givenName,
      lastName: familyName,
      email,
    } = sessionTokenPayload;

    const user = {
      givenName,
      familyName,
      email,
      subscriptions,
      profileId,
    };
    return user;
  } catch {
    return false;
  }
}
