import gql from "graphql-tag";

export default gql`
  mutation UpdateRating(
    $ratingFormId: String!
    $resourceId: String!
    $metaFields: MetaFields!
    $formData: String!
  ) {
    updateRating(
      input: {
        ratingFormId: $ratingFormId
        resourceId: $resourceId
        metaFields: $metaFields
        formData: $formData
      }
    )
  }
`;
