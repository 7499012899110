import React from "react";
import PropTypes from "prop-types";
import BannerBlock from "../blocks/BannerBlock";
import FeedItems from "../organisms/FeedItems";

const Campaign = ({ page, blocks }) => {
  const { campaign, feed } = page;
  return (
    <div>
      <BannerBlock block={campaign} />
      <FeedItems feed={feed} blocks={blocks} />
    </div>
  );
};
Campaign.propTypes = {
  page: PropTypes.any.isRequired,
  blocks: PropTypes.object.isRequired,
};

Campaign.defaultProps = {
};

export default Campaign;
