import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Heading from "components/atoms/Heading/Heading";
import Image from "components/atoms/Image/Image";
import Text from "components/atoms/Text";
import Button from "components/atoms/Button";
import Block from "components/molecules/Block/Block";
import Warning from "./res/warning.svg";
import "./WarningBlock.scss";

const WarningBlock = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const heading = t("social_sign_in_error.heading");
  const description = t("social_sign_in_error.description");
  const buttonText = t("social_sign_in_error.button");

  const handleSignIn = () => {
    history.push({
      pathname: "/account/signIn",
    });
  };

  return (
    <div>
      <Block className="we warning-block">
        <Image src={Warning} />
        <Heading>{heading}</Heading>
        <Text>{description}</Text>
        <Button onClick={handleSignIn}>{buttonText}</Button>
      </Block>
    </div>
  );
};

export default WarningBlock;
