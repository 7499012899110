import React, { useContext } from "react";
import PropTypes from "prop-types";

// App Components
import Button from "components/atoms/Button";
import Card from "components/molecules/Card";
import Image from "components/atoms/Image";
import Tag from "components/atoms/Tag";
import Heading from "components/atoms/Heading";

// Video Modal Context
import { VideoModalContext } from "components/molecules/VideoModal";

// Icons
import PlayButton from "res/icons/button-video-small-enabled.svg";
import { trackVideoView } from "../../../../utils/trackingUtils";

const _getPlayVideo = (openVideoModal, videoSrc, title) => {
  return () => {
    const video = {
      url: videoSrc,
      title: title,
    };
    trackVideoView(video);
    openVideoModal(videoSrc);
  };
};

/**
 * @function VideoTile
 *
 * @param {Object} video - Resource block properties from SB
 */
const VideoTile = ({ video }) => {
  const {
    thumbnailImage,
    duration,
    title,
    videoLink: { url: videoSrc },
  } = video;
  const { openVideoModal } = useContext(VideoModalContext);
  const _playVideo = _getPlayVideo(openVideoModal, videoSrc, title);

  return (
    <>
      <Button
        className="we VideoTile"
        variant="text"
        onClick={_playVideo}>
        <Card key={title}>
          <Card.Content className="tile-content">
            <Image src={thumbnailImage}>
              <img
                src={PlayButton}
                className="playButton-icon"
                alt="Play Video"
              />
              <Tag theme="light" variant="contained" size="big">
                {duration}
              </Tag>
            </Image>
            <div className="video-title">
              <Heading variant={Heading.variants.card}>
                {title}
              </Heading>
            </div>
          </Card.Content>
        </Card>
      </Button>
    </>
  );
};

VideoTile.propTypes = {
  /**
    * Storblock datamodel
    */
  video: PropTypes.object.isRequired,
};

export default VideoTile;
