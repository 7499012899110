import React, { useState, createContext, useContext } from "react";

// Form Block Modal
import FormBlockModal from "./FormBlockModal";

const FormBlockModalContext = createContext();

const _getModalOnClose = (setModalOpen, setFormBlock) => {
  return () => {
    setModalOpen(false);
    setFormBlock(null);
  };
};

const _getOpenFormBlockModal = (setModalOpen, setFormBlock) => {
  return (formBlock) => {
    setFormBlock(formBlock);
    setModalOpen(true);
  };
};

const _getOnSubmit = (setSubmittedFormIds) => {
  return (formId) => {
    setSubmittedFormIds((submittedFormIds) => {
      return ([...submittedFormIds, formId]);
    });
  };
};

export const FormBlockModalProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [submittedFormIds, setSubmittedFormIds] = useState([]);
  const [formBlock, setFormBlock] = useState(null);
  const _modalOnClose = _getModalOnClose(setModalOpen, setFormBlock);
  const _openFormBlockModal = _getOpenFormBlockModal(setModalOpen, setFormBlock);

  const _contextValue = {
    openFormBlockModal: _openFormBlockModal,
    submittedFormIds: submittedFormIds,
  };

  const _onSubmit = _getOnSubmit(setSubmittedFormIds);
  const submitted = formBlock
    ? submittedFormIds.includes(formBlock.formId)
    : false;

  return (
    <FormBlockModalContext.Provider value={_contextValue}>
      <FormBlockModal
        formBlock={formBlock}
        isOpen={modalOpen}
        onClose={_modalOnClose}
        submitted={submitted}
        onSubmit={_onSubmit}
      />
      {children}
    </FormBlockModalContext.Provider>
  );
};

export const useFormBlockModal = () => {
  const context = useContext(FormBlockModalContext);

  if (context === undefined) {
    throw new Error("Hook must be used inside FormBlockContextProvider");
  }

  return context;
};

export default FormBlockModalContext;
