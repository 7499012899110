import React from "react";
import PropTypes from "prop-types";

// App Components
import Block from "components/molecules/Block";
import Image from "components/atoms/Image";
import Heading from "components/atoms/Heading";

// Style sheet
import "./BannerBlock.scss";

const BannerBlock = ({ block }) => {
  const { bannerImage, bannerTitle } = block;
  return (
    <Block className="we BannerBlock">
      <Image src={bannerImage}>
        <Heading
          variant={Heading.variants.page}
          theme="dark"
          >
          {bannerTitle}
        </Heading>
      </Image>
    </Block>
  );
};
BannerBlock.propTypes = {
  block: PropTypes.any.isRequired,
};


export default BannerBlock;
