import React from "react";
import PropTypes from "prop-types";

// Node Module Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// App Components
import Heading from "components/atoms/Heading";
import Block from "components/molecules/Block";
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";
import Text from "components/atoms/Text";

// Utils
import { composeClassName } from "utils";

// Style sheet
import "./SocialMediaBlock.scss";

import facebookIcon from "./res/icons/facebook-icon.png";
import instagramIcon from "./res/icons/instagram-icon.png";
import twitterIcon from "./res/icons/twitter-icon.png";
import pintrestIcon from "./res/icons/pintrest-icon.png";

/**
*
* @function _buildSocialButtons
* Builds a list button
* @param {String} value1 - describe value1
* @param {Object[]} socialLink - Array of social links, type and url
* @returns {Buttons[]} - Returns and array of <Buttons/>
*
*/
const _buildSocialButtons = (socialLinks) => {
  const reducer = (reducedSocialLinks, socialLink) => {
    const { type, link, iconSrc } = socialLink;
    if (link) {
      const { url: href } = link;
      const button = (

        <Button
          key={type}
          variant={Button.variants.secondary}
          className="social-button"
          href={href}
          externalLink
          target="_blank"
        >
          <img
            src={iconSrc}
            alt={`${type} link`}
              />
        </Button>

      );
      return [...reducedSocialLinks, button];
    }
    return reducedSocialLinks;
  };

  return socialLinks.reduce(reducer, []);
};

/**
*
* @function SocialMediaBlock
* Social media block (https://zpl.io/a7dXdyY)
*
* @param {String} className
* @param {Object} block - Block properties
*
*/
const SocialMediaBlock = ({ className, block }) => {
  // Combines all class into a single string
  const composedClass = composeClassName("we SocialMediaBlock", className);

  // Extract storyblock props
  const {
    title,
    bodyText,
    image,
    facebookLink,
    twitterLink,
    instagramLink,
    pinterestLink,
  } = block;

  // Build socal links array
  // TODO: Update when API returns Array of links
  const socialLinks = [
    {
      type: "facebook",
      link: facebookLink,
      iconSrc: facebookIcon,
    },
    {
      type: "twitter",
      link: twitterLink,
      iconSrc: twitterIcon,
    },
    {
      type: "instagram",
      link: instagramLink,
      iconSrc: instagramIcon,
    },
    {
      type: "pintrest",
      link: pinterestLink,
      iconSrc: pintrestIcon,
    },
  ];

  const socialButtons = _buildSocialButtons(socialLinks);

  return (
    <Block className={composedClass}>
      <Row>
        {/* Image Container */}
        <Col className="image-container" md={5}>
          <Image src={image} />
        </Col>

        {/* Content Container */}
        <Col md={7} className="content-container">
          <Heading
            variant={Heading.variants.card}
                    >
            {title}
          </Heading>
          <Text>
            {bodyText}
          </Text>
          <div className="button-container">
            {socialButtons}
          </div>
        </Col>
      </Row>
    </Block>
  );
};


SocialMediaBlock.propTypes = {

  /**
    *
    * CSS Class selector
    *
    *
    */
  className: PropTypes.string,

  /**
    *
    * Block props from StoryBlok
    * {
    *   title,
    *   bodyText,
    *   image,
    *   facebookLink,
    *   twitterLink,
    *   instagramLink,
    *   pinterestLink,
    * }
    *
    */
  block: PropTypes.object.isRequired,


};

export default SocialMediaBlock;
