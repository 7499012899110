import React from "react";
import PropTypes from "prop-types";

// App Components
import Button from "components/atoms/Button/";

// Icons
import ArrowUpIcon from "res/icons/arrow-up.svg";
import ArrowDownIcon from "res/icons/arrow-down.svg";

// Translation hook
import { useTranslation } from "react-i18next";

// Style sheet
import "./ExpandableListButton.scss";

/**
 * Gets Expand Button for Expandable List
 */
const ExpandableListButton = ({ expanded, onClick }) => {
  const { t } = useTranslation();

  const textKeyNs = "expandable_list_button";
  const textKey = expanded ? `${textKeyNs}.less` : `${textKeyNs}.more`;
  const text = t(textKey);

  const iconSrc = expanded ? ArrowUpIcon : ArrowDownIcon;

  return (
    <div className="expand-button">
      <Button
        variant={Button.variants.text}
        onClick={onClick}
      >
        {text}
        <img
          src={iconSrc}
          className="expand-icon"
          alt={text}
        />
      </Button>
    </div>
  );
};

ExpandableListButton.prototype = {
  t: PropTypes.isRequired,
  textKeyNs: PropTypes.string.isRequired,
};

export default ExpandableListButton;
