import { useEffect } from "react";

/**
 *
 * @function useSetInputFocusOnMount
 * Custom hook that sets focus to the search form Input Field on component mount
 * @param {function} searcWidgetIsOpen - accessor for isOpen
 * @param {Object} searchInputRef - Ref to Input Field of the search form
 *
 */
export const useSetInputFocusOnMount = (searchWidgetIsOpen, searchInputRef) => {
  useEffect(() => {
    // Set focus on search field onMount only if the open button has been clicked
    if (searchWidgetIsOpen) {
      searchInputRef.current.focus();
    }
  }, [searchWidgetIsOpen, searchInputRef]);
};

export default { useSetInputFocusOnMount };
