import React from "react";

import AppLayout from "components/templates/AppLayout";

// Error Handling
import { ErrorBoundary } from "modules/error";

// Composed providers
import withAppProvider from "./AppProvider";

// App Routes
import AppRouterItems from "./AppRouterItems";

import "./App.scss";

function App() {
  return (
    <ErrorBoundary>
      <AppRouterItems layout={AppLayout} />
    </ErrorBoundary>
  );
}

export default withAppProvider(App);
