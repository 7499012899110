import React from "react";
import correctIcon from "res/icons/correct.svg";
import incorrectIcon from "res/icons/incorrect.svg";
import Text from "components/atoms/Text";
import Heading from "components/atoms/Heading/Heading";
import { composeClassName } from "utils";
import { useTranslation } from "react-i18next";

import "./AnswerExplanation.scss";

const AnswerExplanation = ({ className, isCorrect, explanation, errorMessage }) => {
  const { t } = useTranslation();
  let explanationProps = {};
  if (errorMessage) {
    explanationProps = {
      icon: incorrectIcon,
      containerStyle: "ExplanationError",
      headerText: errorMessage,
      alt: "error",
    };
  } else if (isCorrect) {
    explanationProps = {
      icon: correctIcon,
      containerStyle: "ExplanationCorrect",
      headerText: t("course.quiz.correct"),
      alt: "correct",
    };
  } else {
    explanationProps = {
      icon: incorrectIcon,
      containerStyle: "ExplanationIncorrect",
      headerText: t("course.quiz.incorrect"),
      alt: "incorrect",
    };
  }

  const { icon, containerStyle, headerText, alt } = explanationProps;
  const containerClassName = composeClassName(
    "we ExplanationContainer",
    containerStyle,
    className,
  );

  let explanationElement;
  if (explanation) {
    explanationElement = <Text className="we Text Explanation">{explanation}</Text>;
  }

  return (
    <div className={containerClassName}>
      <div className="we ExplanationHeader">
        <img src={icon} alt={alt} />
        <Heading variant={Heading.variants.card}>
          {headerText}
        </Heading>
      </div>
      {explanationElement}
    </div>
  );
};

export default AnswerExplanation;
