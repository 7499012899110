import gql from "graphql-tag";

export default gql`
  fragment WebinarFields on Webinar {
    id
    isLive
    title
    bodyText
    thumbnailImage {
      ...ImageFields
    }
    date
    partOfPrograms {
      ...TagFields
    }
    partOfCampaigns {
      ...TagFields
    }
    liveButton {
      ...ButtonFields
    }
  }
`;
