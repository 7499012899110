import React from "react";
import PropTypes from "prop-types";

// Bootstrap Components
import NavDropdown from "react-bootstrap/NavDropdown";

// App Components
import NavItems from "../NavItems";

// Sass Imports
import "./AccountWidget.scss";

// Icons and Images
import ProfileImageIcon from "./res/icons/profile-image-placeholder.svg";
import CaretDown from "./res/icons/caret-down.svg";

/**
 * builds the account widget title bar that appears in the top nav
 * @param {String} userName
 *
 * @returns {String} - Html markup for Title
 */
function _buildTitle(userName) {
  return (
    <div className="title-container">
      <img className="profile-image" src={ProfileImageIcon} alt="Profile Icon Placeholder" />
      <span className="profile-username">{userName}</span>
      <img className="caret-down" src={CaretDown} alt="CaretDown" />
    </div>
  );
}

function AccountWidget(props) {
  const { userName, routes } = props;
  const title = _buildTitle(userName);
  return (
    <NavDropdown className="we AccountWidget" title={title} id="basic-nav-dropdown">
      <NavItems routes={routes} isDropdown />
    </NavDropdown>
  );
}

AccountWidget._buildTitle = _buildTitle;

AccountWidget.propTypes = {
  userName: PropTypes.string.isRequired,
  routes: PropTypes.array.isRequired,
};

export default AccountWidget;
