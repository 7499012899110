import gql from "graphql-tag";

export default gql`
  fragment FilterFields on Filter {
    id
    filterType
    filterLink
    title
    image {
      ...ImageFields
    }
    theme
  }
`;
