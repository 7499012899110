import React from "react";
import Text from "components/atoms/Text";
import { composeClassName } from "utils";
import Heading from "components/atoms/Heading";
import { useTranslation } from "react-i18next";
import Card from "components/molecules/Card";
import Form from "react-bootstrap/Form";
import Button from "components/atoms/Button";
import RadioOption from "components/molecules/RadioOption";
import AnswerExplanation from "modules/course/atoms/AnswerExplanation/AnswerExplanation";

import "./QuizModule.scss";

const _renderAnswer = ({ id, onChange, selected, text }) => {
  const optionClassName = composeClassName(
    "Quiz_Option",
    selected ? "Highlight" : "",
  );
  return (
    <RadioOption
      key={id}
      id={id}
      className={optionClassName}
      onChange={onChange}
      selected={selected}
      label={text}
    />
  );
};

const QuizModule = (props) => {
  const {
    question,
    selectedAnswer,
    setSelectedAnswer,
    showResult,
    showPrevButton = true,
    onNext,
    onPrev,
    onTryAgain,
  } = props;
  const { t } = useTranslation();
  const { text, answers, questionNumber, numberOfQuestions } = question;

  let content;
  let buttons;
  const answerList = answers.map(
    (answer) => {
      const isSelected = selectedAnswer === answer.id;
      if (isSelected && showResult) {
        content = (
          <AnswerExplanation
            className="we AnswerExplanation"
            explanation={answer.explanation}
            isCorrect={answer.correctAnswer}
          />
        );
        if (!answer.correctAnswer) {
          buttons = (
            <Button className="we Next_Button" onClick={onTryAgain}>
              {t("course.quiz.try_again_button")}
            </Button>
          );
        }
      }
      return _renderAnswer({
        ...answer,
        selected: isSelected,
        onChange: () => {
          setSelectedAnswer(answer.id);
        },
      });
    },
  );

  if (!content) {
    content = (
      <Form>
        {answerList}
      </Form>
    );
  }

  if (!buttons) {
    buttons = (
      <>
        {showPrevButton ? (
          <Button className="we Prev_Button" variant="secondary" onClick={onPrev}>
            {t("course.prev_button")}
          </Button>
        ) : <div />}
        <Button className="we Next_Button" onClick={onNext} disabled={!selectedAnswer}>
          {t("course.next_button")}
        </Button>
      </>
    );
  }

  return (
    <Card className="we Quiz">
      <Text className="Question_Number">
        {t("course.quiz.question_number", { questionNumber, numberOfQuestions })}
      </Text>
      <Heading variant={Heading.variants.Card} className="Question_Header">{text}</Heading>
      {content}
      <div className="we Row">
        {buttons}
      </div>
    </Card>
  );
};

export default QuizModule;
