import TagManager from "react-gtm-module";
import { GTM_ID, GTM_AUTH, GTM_PREVIEW } from "lib/config";
import { WE_SCHOOLS_PROGRAM, WE_SCHOOLS_PARTNER } from "lib/constants";

const _trackDataLayer = (data) => {
  TagManager.dataLayer({
    dataLayer: {
      ...data,
    },
  });
};

const _getUserSubscriptionDetails = (subscription) => {
  if (subscription) {
    const { extras } = subscription;
    if (extras) {
      const { location } = extras;
      if (location) {
        const { country, state, postal: zipCode } = location;
        return {
          country,
          state,
          zipCode,
        };
      }
    }
  }
  // default state in case something is missing in the subscription.
  return {
    country: "",
    state: "",
    zipCode: "",
  };
};

const initializeTracking = () => {
  TagManager.initialize({
    gtmId: GTM_ID,
    auth: GTM_AUTH,
    preview: GTM_PREVIEW,
  });
};

const trackUser = (user) => {
  const subscription = user.subscriptions.find(
    ({ partner_id: partner, program }) => {
      const matches =
        partner === WE_SCHOOLS_PARTNER && program === WE_SCHOOLS_PROGRAM;
      return matches;
    },
  );

  const subscriptionDetails = _getUserSubscriptionDetails(subscription);
  // track the profile id as the user id
  _trackDataLayer({
    userId: user.profileId,
    ...subscriptionDetails,
  });
};

const trackVideoView = (video) => {
  _trackDataLayer({
    event: "Video View",
    videoTitle: video.title,
    videoUrl: video.url,
  });
};

export { initializeTracking, trackUser, trackVideoView };
