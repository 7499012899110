import React from "react";
import PropTypes from "prop-types";
import BannerBlock from "../blocks/BannerBlock";
import FeedItems from "../organisms/FeedItems";

const Collection = ({ page, blocks }) => {
  const { collection, feed } = page;
  return (
    <div>
      <BannerBlock block={collection} />
      <FeedItems feed={feed} blocks={blocks} />
    </div>
  );
};
Collection.propTypes = {
  page: PropTypes.any.isRequired,
  blocks: PropTypes.object.isRequired,
};

Collection.defaultProps = {
};

export default Collection;
