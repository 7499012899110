import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Rating from "components/atoms/Rating";

import Button from "components/atoms/Button";

import { composeClassName } from "utils";
import { useRatingFormModal } from "../RatingFormModal";

// Style sheet
import "./RatingWidget.scss";

const _getHandleRateAgainChange = (onClick, currentRating) => {
  return (_, inputtedRating) => {
    /**
     * inputtedRating is null when the user selects the same rating, the rating component is
     * implemented to "clear" the rating in such instances, but we don't want this behavior
     * when launching the rating form modal.
     */
    const nextRating = inputtedRating || currentRating;
    onClick(nextRating);
  };
};

const RateAgainWidget = ({ resourceId, rating, onClick }) => {
  const handleChange = _getHandleRateAgainChange(onClick, rating);
  const name = `resource-${resourceId}-rating`;

  return (
    <div className="rate-again-container">
      <Rating name={name} value={rating} onChange={handleChange} />
    </div>
  );
};

const _getHandleRateNowClick = (onClick) => {
  return () => {
    onClick();
  };
};

const RateNowWidget = ({ onClick }) => {
  const { t } = useTranslation();
  const handleClick = _getHandleRateNowClick(onClick);

  return (
    <Button
      className="rate-cta"
      variant={Button.variants.text}
      onClick={handleClick}>
      {t("classroom_resource.rating_widget.rate_now")}
    </Button>
  );
};

const _getHandleClick = (openRatingFormModal, resourceBlock) => {
  return (nextRating) => {
    openRatingFormModal(resourceBlock, nextRating);
  };
};

const RatingWidget = ({ resourceBlock, className }) => {
  const { openRatingFormModal, ratings } = useRatingFormModal();
  const handleClick = _getHandleClick(openRatingFormModal, resourceBlock);

  const { id, rating } = resourceBlock;
  const currentRating = ratings[id] || rating;
  const Widget = !currentRating ? RateNowWidget : RateAgainWidget;
  const composedClassName = composeClassName("we", "RatingWidget", className);

  return (
    <div className={composedClassName}>
      <Widget rating={currentRating} onClick={handleClick} resourceId={id} />
    </div>
  );
};

RatingWidget.propTypes = {
  /**
   * RatingForm data from storyblok
   */
  resourceBlock: PropTypes.object,
};

export default RatingWidget;
