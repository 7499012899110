import React from "react";
import MaterialRating from "@material-ui/lab/Rating";
import { composeClassName } from "utils";

import "./Rating.scss";

const Star = ({ value = true }) => {
  const statusClassName = value ? "full" : "empty";
  const composedClassName = composeClassName("star", `star-${statusClassName}`);

  return (
    <span className={composedClassName} />
  );
};

function Rating({
  value,
  onChange,
  readonly = false,
  large = false,
  name = "",
  ...rest
}) {
  const composedClassName = composeClassName("we", "Rating", large && "large");

  return (
    <MaterialRating
      className={composedClassName}
      emptyIcon={<Star value={false} />}
      icon={<Star />}
      value={value}
      readOnly={readonly}
      onChange={onChange}
      name={name}
      {...rest}
    />
  );
}

export default Rating;
