import React from "react";

// Translation provider
import { I18nextProvider as ReactI18nextProvider } from "react-i18next";

// i18next configuration
import i18n from "./i18n";

import ProfileLocaleDetector from "./ProfileLocaleDetector";
import countryTags from "./countryTags.json";

const I18nextProvider = ({ children }) => {
  return (
    <ReactI18nextProvider i18n={i18n}>
      <ProfileLocaleDetector countryTags={countryTags}>
        {children}
      </ProfileLocaleDetector>
    </ReactI18nextProvider>
  );
};

export default I18nextProvider;
