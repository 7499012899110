/**
 *
 * @function handleSearchFormSubmit
 * This function get the value being searched and passes it through to the
 * search page.
 * @param {String} searchTerm - Term being searched for
 * @param {Object} history - History object from React Router
 *
 */
export const handleSearchFormSubmit = (event, searchTerm, history) => {
  event.preventDefault();
  if (searchTerm.length > 0) {
    const encodedSearchTerm = encodeURIComponent(searchTerm);
    history.push({
      pathname: "/search",
      search: `?searchTerm=${encodedSearchTerm}`,
    });
  }
};

export default { handleSearchFormSubmit };
