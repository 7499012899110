import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isEmptyString } from "lib/utils";

const withPresenter = (View) => {
  const Presenter = ({ forgotPassword, email }) => {
    const history = useHistory();

    useEffect(() => {
      if (isEmptyString(email)) {
        history.push("/account/forgotPassword");
      }
    }, [email, history]);

    const handleResend = async () => {
      forgotPassword(email);
    };
    const handleBack = () => {
      history.push("/account/forgotPassword");
    };

    return (
      <View
        handleBack={handleBack}
        handleResend={handleResend}
        />
    );
  };

  return Presenter;
};

export default withPresenter;
