import React from "react";
import { useTranslation } from "react-i18next";
import Input from "components/molecules/Input/Input";
import Heading from "components/atoms/Heading/Heading";
import Text from "components/atoms/Text/Text";
import Button from "components/atoms/Button/Button";
import Block from "components/molecules/Block/Block";
import "./CreatePasswordBlock.scss";

const CreatePasswordBlock = ({
  createPassword,
  confirmPassword,
  handleCreatePassword,
  handleConfirmPassword,
  handleSavePassword,
  error,
  formInvalid,
  createPasswordInputType,
  confirmPasswordInputType,
  togglePasswordVisibility,
  toggleConfirmPasswordVisibility,
  invalid,
}) => {
  const { t } = useTranslation();

  let errorMessage;
  let confirmClassName = "input";
  if (error) {
    confirmClassName = "input confirm";
    errorMessage = (
      <Text className="error" variant="overline">
        { t("update_password.error_message") }
      </Text>
    );
  } else if (invalid) {
    confirmClassName = "input confirm";
    errorMessage = (
      <Text className="error" variant="overline">
        { t("update_password.invalid_message") }
      </Text>
    );
  }

  return (
    <Block className="we CreatePasswordBlock">
      <Heading>
        { t("update_password.title") }
      </Heading>
      <Text variant="overline">
        { t("update_password.create_password_label") }
      </Text>
      <Input
        className="input"
        type={createPasswordInputType}
        value={createPassword}
        onChange={handleCreatePassword}
        passwordToggle="true"
        onClick={togglePasswordVisibility}
        />
      <Text variant="overline" className="description">
        { t("update_password.description") }
      </Text>
      <Text variant="overline">
        { t("update_password.confirm_password_label") }
      </Text>
      <Input
        className={confirmClassName}
        type={confirmPasswordInputType}
        value={confirmPassword}
        onChange={handleConfirmPassword}
        passwordToggle="true"
        onClick={toggleConfirmPasswordVisibility}
        />
      {errorMessage}
      <div>
        <Button
          className="button"
          variant="primary"
          onClick={handleSavePassword}
          disabled={formInvalid}>
          { t("update_password.save_button") }
        </Button>
      </div>
    </Block>
  );
};


export default CreatePasswordBlock;
