import gql from "graphql-tag";

export default gql`
  fragment SocialMediaBlockFields on SocialMediaBlock {
    id
    type
    title
    bodyText
    image {
      ...ImageFields
    }
    facebookLink {
      ...LinkFields
    }
    twitterLink {
      ...LinkFields
    }
    instagramLink {
      ...LinkFields
    }
    pinterestLink {
      ...LinkFields
    }
  }
`;
