import React from "react";

// node modules
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

// Bootstrap components
import Nav from "react-bootstrap/Nav";

// App Components
import NavItems from "../../molecules/NavItems";

// Sass Imports
import "./SideNav.scss";


/**
 * Gets the nav element from CSS transtion and sets it to be the focus
 * Requried from an accessibility perspective
 * @param {Function} sideNav - Instance of the side nav dom element
 *
 * @returns {null}
 */
function _setFocusOnSideNav(sideNav) {
  sideNav.focus();
}


/**
 * Sets the focus to the TopNav
 * @returns {null}
 */
function _setFocusOnTopNav() {
  const topNav = document.getElementById("top_nav");
  if (topNav) {
    topNav.focus();
  }
}

/**
 * Adds an transitionend event listener to the element
 * @param {Object} element - Instance of the dom element that is transitioning
 * @param {Function} callback - Instance of the side nav dom element
 *
 * @returns {null}
 */
function _addTransitionListener(element, callback) {
  // use the css transitionend event to mark the finish of a transition
  element.addEventListener("transitionend", callback, false);
}

/**
 * separate nav items by their position (top and bottom)
 * @param {Object[]} routes
 * @param {Boolean} isBottom
 *
 * @returns {Object[]}
 */
function _separateNavItems(routes) {
  const topRoutes = [];
  const bottomRoutes = [];
  routes.forEach((route) => {
    if (route.bottom) {
      bottomRoutes.push(route);
    } else {
      topRoutes.push(route);
    }
  });

  return {
    topRoutes,
    bottomRoutes,
  };
}

function SideNav(props) {
  const { location, routes, isCollapsed } = props;
  const activePage = location.pathname;
  const { topRoutes, bottomRoutes } = _separateNavItems(routes);
  return (
    <CSSTransition
      in={!isCollapsed}
      classNames="side-nav"
      // set focus to side nav when it enters(required for accessibility)
      onEntered={_setFocusOnSideNav}
      // reset focus back to top nav on exit(required for accessibility)
      onExit={_setFocusOnTopNav}
      // Used by CSStransition to determine css transition completion.
      addEndListener={_addTransitionListener}
    >
      <Nav
        tabIndex="-1"
        id="side_nav"
        className="we flex-column"
        activeKey={activePage}
      >
        <div className="nav-slot top">
          <NavItems routes={topRoutes} />
        </div>
        <div className="nav-slot bottom">
          <NavItems routes={bottomRoutes} />
        </div>
      </Nav>
    </CSSTransition>
  );
}

SideNav._setFocusOnSideNav = _setFocusOnSideNav;
SideNav._setFocusOnTopNav = _setFocusOnTopNav;
SideNav._addTransitionListener = _addTransitionListener;

SideNav.propTypes = {
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  isCollapsed: PropTypes.bool,
};

export default withRouter(SideNav);
