import React, { useContext } from "react";

// React Router Components
import { Route, Redirect } from "react-router-dom";

// Context
import { AuthContext } from "modules/auth";

export default function PrivateRoute(props) {
  const { isAuthenticated } = useContext(AuthContext);
  if (!isAuthenticated) {
    return <Redirect to="/account" />;
  }
  return (
    <Route
      {...props}
        />
  );
}
