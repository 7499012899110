import React from "react";
import PropTypes from "prop-types";

// App Components
import Block from "components/molecules/Block";
import ExpandableList from "components/organisms/ExpandableList";
import ExpandableListButton from "components/molecules/ExpandableListButton";

// Bootstrap Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Style sheet
import "./NewestResourcesListBlock.scss";

// Resource Tile Component
import ResourceTile from "./ResourceTile";

/**
 * Adapter component for Expandable List
 * @param {Array} filters
 */
const ListAdapter = ({ items, renderCount }) => {
  const displayItems = items.slice(0, renderCount).map((item) => {
    const key = item.id;
    return (
      <Col md={4} key={key}>
        <ResourceTile resource={item} />
      </Col>
    );
  });

  return (<Row>{displayItems}</Row>);
};

/**
*
* @function NewestResourcesListBlock
* NewestResourcesListBlock (https://zpl.io/agWv4gQ)
*
* @param {Object} block - Block properties from SB
*/
const NewestResourcesListBlock = ({ block }) => {
  const { title, resources } = block;

  return (
    <Block
      title={title}
      className="we NewestResourcesListBlock">
      <ExpandableList
        items={resources}
        adapter={ListAdapter}
        displayCount={3}
        expandButton={ExpandableListButton}
      />
    </Block>
  );
};

NewestResourcesListBlock.propTypes = {
  /**
    * Storblock datamodel
    */
  block: PropTypes.object.isRequired,
};

export default NewestResourcesListBlock;
