import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Input from "components/molecules/Input";

import "./SignUpBlock.scss";
import SocialButton from "components/atoms/Button/SocialButton";
import Block from "components/molecules/Block/Block";
import Heading from "components/atoms/Heading";
import Button from "components/atoms/Button";
import Text from "components/atoms/Text";
import { getSsoLink } from "../../api";


const SignUpBlock = (props) => {
  const {
    blockHeading,
    firstNameTextField,
    lastNameTextField,
    emailTextField,
    createPasswordField,
    confirmPasswordField,
    signUpButton,
    privacyPolicyButton,
    recaptch,
  } = props;
  const { label: signUpLabel, ...signUpButtonProps } = signUpButton;

  const handleSsoClick = (ssoLink) => {
    return () => {
      window.location.replace(ssoLink);
    };
  };

  const facebookClickHandler = handleSsoClick(getSsoLink("facebook", "/"));
  const googleClickHandler = handleSsoClick(getSsoLink("google", "/"));
  // const appleClickHandler = handleSsoClick(getSsoLink("apple", "/"));

  return (
    <>
      <Block className="signUpBlock">
        <div className="headingContent">
          <Heading className="blockHeading">{blockHeading.title}</Heading>
        </div>
        <div className="form">
          <Input {...firstNameTextField} />
          <Input {...lastNameTextField} />
          <Input {...emailTextField} />
          <Input {...createPasswordField} />
          <Input {...confirmPasswordField} />
          <ReCAPTCHA {...recaptch} />
          <Button {...signUpButtonProps}>{signUpLabel}</Button>
        </div>
        <div className="socialButtons">
          <Text>OR</Text>
          <SocialButton onClick={facebookClickHandler} variant="facebook" />
          <SocialButton onClick={googleClickHandler} variant="google" />
          {/* <SocialButton onClick={appleClickHandler} variant="apple" /> */}
          <Button className="privacyPolicyButton" variant="text" onClick={privacyPolicyButton.onClick}>{privacyPolicyButton.label}</Button>
        </div>
      </Block>
    </>
  );
};

export default SignUpBlock;
