import React from "react";

import { ApolloProvider } from "react-apollo";
import { AuthProvider } from "modules/auth";
import { I18nextProvider } from "modules/locale";
import { VideoModalProvider } from "components/molecules/VideoModal";
import { FormBlockModalProvider } from "modules/page/blocks/FormBlock";
import { RatingFormModalProvider } from "modules/page/blocks/ClassroomResourceListBlock/RatingFormModal";
import { QueryClient, QueryClientProvider } from "react-query";
import apolloClient from "graphql/ApolloClient";

const withAppProvider = (Component) => {
  const queryClient = new QueryClient();
  return (props) => {
    return (
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={apolloClient}>
            <I18nextProvider>
              <VideoModalProvider>
                <FormBlockModalProvider>
                  <RatingFormModalProvider>
                    <Component {...props} />
                  </RatingFormModalProvider>
                </FormBlockModalProvider>
              </VideoModalProvider>
            </I18nextProvider>
          </ApolloProvider>
        </QueryClientProvider>
      </AuthProvider>
    );
  };
};

export default withAppProvider;
