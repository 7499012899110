import React from "react";
import PropTypes from "prop-types";

import Block from "components/molecules/Block";
import Image from "components/atoms/Image";

// Style sheet
import "./ImageBlock.scss";

/**
*
* @function ImageBlock
* Renders an Image Block
*
*/
const ImageBlock = ({ block }) => {
  const { image } = block;
  return (
    <Block className="we ImageBlock">
      <Image src={image} />
    </Block>
  );
};


ImageBlock.propTypes = {

  /**
    *
    * Story block data model
    */
  block: PropTypes.object.isRequired,

};

export default ImageBlock;
