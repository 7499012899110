import React from "react";

import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { signUp } from "../../api";

const withInteractor = (Presenter) => {
  const Interactor = (props) => {
    const { state } = useLocation();
    const { mutateAsync: createAccount } = useMutation(signUp);

    return <Presenter createAccount={createAccount} prefill={state} {...props} />;
  };
  return Interactor;
};
export default withInteractor;
