import React from "react";
import { useMutation } from "react-query";
import { signIn } from "../../api";

const withInteractor = (Presenter) => {
  const Interactor = () => {
    // pass mutate and error to presenter
    const { mutateAsync, isLoading } = useMutation(signIn);
    return (
      <Presenter
        doSignIn={mutateAsync}
        isLoading={isLoading}
        />
    );
  };
  return Interactor;
};

export default withInteractor;
