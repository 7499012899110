import React from "react";

import { Mutation } from "react-apollo";

import Card from "components/molecules/Card";
import Heading from "components/atoms/Heading";
import LocationCaptureForm from "modules/location/LocationCaptureForm";

import UPDATE_LOCATION_DETAILS from "graphql/mutations/updateLocationDetails";

// Style sheet
import "./LocationCaptureBlock.scss";

import { useTranslation } from "react-i18next";

function _refreshPage() {
  window.location.reload();
}

const _onCompleted = () => {
  _refreshPage();
};

const _onFormSubmit = (updateLocationDetails) => {
  return (formValues) => {
    return updateLocationDetails({ variables: formValues });
  };
};


const _errorMessage = (error) => {
  if (!error) return "";
  return (
    <pre>{JSON.stringify(error, null, 4)}</pre>
  );
};

const LocationCaptureBlock = () => {
  const { t } = useTranslation();

  return (
    <Mutation mutation={UPDATE_LOCATION_DETAILS}>
      {(updateLocationDetails, { loading, error, data }) => {
        if (data) _onCompleted();

        return (
          <Card className="we LocationCaptureBlock">
            <Heading variant={Heading.variants.card} className="title">
              { t("location_capture.heading_message") }
            </Heading>
            <LocationCaptureForm
              onSubmit={_onFormSubmit(updateLocationDetails)}
              loading={loading}
            />
            { _errorMessage(error) }
          </Card>
        );
      }}
    </Mutation>
  );
};

export default LocationCaptureBlock;
